import { } from './polyfill';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Spinner from './components/loading/Spinner';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux'
// import { MetamaskProvider } from './contexts/MetamaskContext';
import Web3 from 'web3';
import { WalletProvider } from './contexts/WalletContext';
import { AlertsProvider } from './contexts/AlertsContext';
import { store } from './redux/store';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { Error500 } from './pages/errors';
import { reactPlugin } from './plugins/AppInsights';
import { NavigationProvider } from './contexts/NavigationContext';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // react-date-range main style file 
import 'react-date-range/dist/theme/default.css'; // react-date-range theme css file
import "slick-carousel/slick/slick.css"; // react-slick main style file
import "slick-carousel/slick/slick-theme.css"; // react-slick theme css file
import { ToastContainer } from 'react-toastify';

function getLibrary(provider) {
  return new Web3(provider)
}


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Suspense fallback={<Spinner/>}>
      <BrowserRouter>
        <AppInsightsErrorBoundary onError={Error500} appInsights={reactPlugin}>
          <Provider store={store}>
            <NavigationProvider>
              <Web3ReactProvider getLibrary={getLibrary}>
                <AlertsProvider>
                  <WalletProvider>
                    <App/>
                    <ToastContainer/>
                  </WalletProvider>
                </AlertsProvider>
              </Web3ReactProvider>
            </NavigationProvider>
          </Provider>
        </AppInsightsErrorBoundary>
      </BrowserRouter>
    </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
