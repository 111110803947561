import React, { useCallback } from 'react';
import classNames from '../../helpers/classNames';

/** @param {import('react').InputHTMLAttributes} param0 */
const FieldInputSwitch = ({ 
  name, 
  onChange, 
  value,
  className,
  ...rest 
}) => {
  const handleChangeValue = useCallback(() => {
    console.log(name, value);
    onChange(name, !value);
  }, [onChange, name, value]);

  console.log('value', value);


  return (
    <input 
      {...rest}
      className={classNames('form-field-switch', className)}
      onChange={handleChangeValue}
      checked={value}
      value=''
      type='checkbox'
    />
  )
};

export default FieldInputSwitch