import React from 'react';
import { BranchCard } from '../../components/cards';
import { ItemList } from '../../components/list';

const DATA_SOURCE = {
  endpoint: 'getVendorBranches',
  accessor: 'branches',
}

const Branches = () => {

  return (
    <div className="collection-list-content">
      <ItemList 
        dataSource={DATA_SOURCE}
        cardComponent={BranchCard}
        listName='branch'
      />
    </div>
  );
};

export default Branches;