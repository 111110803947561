export const DEV_IP = '1';
const BLOCKCHAIN_MODE = process.env.REACT_APP_BLOCKCHAIN_MODE;

const CHAIN_IDS = {
  PROD: [1, 137],
  // TEST: [80001],
  TEST: [4, 80001],
};

export const NETWORK_HEX_IDS = {
  1: '0x1',
  4: '0x4',
  80001: '0x13881',
  137: '0x89'
}

export const CHAIN_NETWORK_NAMES = {
  1: 'Ethereum',
  4: 'Rinkeby (Eth Testnet)',
  137: 'Polygon',
  80001: 'Polygon (Testnet)',
}

export const getNetworkSelection = (supportedChains = []) => {
  return supportedChains.map((chainID) => ({
    value: chainID,
    label: CHAIN_NETWORK_NAMES[chainID],
  }));
}

const BLOCKCHAIN_CONFIGS = {
  PROD: {
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.PROD,
  },
  TEST: {
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.TEST,
  }
};

export const RWARD_CRUD_API = process.env.REACT_APP_RWARD_CRUD_API; 
export const RWARD_NOTIF_API = process.env.REACT_APP_RWARD_NOTIF_API; 
// export const LIKHA_CRUD_API = process.env.REACT_APP_LIKHA_CRUD_API;
export const LIKHA_CRUD_API = "https://likhafuncapp.azurewebsites.net/api/";
export const RWARD_CUSTOMER_URL = process.env.REACT_APP_RWARD_CUSTOMER_URL;
// export const RWARD_CUSTOMER_URL = 'https://thick-eyes-bake-202-128-60-186.loca.lt/';
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const POLYGONSCAN_URL = BLOCKCHAIN_MODE === 'PROD' ? "https://polygonscan.com/" : "https://mumbai.polygonscan.com/";

/** @typedef {'DEV' | 'TEST' | 'PROD'} SERVER_MODES */

/** @type {SERVER_MODES} */
export const SERVER_MODE = process.env.REACT_APP_SERVER_MODE;
// export const SERVER_MODE = undefined;

/** @param {SERVER_MODES} forServer */
export const isForThisServer = (forServer = 'DEV') => {
  switch(SERVER_MODE) {
    case 'DEV':
      return true;
    case 'TEST':
      return forServer === 'TEST' || forServer === 'PROD';
    case 'PROD':
    default:
      return forServer === 'PROD';
  }
}

export const BLOCKCHAIN = BLOCKCHAIN_CONFIGS[BLOCKCHAIN_MODE === 'PROD' ? 'PROD' : 'TEST'];
