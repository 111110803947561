import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getFullPath } from '../../routes';
import Spinner from '../loading/Spinner';

const CREATE_PASSWORD_PATH = getFullPath(['ACCOUNT', 'CREATE_PASSWORD']);
const OTP_EMAIL_PATH = getFullPath(['ACCOUNT', 'OTP', 'EMAIL']);
const OTP_MOBILE_PATH = getFullPath(['ACCOUNT', 'OTP', 'MOBILE']);

const AuthenticatedRoute = ({ element, children, history }) => {
  const { currentUser, authenticatingToken, tempPassword, otpEmail, otpMobile } = useSelector(state => state.auth);
  const location = useLocation();
  // const history = useHistory();
  // const privateElement = useMemo(
  //   () => {
  //     if (currentUser) {
  //       return element;
  //     }
  //     return routes.HOME.element;
  //     // return (
  //     //   // <Navigate
  //     //   //   to={{
  //     //   //     pathname: '/errors/error401',
  //     //   //     state: { 
  //     //   //       from: props.location,
  //     //   //     },
  //     //   //   }}
  //     //   // />
  //     // );
  //   },
  //   [currentUser, element]
  // );

  if (authenticatingToken) {
    // not logged in so redirect to login page with the return url
    return <div className='w-full h-full flex justify-center items-center'><Spinner/></div>
  }
  if (!currentUser) {
    return <Navigate to="/errors/401" state={{ from: history }} replace />
  }

  // if(tempPassword) {
  //   if(location.pathname !== CREATE_PASSWORD_PATH) {
  //     return <Navigate to="/account/create-password" state={{ from: history }} replace />
  //   }
  // } else if(otpEmail) {
  //   if(location.pathname !== OTP_EMAIL_PATH) {
  //     return <Navigate to="/account/otp/email" state={{ from: history }} replace />
  //   }
  // } else if(otpMobile) {
  //   if(location.pathname !== OTP_MOBILE_PATH) {
  //     return <Navigate to="/account/otp/mobile" state={{ from: history }} replace />
  //   }
  // }
  
  // authorized so return child components
  return children;
};

export default AuthenticatedRoute;