import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ArrowCircleDownIcon, CheckCircleIcon, PencilIcon, PlusCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/react/outline';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertsContext from '../../contexts/AlertsContext';
import ItemListActionButton from '../buttons/ItemListActionButton';
import Spinner from '../loading/Spinner';
import ItemListPageSizeButton, { GET_ROW_COUNT } from '../buttons/ItemListPageSizeButton';
import ItemListPagesButton from '../buttons/ItemListPagesButton';
import RwardCRUD from '../../helpers/RwardCRUD';
import useModal from '../../hooks/useModal';
import { ContentModal } from '../modal';
import FieldInput from '../forms/FieldInput';
import { getTSNow, preventAndStop } from '../../helpers/Generic';
import classNames from '../../helpers/classNames';
import Jumbotron from '../jumbotron/Jumbotron';
import ActionButton from '../buttons/ActionButton';
import CardButton from '../buttons/CardButton';

const DEFAULT_PAGE_SIZE = 12;

/**
 * 
 * @callback FieldSelectionCallback
 * @param {Array<string>} values
 * 
 * @typedef {Object} ReportFieldSelectionProps
 * @property {DownloadDataField | string} field
 * @property {Array<string>} removedFields
 * @property {FieldSelectionCallback} onClick
 * 
 * @param {ReportFieldSelectionProps} param0
 *  
 * @returns {React.Component}
 */
const ReportFieldSelection = ({ field, removedFields, onClick }) => {
  const {
    fieldLabel,
    // fieldKey,
    fieldId,
  } = useMemo(() => ({
    fieldLabel: field.label || field.key,
    // fieldKey: field.key,
    fieldId: field.id || field.key,
  }), [field]);
  const isFieldRemoved = useMemo(() => removedFields.includes(fieldId), [fieldId, removedFields]);
  const handleToggle = useCallback((ev) => {
    preventAndStop(ev);
    if(isFieldRemoved) {
      onClick(removedFields.filter(value => value !== fieldId));
    } else {
      onClick([...removedFields, fieldId]);
    }
  }, [onClick, isFieldRemoved, removedFields, fieldId]);
  
  return (
    <div 
      className={classNames(
        'm m-[.4rem] py-1 px-3 rounded-full flex flex-row justify-center items-center cursor-pointer outline outline-offset-1 outline-1 transition-colors duration-500',
        isFieldRemoved ? 'bg-white text-red-600 outline-red-600' : 'bg-green-300 text-green-600 outline-green-600'
      )}
      onClick={handleToggle}
    >
      {fieldLabel}
      {
        isFieldRemoved ?
        <XCircleIcon className='ml-2 w-4 h-4 stroke-red-600'/> :
        <CheckCircleIcon className='ml-2 w-4 h-4 stroke-green-600'/>
      }
    </div>
  );
};

const FILTER_CONDITIONS = {
  number: [
    {
      label: 'Exactly',
      value: 'EQUALS',
    },
    {
      label: 'Not equal to',
      value: 'IS-NOT',
    },
    {
      label: 'At least',
      value: 'GREATER-EQUAL',
      tips: 'Value should be equal or greater than'
    },
    {
      label: 'At most',
      value: 'LESSER-EQUAL',
      tips: 'Value should be equal or greater than'
    },
    {
      label: 'Greater than',
      value: 'GREATER',
      tips: 'Value should be above'
    },
    {
      label: 'Less than',
      value: 'LESSER',
      tips: 'Value should be below'
    },
    {
      label: 'Between',
      value: 'BETWEEN',
      tips: 'Value should be inside'
    },
    {
      label: 'Within',
      value: 'BETWEEN-BOTH',
      tips: 'Value should be anywhere around'
    },
    {
      label: 'At least but less than',
      value: 'BETWEEN-START',
      tips: 'Value should be equal or greater than but below'
    },
    {
      label: 'At most but greater than',
      value: 'BETWEEN-END',
      tips: 'Value should be equal or less than but above'
    },
  ],
  string: [
    {
      label: 'Exactly',
      value: 'EQUALS',
    },
    {
      label: 'Not equal to',
      value: 'IS-NOT',
    },
    {
      label: 'Contains',
      value: 'CONTAINS',
    },
    {
      label: 'Does not contains',
      value: 'NOT-CONTAINS',
    },
    {
      label: 'Starts with',
      value: 'STARTS-WITH',
    },
    {
      label: 'Does not start with',
      value: 'NOT-STARTS-WITH',
    },
    {
      label: 'Ends with',
      value: 'ENDS-WITH',
    },
    {
      label: 'Does not end with',
      value: 'NOT-ENDS-WITH',
    },
  ],
  date: [],
  time: [],
  datetime: [
    {
      label: 'Exactly',
      value: 'EQUALS',
    },
    {
      label: 'Not equal to',
      value: 'IS-NOT',
    },
    {
      label: 'At least',
      value: 'GREATER-EQUAL',
      tips: 'Value should be equal or greater than'
    },
    {
      label: 'At most',
      value: 'LESSER-EQUAL',
      tips: 'Value should be equal or greater than'
    },
    {
      label: 'Greater than',
      value: 'GREATER',
      tips: 'Value should be above'
    },
    {
      label: 'Less than',
      value: 'LESSER',
      tips: 'Value should be below'
    },
    {
      label: 'Between',
      value: 'BETWEEN',
      tips: 'Value should be inside'
    },
    {
      label: 'Within',
      value: 'BETWEEN-BOTH',
      tips: 'Value should be anywhere around'
    },
    {
      label: 'At least but less than',
      value: 'BETWEEN-START',
      tips: 'Value should be equal or greater than but below'
    },
    {
      label: 'At most but greater than',
      value: 'BETWEEN-END',
      tips: 'Value should be equal or less than but above'
    },
  ],
}

/**
 * 
 * @typedef {Object} ReportFieldFilterProps
 * @property {Array<DownloadDataField>} fields
 * @property {Object} filterValues
 * 
 * @param {ReportFieldFilterProps} param0
 *  
 * @returns {React.Component}
 */
const ReportFieldFilter = ({ fields, filterValues, onCancel, onSubmit }) => {
  const [filter, setFilter] = useState(filterValues || {
    field: null,
    condition: null,
    value: null
  });

  const handleFieldChange = useCallback((name, value) => {
    setFilter(curr => ({
      ...curr,
      [name]: value,
    }));
  }, []);

  const {
    filterType, conditions, filterField, filterOptValues,
  } = useMemo(() => {
    let filterType = null;
    let filterField = null;
    let filterOptValues = [];
    let conditions = [];
    console.log(filter);
    if(!filter.field) {
      return { filterType, conditions, filterField, filterOptValues };
    }
    console.log('fields', fields);
    filterField = fields.find(field => [field.key, field.id].includes(filter.field));
    console.log('filterField', filterField);
    if(!filterField) {
      toast.error('Unable to render filtering for this field');
      return { filterType, conditions, filterField, filterOptValues };
    }
    const fieldRenderAs = filterField?.renderAs;
    console.log('fieldRenderAs', fieldRenderAs);

    filterType = typeof fieldRenderAs === 'string' ? filterField.renderAs : [undefined, null].includes(fieldRenderAs) ? 'string' : 'select';
    if(Array.isArray(fieldRenderAs?.source) && fieldRenderAs?.source?.length > 0){
      filterType = 'string';
    }
    console.log('filterType', filterType);

    if(filterType === 'select') {
      if(fieldRenderAs.options?.length > 0) {
        filterOptValues = fieldRenderAs.options;
      } else {
        const renderAsSource = fieldRenderAs.source;
        const reportOptValLink = [
          `getReportFieldOptions?database=${renderAsSource.database}`,
          `container=${renderAsSource.container}`,
          `partition=${renderAsSource.partition}`,
          `fieldName=${fieldRenderAs.fieldName}`,
          `search=`
        ]
        filterOptValues = {
          link: reportOptValLink.join('&'),
          accessor: 'options',
          labelKey: fieldRenderAs.fieldName,
        }
      }
    } else {
      const typeConditions = FILTER_CONDITIONS[filterType];
      console.log('typeConditions', typeConditions);
      if(typeConditions?.length > 0) {
        console.log('typeConditions Length', typeConditions.length);
        conditions = typeConditions;
        console.log('conditions', conditions);
      } else {
        toast.error('Unable to render filtering for this field');
      }
    }
    if(filterType === 'datetime') filterType = 'datetime-local';
    return { filterType, conditions, filterField, filterOptValues };
  }, [filter, fields]);

  const handleClickSubmit = useCallback((ev) => {
    preventAndStop(ev);
    if(typeof onSubmit === 'function') {
      if(!filter.field) {
        toast.error('Please select a field to filter');
      } else if(conditions?.length > 0 && !filter.condition) {
        toast.error('Please select a condition to satisfy');
      } else if([null, undefined, []].includes(filter.value)) {
        toast.error('Please provide an expected value');
      } else {
        if(filterType === 'select') delete filter.condition;
        onSubmit({ ...filter, conditions });
      }
    }
  }, [onSubmit, filter, conditions, filterType]);

  return (
    <div className='flex flex-col justify-center items-center w-full'>
      <h1 className='text-lg mb-4'>{filterValues ? 'Edit' : 'Add'} Filter</h1>
      <FieldInput
        name='field'
        label='Field to Filter'
        type='select-pill'
        options={fields}
        value={filter.field}
        onChangeFieldValue={handleFieldChange}
        className='w-full'
      />
      {
        filterField ?
        conditions.length > 0 ? 
        <>
          <FieldInput
            name='condition'
            label='Condition to Satisfy'
            type='select-pill'
            options={conditions}
            value={filter.condition}
            onChangeFieldValue={handleFieldChange}
            className='w-full'
          /> 
          {
            filter.condition?.startsWith('BETWEEN') ?
            <>
            </> :
            <FieldInput
              name='value'
              label='Expected Value'
              type={filterType}
              value={filter.value}
              onChangeFieldValue={handleFieldChange}
              className='w-full'
            />
          }
        </> : 
        <FieldInput
          name='value'
          label='Expected Value'
          type='select'
          options={filterOptValues}
          value={filter.value}
          onChangeFieldValue={handleFieldChange}
          className='w-full'
          multiple
        /> : null
      }
      <div
        className='h-20'
      />
      <div className='flex flex-row justify-end items-center w-full space-x-2 relative'>
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={handleClickSubmit}
        >
          {filterValues ? 'Update' : 'Add'}
        </button>
      </div>
    </div>
  )
}

/**
 * 
 * @typedef {Object} ReportFieldFilterAddedProps
 * @property {Array<DownloadDataField>} fields
 * @property {Object} filter
 * 
 * @param {ReportFieldFilterAddedProps} param0
 *  
 * @returns {React.Component}
 */
const ReportFieldFilterCard = ({ filter, fields, onEdit, onDelete }) => {
  const info = useMemo(() => {
    const field = fields.find(field => [field.id, field.key].includes(filter.field));
    if(!field) return null;
    console.log('filter', filter);
    return {
      fieldName: field.label || field.key,
      condition: filter.condition ? filter.conditions.find(condition => condition.value === filter.condition)?.label : ' one of ',
      value: Array.isArray(filter.value) ? filter.value.join(', ') : filter.value,
    };
  }, [fields, filter]);

  const handleEditClick = useCallback(() => onEdit(filter), [filter, onEdit]);
  const handleDeleteClick = useCallback(() => onDelete(filter.id), [filter, onDelete]);

  return (
    info ?
    <div className='w-full rounded-md shadow-md px-4 py-2 flex flex-row justify-between items-center'>
      <p>{info.fieldName} {info.condition} {info.value}</p>
      <div className='flex flex-row space-x-2'>
        <CardButton 
          icon={PencilIcon}
          onClick={handleEditClick}
        />
        <CardButton 
          icon={TrashIcon} 
          warning={{ title: 'Remove Filter', message: 'Are you sure you want to remove this filter'}}
          onClick={handleDeleteClick}
        />
      </div>
    </div> : null
  )
}

/**
 * 
 * @typedef {Object} DataSource
 * @property {string} endpoint
 * @property {string} accessor
 * 
 * @typedef {Object} DownloadDataFieldRenderFromTablePartition
 * @property {string} fieldName // field name to use for comparing partition values
 * @property {string} tblFieldName? // optional if the table connected has different partition name
 * 
 * @typedef {Object} DownloadDataFieldRenderFromTable
 * @property {string} database // which database to fetch the record
 * @property {string} container // which container to fetch the record
 * @property {DownloadDataFieldRenderFromTablePartition} partition // partition to use for retrieving the record
 * 
 * 
 * @typedef {Object} DownloadDataFieldRenderFrom
 * @property {Array<string> | DownloadDataFieldRenderFromTable} source // get the field value from this record by using array of field name or a record from a table from a container
 * @property {boolean} useAsIs // Use the field as is without mapping or finding the id
 * @property {string} fieldName
 *
 * 
 * @typedef {Object} DownloadDataField
 * @property {string} key // Field key
 * @property {string} id // Field id, when there are duplicate keys
 * @property {string} label // field label to use on downloading the report
 * @property {'date' | DownloadDataFieldRenderFrom} renderAs // 
 * 
 * @typedef {Object} DownloadDataObject
 * @property {Array<DownloadDataField | string>} fields
 * @property {string} endpoint
 * 
 * @typedef {Object} ItemListProps
 * @property {DataSource} dataSource
 * @property {Array<ItemListActionProps>} actions
 * @property {React.Component} cardComponent
 * @property {string} listName
 * @property {number} pageSize
 * @property {string} reportModule
 * @property {boolean} showCreate
 * 
 * @param {ItemListProps} param0 
 *  
 * @return {React.Component}
 */
const ItemList = ({ dataSource, cardComponent: CardComponent, pageSize = DEFAULT_PAGE_SIZE, actions = [], listName, reportModule, showCreate = false }) => {
  const { addMsg } = useContext(AlertsContext);
  const { 
    isOpen: isOpenDownload,
    openModal: openModalDownload,
    closeModal: closeModalDownload,
  } = useModal();
  const {
    isOpen: isOpenFilter,
    openModal: openModalFilter,
    initialValues: valuesFilter,
    openModalWithValues: openModalEditFilter,
    closeModal: closeModalFilter,
  } = useModal();
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedRows, setSelectedRows] = useState(GET_ROW_COUNT(pageSize));
  const [reportDate, setReportDate] = useState({});
  const [requestingReport, setRequestingReport] = useState(false);
  const location = useLocation();
  const [removedReportField, setRemovedReportField] = useState([]);
  const [reportFields, setReportFields] = useState({ loading: false, fields: [] });
  const [filters, setFilters] = useState([]);

  const getListData = useCallback(
    /**
     * 
     * @typedef {Object} Pagination
     * @property {number} PageNumber
     * @property {number} PageSize
     * 
     * @param {Pagination} pagination
     *  
     */
    async (pagination) => {
      setIsFetching(true);
      try {
        const respData = await RwardCRUD.post(`${dataSource.endpoint}`, { Pagination: pagination });
        // const respData = restoResponse.data;
        const items = respData.data[dataSource.accessor]; 
        const pages = respData.data.pageCount; 
        setItems(items);
        setPageCount(pages);
        if(showCreate && pagination.PageNumber === 1 && items.length === 0) {
          addMsg({ 
            id: `${listName}-walkthrough`,
            type: 'info', 
            text: `Want us to guide you with creating your first ${listName}?`, 
            closeAutomatically: false,
            link: { 
              to: { pathname: `${location.pathname}/create` }, 
              state: { walkthrough: true }, 
              text: "Let's go"
            }
          })
        }
      // } catch(err) {
      //   addMsg({ type: 'error', text: err?.message });
      } finally {
        setIsFetching(false);
      }
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    getListData({ PageNumber: pageNumber, PageSize: selectedRows.id });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedRowsChange = useCallback((row) => {
    setPageNumber(1);
    setSelectedRows(row);
    getListData({ PageNumber: 1, PageSize: row.id });
  }, [getListData]);
  
  const handlePageChange = useCallback((newPageNum) => {
    setPageNumber(newPageNum);
    getListData({ PageNumber: newPageNum, PageSize: selectedRows.id });
  }, [getListData, selectedRows.id]);

  const handleDateRangeChange = useCallback((_, value) => {
    setReportDate(value);
  }, []);

  const filterableFields = useMemo(() => {
    return reportFields.fields.filter(field => !removedReportField.includes(field.id || field.key)).map((field) => ({
      ...field,
      value: field.id || field.key,
      label: field.label || field.id|| field.key,
    }));
  }, [reportFields, removedReportField]);

  const requestReportDownload = useCallback(async () => {
    if(!reportDate.startDate && !reportDate.endDate) {
      toast.error('Please select a daterange for your reports');
      return;
    } else if(removedReportField.length === reportFields.fields.length) {
      toast.error('Please select atleast one field for your reports');
      return;
    }
    try {
      setRequestingReport(true);
      const response = await RwardCRUD.post('requestUserReport', {
        Module: reportModule,
        Date: reportDate,
        Fields: filterableFields.map(field => field.id || field.key),
        Filters: filters.map(filter => ({ condition: filter.condition || 'ONE-OF', field: filter.field, value: filter.value })),
      }, {
        timeout: 10000,
        hideErrors: {
          Code: ['ECONNABORTED']
        },
      });
      window.open(response.data.fileURL, '_blank');
      toast.success('Successfully downloaded your report file');
    } catch(err) {
      if(err?.code === 'ECONNABORTED') {
        toast.info(`Your report is taking some time to generate, we'll inform you once your report is available. Thank you!`);
      }
    } finally {
      setRequestingReport(false);
      closeModalDownload();
    }
  }, [reportDate, removedReportField, reportFields, reportModule, filterableFields, filters, closeModalDownload]);

  useEffect(() => {
    const getReportFields = async () => {
      if(reportModule) {
        try {
          setReportFields({ loading: true, fields: [] });
          const fields = await RwardCRUD.get(`getReportFields/${reportModule}`);
          setReportFields({ loading: false, fields: fields.data });
        } catch(err) {
          setReportFields({ loading: false, fields: [] });
        }
      }
    }
    getReportFields();
  }, [reportModule]);

  const handleFilterSubmit = useCallback((filter) => {
    if(valuesFilter) {
      setFilters(curr => curr.map((currFilter) => {
        if(currFilter.id === valuesFilter.id) {
          return {
            ...currFilter,
            ...filter,
          }
        }
        return currFilter;
      }));
    } else {
      setFilters(curr => [
        ...curr, {
          ...filter,
          id: getTSNow(),
        }
      ]);
    }
    closeModalFilter();
  }, [closeModalFilter, valuesFilter]);

  const handleRemoveFilter = useCallback((id) => {
    setFilters(curr => curr.filter(filter => filter.id !== id));
  }, []);

  const reportFieldsSelection = useMemo(() => {
    if(reportFields.fields?.length > 0) {
      return reportFields.fields.map((field) => ({ 
        ...field, 
        value: field.id || field.key,
        label: field.label || field.id|| field.key,
      }));
    } else {
      return [];
    }
  }, [reportFields.fields]);

  return (
    <div className="item-list-container">
      <div className="item-list-header">
        <div className="flex flex-wrap gap-2">
          {/* <ItemListActionButton link={`/redeem/code`} text="Enter a code"/> */}
          {/* <ItemListActionButton onClick={() => alert('Coming soon')} text="Delete" icon={XCircleIcon} warning={{ title: ''}}/> */}
          { showCreate ?
            <ItemListActionButton link={`${location.pathname}/create`} text="Create" icon={PlusCircleIcon}/> : null
          }
          {
            actions.map((action) => {
              return (
                <ItemListActionButton {...action} />
                )
              })
          }
          {
            reportModule ?
            <ItemListActionButton onClick={openModalDownload} text="Download Report" icon={ArrowCircleDownIcon}/>
            : null
          }
        </div>
        <div className="item-list-filters">

        </div>
      </div>
      <div className="item-list-items justify-center">
      {
        isFetching ?
        <div className="item-list-loader">
          <Spinner/>
        </div> :
        !!items.length ? 
        items.map((item) => {
          return (
            <CardComponent key={item.id} data={item}/>
          );
        }) :
        <div className="item-list-zero">
          No data to show
        </div> 
      }
      </div>
      <div className="item-list-footer">
        <ItemListPageSizeButton 
          selectedRows={selectedRows}
          onSelectedRowsChange={handleSelectedRowsChange}
        />
        <ItemListPagesButton
          pageNumber={pageNumber}
          pageCount={pageCount}
          onPageNumberChange={handlePageChange}
        />
      </div>

      <ContentModal 
        isOpen={isOpenDownload}
        onClose={closeModalDownload}
        loading={requestingReport}
      >
        <div className='flex flex-col justify-center items-center w-full'>
          <h1 className='text-lg mb-4'>Download Report</h1>
          <FieldInput
            label='Date Range'
            type='daterange'
            name='ReportDateRange'
            tips='Specify date range of the report to download'
            value={reportDate}
            onChangeFieldValue={handleDateRangeChange}
            className='w-full'
          />
          <h2 className='mb-2'>Select fields to extract</h2>
          <div className='flex flex-row flex-wrap justify-center items-center mb-2'>
          {
            reportFields.loading ?
            <Spinner/> :
            reportFields.fields.length > 0 ?
            reportFields?.fields.map((field) => 
              <ReportFieldSelection 
                field={field} 
                removedFields={removedReportField}
                onClick={setRemovedReportField}
              />
            ) :
            <Jumbotron
              title='Report fields error'
              header="We're not able to provide the proper fields for this report"
              tips='Kindly refresh the page, if the error persists please contact the admin.'
            />
          }
          </div>
          {
            filterableFields.length > 0 ?
            <>
            <h2 className='mb-2'>Filter data by fields</h2>
            <ActionButton
              text='Add Filter'
              className='mt-0 self-center'
              onClick={openModalFilter}
            />
            <div className='flex flex-col justify-start w-full mb-2 space-y-2 py-2'>
            {
              filters.map((filter) => 
                <ReportFieldFilterCard
                  key={filter.id}
                  fields={reportFields.fields}
                  filter={filter}
                  onEdit={openModalEditFilter}
                  onDelete={handleRemoveFilter}
                />
              )
            }
            </div>
            </> : null
          }
          <div className='flex flex-row justify-end items-center w-full space-x-2 relative'>
            {/* <ActionButton text='Cancel' onClick={closeModalDownload}/>
            <ActionButton text='Download' onClick={requestReportDownload} disabled={requestingReport}/> */}
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={closeModalDownload}
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={requestReportDownload}
              disabled={requestingReport}
            >
              Download
            </button>
          </div>
        </div>
      </ContentModal>
      <ContentModal 
        isOpen={isOpenFilter}
        onClose={closeModalFilter}
      >
        <ReportFieldFilter
          filterValues={valuesFilter}
          fields={reportFieldsSelection}
          onCancel={closeModalFilter}
          onSubmit={handleFilterSubmit}
        />
      </ContentModal>
    </div>
  );
};

export default ItemList;