import React, { useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { DateRange  } from 'react-date-range';
import { getReadableDate } from '../../helpers/TextFormatter';
import CustomButton from '../buttons/CustomButton';
import classNames from '../../helpers/classNames';

const FieldInputDateRange = ({ name, value, onChange, placeHolder = 'Click to pick date range', onBlur, className, disabled, id }) => {
  const [dateRange, setDateRange] = useState([value]);
  let [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback((ev) => {
    if(typeof ev === 'boolean') {
      setIsOpen(ev);
    } else if(typeof ev?.preventDefault === 'function') {
      ev.preventDefault();
      ev.stopPropagation();
      setIsOpen(false);
    }
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  // const ranges = useMemo(() => {
  //   console.log('value', value);
  //   return [value];
  // }, [value]);

  const handleChangeValue = useCallback((item) => {
    setDateRange([item.range1]);
  }, []);
    // onChange(name, item.range1);
  // }, [name, onChange]);

  const confirmChangeDate = useCallback((ev) => {
    onChange(name, dateRange[0]);
    closeModal(ev);
  }, [closeModal, dateRange, name, onChange]);

  return (
    <>
      <button 
        id={id}
        onClick={openModal}
        disabled={disabled}
        type='button'
        name={name}
        onBlur={(ev) => {
          if(!isOpen && typeof onBlur === 'function') {
            console.log('blur', ev);
            onBlur(ev);
          }
        }}
        className={classNames(
          "form-input w-full rounded-lg disabled:bg-gray-100",
          className,
        )} 
      >
        {getReadableDate(value, placeHolder)}
      </button>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex flex-col justify-center items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Date Range Selection
                  </Dialog.Title>
                  <div className="mt-2">
                    <DateRange  
                      editableDateInputs={true}
                      onChange={handleChangeValue}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                    />
                  </div>
                  <div className="flex flex-row w-full justify-end items-center space-x-2">
                    <CustomButton
                      text='Cancel'
                      onClick={closeModal}
                      btnClassName='px-3 py-1'
                      txtClassName='text-sm'
                    />
                    <CustomButton
                      text='Confirm'
                      onClick={confirmChangeDate}
                      btnClassName='px-3 py-1 bg-blue-400'
                      txtClassName='text-sm'
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FieldInputDateRange;