import React, { useEffect, useMemo, useState } from 'react'
import OtpInput from "react-otp-input";
import classNames from '../../helpers/classNames';

const FieldInputOTP = ({ length = 6, separator = <div className='w-3 h-3 rounded-full bg-rward-blue-dark mx-2'/>, onChange, name, className = '', disabled = false }) => {
  const customSeparator = useMemo(() => {
    if(typeof separator === 'string') {
      return <span>{separator}</span>;
    } else {
      return separator;
    }
  }, [separator]);

  const [otp, setOtp] = useState('');

  useEffect(() => {
    onChange(name, otp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp, name]);

  return (
    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={length}
      separator={customSeparator}
      isDisabled={disabled}
      containerStyle='w-full'
      inputStyle='rounded-md flex-1 last:mr-6 focus:border-rward-blue-dark'
      className={classNames('w-full', className)}
    />
  );
};

export default FieldInputOTP;