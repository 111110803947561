import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useCallback, useState } from 'react';
import classNames from '../../../helpers/classNames';
import RwardCRUD from '../../../helpers/RwardCRUD';

/**
 * @typedef {Object} BranchData
 * @property {string} Email
 * 
 * @typedef {Object} BranchCardProps
 * @property {BranchData} data
 * @property {function} onEditClick
 * @property {function} onDeleteSuccess
 * 
 * @param {BranchCardProps} param0 
 * 
 */
const BranchUser = ({ data, onEditClick, onDeleteSuccess }) => {
  const [deleting, setDeleting] = useState(false);
  const handleEditClick = useCallback(() => {
    onEditClick(data);
  }, [data, onEditClick]);
  const handleDeleteClick = useCallback(async () => {
    if(!deleting) {
      setDeleting(true);
      try {
        await RwardCRUD.post(`deleteBranchUser/${data.id}`);
      } finally {
        setDeleting(false);
        onDeleteSuccess(data.id);
      }
    }
  }, [data, onDeleteSuccess, deleting]);

  return (
    <div className='w-full my-2 rounded-lg shadow-md bg-rward-blue-dark text-white py-2 px-4 flex flex-row justify-between items-center'>
      <p>{data.Email}</p>
      <div className='h-full flex flex-row space-x-2'>
        <PencilIcon 
          className="h-8 w-auto aspect-square rounded-full hover:bg-rward-yellow p-2 cursor-pointer"
          onClick={handleEditClick}
        />
        <TrashIcon 
          className={classNames(
            "h-8 w-auto aspect-square rounded-full hover:bg-rward-yellow p-2 cursor-pointer",
            deleting ? "cursor-wait !bg-gray-500" : ""
          )}
          onClick={handleDeleteClick}
        />
      </div>
    </div>
  );
};

export default BranchUser;