/* eslint-disable jsx-a11y/anchor-is-valid */
// import { ChevronDownIcon } from '@heroicons/react/outline';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import NavigationContext from '../contexts/NavigationContext';
import { SIDENAV_BTN } from '../data/html-ids';
import classNames from '../helpers/classNames';
import routes from '../routes';

// import WalletButton from '../components/buttons/WalletButton';

const VENDOR_LINKS = [
  routes.DASHBOARD,
  routes.REDEEM,
  routes.COLLECTIONS
]

const SideNavMainLink = ({ vendorLink }) => {
  const { expanded } = useContext(NavigationContext);
  const location = useLocation();

  const isOnLink = useMemo(() => vendorLink.link === '/' ? vendorLink.link === location.pathname : location.pathname.startsWith(vendorLink.link), [location.pathname, vendorLink.link]);
  
  return (
    <>
    <li 
      className={classNames(
        'group mx-1 rounded-full transition-all text-white bg-rward-blue-dark hover:rounded-lg cursor-pointer relative',
        isOnLink ? '!bg-rward-yellow' : ''
      )}
    >
      <Link  to={vendorLink.link} className="flex flex-row p-3" id={vendorLink.id}>
        <vendorLink.icon className={`w-6 aspect-square group-hover:animate-wiggle`}/>
        <h1 className={`${expanded ? '' : 'hidden'} ml-2 flex-1`}>{vendorLink.name}</h1>
        {/* {
          !!vendorLink.routes ? 
          <ChevronDownIcon className={`${expanded ? 'hidden md:flex' : 'hidden'} w-6 aspect-square`}/> : null
        } */}
      </Link>
    
    <div className={`hidden ${expanded ? '' : 'group-hover:block'} bg-rward-blue-dark px-3 py-1 rounded-full absolute left-full translate-x-2 top-1/2 -translate-y-1/2 text-white`}>
      {vendorLink.name}
    </div>
    </li>
    </>
  );
}

export default function SideNav() {
  const sideNav = useRef(null);
  const location = useLocation();
  const { currentUser } = useSelector(state => state.auth);
  const { expanded, toggleExpand } = useContext(NavigationContext);

  const onClickOutside = useCallback((ev) => {
    if(sideNav.current && ev.target.id !== SIDENAV_BTN && !sideNav.current.contains(ev.target)) {
      toggleExpand();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(expanded) {
      window.addEventListener('mousedown', onClickOutside);
    }
    
    return () => window.removeEventListener('mousedown', onClickOutside);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const showSideNav = useMemo(() => currentUser && location.pathname !== '/', [currentUser, location]);
  
  return (
    <div 
      ref={sideNav} 
      className={classNames(
        'hidden md:block fixed left-0 top-14 h-full transition-all bg-rward-blue-light py-4',
        showSideNav ? expanded ? 'w-64' : 'w-14' : 'w-0',
      )}
    >
      <ul className="space-y-1 relative">
      {
        showSideNav && VENDOR_LINKS.map((vendorLink) => {
          return (
            <SideNavMainLink 
              vendorLink={vendorLink}
              key={vendorLink.name}
            />
          )
        })
      }
      </ul>
    </div>
  )
}