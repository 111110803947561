import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Jumbotron from '../../../components/jumbotron';
import Wizard from '../../../components/wizard';
import * as Yup from 'yup';
import RwardCRUD from '../../../helpers/RwardCRUD';
import useInterval from '../../../hooks/useInterval';
import { successLogin } from '../../../redux/auth/authSlice';

const Mobile = () => {
  const { otpMobile, currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasSent, setHasSent] = useState(false);
  const [timer, setTimer] = useState(-1);

  useEffect(() => {
    if(!otpMobile) {
      navigate('/dashboard');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpMobile]);

  useInterval(() => {
    if(hasSent) {
      if(timer >= 0) {
        if(timer === 0) {
          setHasSent(false);
        }
        setTimer(timer - 1);
      } else {
        setTimer(180);
      }
    }
  }, 1000);

  return (
    <div className='info-page'>
      <div className='info-container items-center'>
        <Jumbotron
          title='Account Verification (Mobile)'
          header="You're account is restricted to access the system since you haven't verified your mobile."
          tips='Follow the steps below to verify your account'
        />
        <Wizard
          forms={[
            {
              title: 'Request OTP',
              description: "We'll send an OTP to your Mobile and use that OTP to validate your account",
              initialValues: {
                Mobile: currentUser.Mobile,
              },
              fields: [
                {
                  type: 'text',
                  disabled: true,
                  label: 'Mobile',
                  name: 'Mobile',
                  className: 'max-w-md'
                }
              ],
              submitText: 'Request OTP',
              disableSubmit: hasSent,
              disableSubmitText: `Request again in ${timer + 1} seconds`,
              actionsClassName: '!justify-start',
              onSubmit: async () => {
                await RwardCRUD.post('requestVendorOTP', { Medium: 'SMS' });
                setHasSent(true);
              },
              additionalValidation: hasSent,
            },
            {
              title: 'Submit OTP',
              description: "Please enter the OTP you received from your mobile",
              initialValues: {
                OTP: "",
              },
              schema: Yup.object().shape({
                OTP: Yup.string().required('Please enter the otp').length(6, 'Please enter the 6 character OTP')
              }),
              fields: [
                {
                  type: 'otp',
                  length: 6,
                  label: 'OTP',
                  name: 'OTP',
                  className: 'max-w-md'
                }
              ],
              submitText: 'Submit',
              actionsClassName: '!justify-start',
              onSubmit: async (values) => {
                const otpResponse = await RwardCRUD.post('validateVendorOTP', { Medium: 'SMS', OTP: values.OTP });
                dispatch(successLogin({ user: otpResponse.data.user }));
              }
            },
          ]}

        />
      </div>
    </div>
  );
};

export default Mobile;