import React from 'react'
import { useSelector } from 'react-redux';

const Profile = () => {
  const { currentUser } = useSelector(state => state.auth);

  return (
    <div className='w-full h-full flex p-4'>
      {JSON.stringify(currentUser)}
    </div>
  );
};

export default Profile;