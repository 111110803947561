import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import RedeemCard from '../../components/cards/Redeem/RedeemCard';
import { FormWithValidation } from '../../components/forms';
import { ItemList } from '../../components/list';
import { ContentModal } from '../../components/modal';
import RwardCRUD from '../../helpers/RwardCRUD';
import { getReadableTime } from '../../helpers/TextFormatter';
import useModal from '../../hooks/useModal';
import { updateBranch } from '../../redux/auth/authSlice';
import { isForThisServer } from '../../setupConfig';
import { useState } from 'react';

const DATA_SOURCE = {
    endpoint: 'getBranchRedemptions',
    accessor: 'redemptions'
}


const RedeemLogs = () => {
    const dispatch = useDispatch();
    const { 
        isOpen: isOpenGeoloc, 
        openModal: openGeolocModal,
        closeModal: closeGeolocModal,
    } = useModal();
    const { branchInfo } = useSelector(state => state.auth);
    const [geolocationEnabled, setGeolocationEnabled] = useState(!branchInfo.DisableGeolocation)
    const { autoApproveState, toggleStateTo, geoloc } = useMemo(() => {
        const approval = branchInfo.AutoApprove ? {
            autoApproveState: 'ON',
            toggleStateTo: 'OFF',
        } : {
            autoApproveState: 'OFF',
            toggleStateTo: 'ON',
        };
        const geolocRequirements = branchInfo?.DisableGeolocation;
        const geoloc = {
            disabled: false,
            time: '',
            transaction: 0,
        };

        if(![null, undefined].includes(geolocRequirements)) {
            if(typeof geolocRequirements === 'boolean') {
                if(!geolocRequirements) {
                    Object.assign(geoloc, geolocRequirements);
                }
            } else {
                Object.assign(geoloc, geolocRequirements);
            }
        }
        return {
            ...approval,
            geoloc: {
                ...geoloc,
                state: !geolocationEnabled ? 'OFF' : 'ON',
            },
        }
    }, [branchInfo, geolocationEnabled]);
    const toggleAutoApprove = useCallback(async () => {
        const toggleResponse = await RwardCRUD.get('toggleBranchAutoApproval');
        dispatch(updateBranch({ branch: toggleResponse.data }));
    }, [dispatch]);

    const handleSubmitSuccess = useCallback((response) => {
        setGeolocationEnabled(!response.data.DisableGeolocation)
        closeGeolocModal()
    }, [closeGeolocModal])

    return(
        <div className="collection-list-content">
            <ItemList 
                dataSource={DATA_SOURCE}
                cardComponent={RedeemCard}
                listName='redemptions'
                showCreate={false}
                actions={[
                    {
                        text: 'Enter a Code',
                        link: '/redeem/code',
                    },
                    {
                        text: `Auto Approve (${autoApproveState.toUpperCase()})`,
                        onClick: toggleAutoApprove,
                        warning: {
                            title: 'Toggle Auto Approve',
                            message: `Are you sure you want to turn ${toggleStateTo} Auto Approve?`,
                        }
                    },
                    {
                        text: `Realtime Location (${geoloc.state})`,
                        onClick: openGeolocModal,
                    }
                ]}
                reportModule={'Redemption'}
            />
            <ContentModal
                isOpen={isOpenGeoloc}
                onClose={closeGeolocModal}
            >
                <FormWithValidation
                    formName='DisableGeolocation'
                    formTitle='Toggle Geolocation'
                    containerClassName='shadow-none m-0'
                    initialValues={{
                        DisableGeolocation: geolocationEnabled,
                        // TimeReEnable: typeof geoloc.disabled === 'boolean' ? null : geoloc.disabled,
                        // TransactionReEnable: 0,
                    }}
                    hideFieldWhen={{
                        // TimeReEnable: (values) => values.EnableGeolocation, 
                        // TransactionReEnable: (values) => values.EnableGeolocation, 
                    }}
                    fields={[
                        {
                            name: 'DisableGeolocation',
                            type: 'switch',
                            label: 'Enable Geolocation',
                            disabled: false,
                            className: 'flex-row items-center mb-2',
                        },
                        // {
                        //     name: 'TimeReEnable',
                        //     type: 'time',
                        //     label: 'Re-enable by time',
                        //     tips: 'Automatically re-enable the realtime location requirements until the specified time',
                        //     className: 'w-1/2'
                        // },
                        // {
                        //     name: 'TransactionReEnable',
                        //     type: 'number',
                        //     label: 'Re-enable by transaction',
                        //     tips: 'Automatically re-enable the realtime location requirements when approved transactions reached this count',
                        //     className: 'w-1/2'
                        // },
                    ]}
                    submitLink='toggleBranchGeoloc'
                    onSubmitSuccess={handleSubmitSuccess}
                    onPreSubmitTransform={(values) => {
                        return values;
                    }}
                />
            </ContentModal>
        </div>
    )
}

export default RedeemLogs;