/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment, useCallback, useContext, useMemo } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux';
import {
  LogoutIcon,
  XIcon,
} from '@heroicons/react/outline'
import { Link, useNavigate } from 'react-router-dom';
import CustomHeaderLogo from '../components/brand/Logo'
import { MenuIcon, MenuAlt1Icon } from '@heroicons/react/solid';
import NavigationContext from '../contexts/NavigationContext';
import VendorButton from './VendorButton';
import NotificationButton from './NotificationButton';
import { SIDENAV_BTN } from '../data/html-ids';
import classNames from '../helpers/classNames';
import AuthenticationButton from './AuthenticationButton';
import { useLocation } from 'react-router-dom';
import { ReactComponent as FacebookSVG } from '../assets/svg/Facebook.svg';
import { ReactComponent as TwitterSVG } from '../assets/svg/Twitter.svg';
// import { ReactComponent as LinkedInSVG } from '../assets/svg/LinkedIn.svg';
import { ReactComponent as InstagramSVG } from '../assets/svg/Instagram.svg';
import SocialMediaLanding from '../components/buttons/SocialMediaLanding';
import useScrollPosition from '../hooks/useScrollPosition';
import { useCookies } from 'react-cookie';
import { logoutUser } from '../redux/auth/authSlice';
import RwardCRUD from '../helpers/RwardCRUD';
import COOKIE_NAMES from '../data/cookies';
// import WalletButton from '../components/buttons/WalletButton';

export default function Header() {
  const { expanded, toggleExpand } = useContext(NavigationContext);
  const { currentUser } = useSelector(state => state.auth);
  const location = useLocation();
  const scrollPosition = useScrollPosition();
  const [cookie, , removeCookie] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showSideNav = useMemo(() => currentUser && location.pathname !== '/', [currentUser, location]);

  const headerBG = useMemo(() => {
    return showSideNav ? "bg-rward-blue-light !py-0" : 
    scrollPosition > 600 ? "bg-rward-blue-dark/80" :
    scrollPosition > 150 ? "bg-rward-blue-dark/50" : ""
  }, [showSideNav, scrollPosition]);

  const goTo = useCallback((link) => {
    navigate(link);
  }, [navigate]);

  const handleLogoutClick = useCallback(async () => {
    RwardCRUD.get('branchUserOffline', { headers: { 'rward-authorization': cookie[COOKIE_NAMES.TOKEN] }});
    removeCookie(COOKIE_NAMES.TOKEN, { secure: true, path: '/' });
    dispatch(logoutUser());
    goTo('/');
  }, [cookie, dispatch, goTo, removeCookie]);

  
  return (
    <Popover className={classNames("fixed top-0 z-50 drop-shadow-2xl w-full h-max transition-all duration-500 py-4", headerBG)}>
      <div className="mx-auto px-6 relative max-h-16">
        <div className={classNames("flex justify-between items-center", showSideNav ? "py-2" : "py-4")}>
          <div className='hidden md:flex flex-row justify-center items-center space-x-3 lg:space-x-6'>
            <button id={SIDENAV_BTN} className={classNames("inline-flex custom-nav-btn mr-4 -translate-x-4 transition-all", showSideNav ? "w-auto p-2" : "w-auto p-2 md:w-0 md:p-0")} onClick={toggleExpand}>
              <span className="sr-only">Expand menu</span>
              {
                expanded ?
                <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" /> :
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              }
            </button>
            {
              showSideNav ? null :
              <>
                <SocialMediaLanding
                  link={'https://www.facebook.com/rwardsnft'}
                  className='hidden md:flex'
                  svg={FacebookSVG}
                />
                {/* <SocialMediaLanding
                  link={'https://linkedin.com'}
                  className='hidden md:flex'
                  svg={LinkedInSVG}
                /> */}
                <SocialMediaLanding
                  link={'https://www.instagram.com/rwardsnft/'}
                  className='hidden md:flex'
                  svg={InstagramSVG}
                />
                <SocialMediaLanding
                  link={'https://twitter.com/rwardsnft'}
                  className='hidden md:flex'
                  svg={TwitterSVG}
                />
              </>
            }
          </div>
          <div className='flex flex-row space-x-3 lg:space-x-16 justify-center items-center text-lg'>
            <Link to={currentUser ? "/dashboard" : "/"}>
              <span className="sr-only">Rward WebApp</span>
              <CustomHeaderLogo showSideNav={showSideNav}/>
            </Link>
          </div>
          <Popover.Group as="div" className="flex flex-row space-x-1 justify-end">
            <NotificationButton/>
            {/* <VendorButton/> */}
            
            <button className="custom-nav-btn relative" onClick={handleLogoutClick} data-show={!!currentUser}>
              <LogoutIcon className={classNames(currentUser ? "h-8 w-8" : 'h-0 w-0' )}/>
            </button>
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-b2b-blue-dark/90 divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <CustomHeaderLogo/>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-b2b-gold opacity-75 rounded-md p-2 inline-flex items-center justify-center text-white hover:opacity-100">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {/* <HashLink
                    className="custom-popup-link"
                    to={'/#welcome'}
                    smooth
                  >
                    Home
                  </HashLink>
                  <HashLink
                    className="custom-popup-link"
                    to={'/#about'}
                    smooth
                  >
                    About
                  </HashLink>
                  <HashLink
                    className="custom-popup-link"
                    to={'/#utilities'}
                    smooth
                  >
                    Utilities
                  </HashLink> */}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}