import { createSlice } from '@reduxjs/toolkit'
import { Cookies } from 'react-cookie';
import COOKIE_NAMES from '../../data/cookies';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    branchInfo: null,
    loggingIn: false,
    otpEmail: false,
    otpMobile: false,
    tempPassword: false,
    authenticatingToken: new Cookies().get(COOKIE_NAMES.TOKEN),
  },
  reducers: {
    successLogin: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.currentUser = action.payload.user;
      state.branchInfo = action.payload.branch;
      state.tempPassword = !!action.payload.user.TemporaryPassword;
      state.otpEmail = !action.payload.user.EmailVerified;
      state.otpMobile = action.payload.user.Mobile && !action.payload.user.MobileVerified;
      state.loggingIn = false;
    },
    logoutUser: (state) => {
      state.currentUser = null;
      state.branchInfo = null;
      state.otpEmail = false;
      state.otpMobile = false;
      state.tempPassword = false;
    },
    setAuthenticatingToken: (state, action) => {
      state.authenticatingToken = action.payload.isAuthenticating;
    },
    updateBranch: (state, action) => {
      state.branchInfo = action.payload.branch;
    }
  },
})

// Action creators are generated for each case reducer function
export const { successLogin, logoutUser, setAuthenticatingToken, updateBranch } = authSlice.actions;

export default authSlice.reducer