import React, { useCallback, useRef } from 'react';
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from '../../setupConfig';
import Spinner from '../loading/Spinner';
import classNames from '../../helpers/classNames';
import { geocodeByAddress, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete';

const libraries = ['places'];
const FieldInputGeolocation = ({ className, name, value, onChange, ...rest }) => {
  const location = useRef()
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries
  });

  const handlePlaceChange = useCallback(() => {
    console.log(location.current);
    const inputAddress = location.current.value;
    try{
      let address = {};
      geocodeByAddress(inputAddress)
      .then(results => {
        address = results[0].address_components
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        let geo = {
          label: inputAddress,
          info: address,
          geo: {latitude: lat, longitude: lng}
        };
        onChange(name, geo);
      });
    } catch(e){
      console.log(e)
    }
  }, [name, onChange]);

  return (
    isLoaded ? <Autocomplete className='w-full' restrictions={{country: 'ph'}} onPlaceChanged={handlePlaceChange}>
      <input {...rest} className={classNames('w-full', className)} ref={location} type={'text'}/>
    </Autocomplete> : <Spinner/>
  );
};

export default FieldInputGeolocation;