import { CheckCircleIcon, ClockIcon, XCircleIcon } from '@heroicons/react/outline';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from '../../../helpers/classNames';

const REDEEM_STATUS = {
    P: {
        text: 'Waiting',
        icon: ClockIcon,
        divColor: 'bg-orange-200 text-orange-400',
        fgColor: 'stroke-orange-400'
    },
    X: {
        text: 'Declined',
        icon: XCircleIcon,
        divColor: 'bg-red-300 text-red-600',
        fgColor: 'stroke-red-600'
    },
    A: {
        text: 'Approved',
        icon: CheckCircleIcon,
        divColor: 'bg-green-300 text-green-600',
        fgColor: 'stroke-green-600'
    }
}

const RedeemCard = ({ data }) => {
    const { perkName, status } = useMemo(() => {
        return {
            perkName: data.Voucher?.Perks?.find(perk => perk.id === data.PerksID)?.Name || 'Invalid Claim',
            status: REDEEM_STATUS[data.Status],
        }
    }, [data]);

    return(
        <Link 
            to={`/redeem/${data?.id}`} 
            className="card redemption-card group hover:bg-rward-yellow"
        >
            <img className="rounded-md w-full h-2/3 xs:h-1/2 object-cover object-top" src={data?.Voucher?.Thumbnail} alt="" />
                {/* <div className="relative h-0 pb-[66.6666%] pt-[33.3333%]">
                </div> */}
            <div className="h-1/2 p-3 flex flex-col justify-center items-center w-full">
                <h1 className="text-lg font-semibold tracking-tight text-gray-900 text-center mb-2">{data?.Voucher?.Name}</h1>
                {/* <h1 className="text-2xl tracking-tight text-gray-900 text-center mb-2">{perkName}</h1> */}
                <h1 className="text-2xl tracking-tight text-gray-900 text-center mb-2">Sample Perk</h1>
                <div className='flex flex-row sm:flex-col justify-between sm:justify-center items-center w-full'>
                    <p className="rounded-full p-2 xs:px-3 xs:py-1 text-white bg-rward-blue-dark xs:mb-2 tracking-widest">{data?.Code}</p>
                    {/* <div className={classNames(
                        "rounded-full p-2 xs:py-1 flex flex-row justify-center items-center",
                        // status.divColor,
                        "bg-orange-200 text-orange-400"
                    )}> */}
                        <div className="rounded-full p-2 xs:py-1 flex flex-row justify-center items-center bg-orange-200 text-orange-400">
                        {/* <p className='hidden xs:block'>{status.text}</p> */}
                        {/* {status.text} */}
                        Sample Text
                        {/* {<status.icon className={classNames(
                            'ml-2 w-4 h-4',
                            status.fgColor
                        )}/>} */}
                    </div>
                </div>
            </div>
        </Link>
    )

}

export default RedeemCard