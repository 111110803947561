import React, { useCallback } from 'react';
import classNames from '../../helpers/classNames';

const FieldInputFile = ({ className = '', name, onChange, ...rest}) => {
  const handleChangeValue = useCallback((ev) => {
    onChange(name, ev.target.files[0]);
  }, [onChange, name]);

  return (
    <input 
      type="file" 
      name={name}
      onChange={handleChangeValue}
      {...rest}
      value={undefined}
      className={classNames(
        `block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-semibold
        file:bg-blue-400 file:text-white
        hover:file:bg-blue-500
        file:cursor-pointer
        cursor-pointer
        `,
        className
      )}
    />
  );
};

export default FieldInputFile;