import React, { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from './layouts/AppLayout';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorNotFound } from './pages/errors';
import Spinner from './components/loading/Spinner';
import routes from './routes';
import { useSelector, useDispatch } from 'react-redux';
import { Cookies, useCookies } from 'react-cookie';
import COOKIE_NAMES from './data/cookies';
import { setAuthenticatingToken, successLogin } from './redux/auth/authSlice';
import AlertsContext from './contexts/AlertsContext';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import withAppInsights from './plugins/AppInsights';
import { AuthenticatedRoute } from './components/route';
import RwardNotif from './helpers/RwardNotif';
import { CreateVoucherPage } from './pages/voucher';
import RwardCRUD from './helpers/RwardCRUD';
import { CreateBranchPage } from './pages/branch';
import { useLocation } from 'react-router-dom';
import { CollectionCreatePage } from './pages/collection';
import useInterval from './hooks/useInterval';
import LazyLoadSpinner from './components/loading/LazyLoadSpinner';

// const TEMP_USER = {
//   id: '1',
//   Name: 'Jason Ryan Cruz',
//   Email: 'cruz.ryanjason@gmail.com',
// }

const AuthenticatingTokenComp = () => {

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Spinner/>
    </div>
  );
}

const App = withAppInsights(() => {
  const { currentUser, authenticatingToken } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [cookie, , removeCookie] = useCookies([COOKIE_NAMES.TOKEN]);
  /** @type {[W3CWebSocket, function]} */
  const [wsClient, setWsClient] = useState(null);
  const { addMsg } = useContext(AlertsContext);

  const requestWsURL = useCallback(async () => {
    try {
      let notifResponse = await RwardNotif.get(`negotiateUser`);
      if(!('valid' in notifResponse)) {
        notifResponse = notifResponse.data;
      }
      if(notifResponse.valid) {
        const notifData = notifResponse.data;
        setWsClient(currWsClient => {
          if(!currWsClient) {
            const newWSClient = new W3CWebSocket(notifData.url);

            newWSClient.onopen = () => {
              console.log('Listening to notifications');
            }
      
            newWSClient.onmessage = (msg) => {
              const notifData = JSON.parse(msg.data);
              // console.log(notifData);
              addMsg({ type: 'success', text: notifData.Message, link: { to: { pathname: notifData.Data?.Link }, text: 'View Request'}, closeAutomatically: false });
            }
            return newWSClient;
          }
          return currWsClient;
        });
      }
    } catch(err) {
      console.log(err);
    }
  }, [addMsg]);

  const reauthToken = useCallback(async () => {
    dispatch(setAuthenticatingToken({ isAuthenticating: true }));
    try {
      let authResponse = await RwardCRUD.get(`reauthToken`);
      if(!('valid' in authResponse)) {
        authResponse = authResponse.data;
      }
      if(!authResponse.valid) {
        dispatch(setAuthenticatingToken({ isAuthenticating: false }));
        removeCookie(COOKIE_NAMES.TOKEN);
        if(location.pathname !== '/') navigate('/');
        return;
      }
      dispatch(successLogin({ user: authResponse.data.user, branch: authResponse.data.branch }));
      dispatch(setAuthenticatingToken({ isAuthenticating: false }));
      if(location.pathname === '/') navigate('/dashboard');
    } catch(err) {
      removeCookie(COOKIE_NAMES.TOKEN);
      if(location.pathname !== '/') navigate('/');
      return;
    }
  }, [dispatch, location.pathname, navigate, removeCookie]);

  const homeElement = useMemo(() => {
    return authenticatingToken ? <AuthenticatingTokenComp/>  : <routes.HOME.element/>;
  }, [authenticatingToken]);

  useEffect(() => {
    if(cookie.token) {
      if(!currentUser) {
        reauthToken();
      }
      requestWsURL();
    } else {
      console.log('NO MORE TOKENS');
      if(typeof wsClient?.close === 'function') {
        wsClient.close();
        setWsClient(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie, currentUser]);

  useInterval(() => {
    // added checking of node env to prevent persistent request on local development, 
    // remove the condition process.env.NODE_ENV === 'production' if you want to test this feature locally
    if(process.env.NODE_ENV === 'production' && new Cookies().get(COOKIE_NAMES.TOKEN)) {
      RwardCRUD.get('branchUserOnline');
    };
  }, 10000, true);
  
  return (
    <AppLayout>
      <Suspense fallback={<Spinner/>}>
      <Routes>
        <Route index element={homeElement}/>
        <Route path={routes.DASHBOARD.link} element={<AuthenticatedRoute><routes.DASHBOARD.element/></AuthenticatedRoute>}/>
        <Route path={routes.REDEEM.link}>
          <Route index element={<AuthenticatedRoute><routes.REDEEM.element/></AuthenticatedRoute>}/>
        </Route>
        <Route path = {routes.REDEEM.link}>
          <Route index element = {<AuthenticatedRoute><routes.REDEEM.element/></AuthenticatedRoute>}/>
          <Route path={routes.REDEEM.routes.REDEEM_INFO.link} element={<AuthenticatedRoute><routes.REDEEM.routes.REDEEM_INFO.element/></AuthenticatedRoute>}/>
          <Route path={routes.REDEEM.routes.REDEEM_CODE.link} element={<AuthenticatedRoute><routes.REDEEM.routes.REDEEM_CODE.element/></AuthenticatedRoute>}/>
          <Route path={routes.REDEEM.routes.REDEEM_CODE_INFO.link} element={<AuthenticatedRoute><routes.REDEEM.routes.REDEEM_CODE_INFO.element/></AuthenticatedRoute>}/>
        </Route>
        <Route path={routes.COLLECTIONS.link}>
          <Route index element={<AuthenticatedRoute><Suspense fallback={<LazyLoadSpinner/>}><routes.COLLECTIONS.element/></Suspense></AuthenticatedRoute>}/>
          <Route path="create" element={<AuthenticatedRoute><Suspense fallback={<LazyLoadSpinner/>}><CollectionCreatePage/></Suspense></AuthenticatedRoute>}/>
          {/* <Route path={routes.BRANCHES.routes.VOUCHERS.link} element={<routes.BRANCHES.routes.VOUCHERS.element/>}/> */}
          <Route path={routes.COLLECTIONS.routes.INFORMATION.link} element={<AuthenticatedRoute><Suspense fallback={<LazyLoadSpinner/>}><routes.COLLECTIONS.routes.INFORMATION.element/></Suspense></AuthenticatedRoute>}/>
          <Route path={routes.COLLECTIONS.routes.MINT_REQUEST_INFO.link} element={<AuthenticatedRoute><Suspense fallback={<LazyLoadSpinner/>}><routes.COLLECTIONS.routes.MINT_REQUEST_INFO.element/></Suspense></AuthenticatedRoute>}/>
        </Route>
        <Route path={routes.ACCOUNT.link}>
          <Route index element={<AuthenticatedRoute><routes.ACCOUNT.element/></AuthenticatedRoute>}/>
          <Route path={routes.ACCOUNT.routes.CREATE_PASSWORD.link} element={<AuthenticatedRoute><routes.ACCOUNT.routes.CREATE_PASSWORD.element/></AuthenticatedRoute>}/>
          <Route path={routes.ACCOUNT.routes.OTP.link}>
            <Route path={routes.ACCOUNT.routes.OTP.routes.EMAIL.link} element={<AuthenticatedRoute><routes.ACCOUNT.routes.OTP.routes.EMAIL.element/></AuthenticatedRoute>}/>
            <Route path={routes.ACCOUNT.routes.OTP.routes.MOBILE.link} element={<AuthenticatedRoute><routes.ACCOUNT.routes.OTP.routes.MOBILE.element/></AuthenticatedRoute>}/>
          </Route>
        </Route>
        <Route path={routes.SETTINGS.link}>
          <Route index element={<AuthenticatedRoute><routes.SETTINGS.element/></AuthenticatedRoute>}/>
        </Route>
        <Route path={routes.ERRORS.link}>
          <Route index element={<routes.ERRORS.element/>}/>
          <Route path={routes.ERRORS.routes.ERROR_404.link} element={<routes.ERRORS.routes.ERROR_404.element/>}/>
          <Route path={routes.ERRORS.routes.ERROR_401.link} element={<routes.ERRORS.routes.ERROR_401.element/>}/>
        </Route>
        <Route path="*" element={<ErrorNotFound/>}/>
      </Routes>
      </Suspense>
    </AppLayout>
  );
});

export default App;
