import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import RwardCRUD from '../../helpers/RwardCRUD';
import Spinner from '../../components/loading/Spinner';
import errorIcon from '../../assets/img/error.png'
import successIcon from '../../assets/img/success.png'
import { Link } from 'react-router-dom';


const RedeemInfo = () => {
    const { redeemID, redeemCode } = useParams();
    const [redeemInfo, setRedeemInfo] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const [perkInfo, setPerkInfo] = useState();
    const [status, setStatus] = useState();

    const getRedemptionInfo = useCallback(async () => {
        if(redeemID){
            const redeemData = await RwardCRUD.get(`getRedeemInformation/${redeemID}`)
            setRedeemInfo(redeemData.data)
            setIsFetching(false)
        } else if(redeemCode) {
            const redeemData = await RwardCRUD.get(`getRedeemInformation/${redeemCode}`)
            setRedeemInfo(redeemData.data)
            setIsFetching(false)
        }
    }, [redeemID, redeemCode])

    const handleYesClick = async () => {
        const request = await RwardCRUD.post(`approveVoucherRedemption/${redeemInfo.Code}`)
        if(request.valid){
            console.log(request)
            setStatus(request.data.Status)
        }
    }

    const handleNoClick = async () => {
        const request = await RwardCRUD.post(`rejectVoucherRedemption/${redeemInfo.Code}`)
        if(request.valid){
            console.log(request)
            setStatus(request.data.Status)
        }
    }

    useEffect(() => {
        getRedemptionInfo();
    }, [getRedemptionInfo])

    useEffect(() => {
        console.log(redeemInfo)
        setStatus(redeemInfo?.Status)
        var result = redeemInfo?.Voucher?.Perks?.find(obj => {return obj.id === redeemInfo.PerksID})
        setPerkInfo(result)
    }, [redeemInfo])

    useEffect(() => {
        console.log(perkInfo)
    }, [perkInfo])

    return(
        <div className='w-full h-full flex flex-col justify-center items-center p-6'>
            { isFetching ? 
            <Spinner/> : 
            <div className='bg-white shadow-xl rounded-lg w-[calc(100%)] lg:w-[calc(70%)] py-10 px-6 flex flex-col justify-center items-center space-y-4 '>
                <div className='text-4xl text-center w-[calc(100%)]'>{redeemInfo?.Voucher?.Name}</div>
                { status === 'P' ? 
                <>
                <div className='text-2xl text-center w-[calc(100%)]'>A customer wants to redeem the following perk:</div>
                <div className='rounded-lg bg-rward-blue-dark text-white p-6 w-fit flex flex-col justify-center items-center'>
                    <h1 className='text-3xl text-center mb-4'>Code: {redeemInfo?.Code}</h1>
                    <h2 className='text-2xl text-center bg-white rounded-md p-4 text-black mb-4'>{perkInfo?.Name}</h2>
                    <p className='text-lg text-center'>{perkInfo?.Description}</p>
                </div>
                <div className='text-3xl text-center w-[calc(100%)]'>Do you accept?</div>
                <div className = "w-full flex flex-row justify-center items-center">
                <button
                className='bg-rward-yellow hover:bg-yellow-600 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                onClick = {handleYesClick}>Yes</button>
                <button
                className='bg-red-500 hover:bg-red-700 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                onClick = {handleNoClick}>No</button>
                </div>
                </>
                : status === 'X' ?
                <>
                <div className='text-2xl text-center w-[calc(100%)]'>The customer wants to redeem the following perk:</div>
                <div className='rounded-lg bg-rward-blue-dark text-white p-6 w-fit flex flex-col justify-center items-center'>
                    <h1 className='text-3xl text-center mb-4'>Code: {redeemInfo?.Code}</h1>
                    <h2 className='text-2xl text-center bg-white rounded-md p-4 text-black mb-4'>{perkInfo?.Name}</h2>
                    <p className='text-lg text-center'>{perkInfo?.Description}</p>
                    <div className='rounded-full bg-red-300 mt-2 py-2 px-4 flex flex-row justify-center items-center'>
                        <p className='mr-3'>Declined</p>
                        <img className="w-6 h-6" src={errorIcon} alt='Rejected Redemption'/>
                    </div>
                </div>
                <div className='text-2xl text-center w-[calc(100%)]'>You have already rejected this request. </div>
                <div className='text-2xl text-center w-[calc(100%)]'>Do you want to accept it instead?</div>
                <div className = "w-full flex flex-row justify-center items-center">
                <button
                className='bg-rward-yellow hover:bg-yellow-600 disabled:bg-gray-300 text-white m-4 font-bold py-6 px-12 rounded-full'
                onClick={handleYesClick}>Yes</button>
                </div>
                <Link to='/redeem' className='mt-4 bg-rward-red text-white px-4 py-2 rounded-full text-xl'>
                    CLOSE
                </Link>
                </>
                : status === 'A' ? 
                <>
                <div className='text-2xl text-center w-[calc(100%)]'>The customer redeemed the following perk:</div>
                <div className='rounded-lg bg-rward-blue-dark text-white p-6 w-fit flex flex-col justify-center items-center'>
                    <h1 className='text-3xl text-center mb-4'>Code: {redeemInfo?.Code}</h1>
                    <h2 className='text-2xl text-center bg-white rounded-md p-4 text-black mb-4'>{perkInfo?.Name}</h2>
                    <p className='text-lg text-center'>{perkInfo?.Description}</p>
                    {
                        redeemInfo?.RedeemedPromoCode ?
                        <p className='text-lg text-center'>Redeemed Promo Code: 
                            <p className='bg-rward-yellow text-black rounded-md px-2 py-1 mt-2'>{redeemInfo?.RedeemedPromoCode}</p>
                        </p> :
                        null
                    }
                    <div className='rounded-full bg-green-300 mt-2 py-2 px-4 flex flex-row justify-center items-center'>
                        <p className='mr-3 text-black'>Accepted</p>
                        <img className="w-6 h-6" src={successIcon} alt='Rejected Redemption'/>
                    </div>
                </div>
                <div className='text-2xl text-center w-[calc(100%)]'>You have already accepted this request. Nothing else you can do here.</div>
                <Link to='/redeem' className='mt-4 bg-rward-red text-white px-4 py-2 rounded-full text-xl'>
                    CLOSE
                </Link>
                </> 
                :
                null
                }
            </div>
            }
        </div>
    )

}

export default RedeemInfo