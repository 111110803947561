import React, { useCallback, useEffect, useState } from 'react';
import { getTime } from 'date-fns';
import RwardCRUD from '../helpers/RwardCRUD';
import RwardNotif from '../helpers/RwardNotif';
import LikhaCRUD from '../helpers/LikhaCRUD';
import { toast } from 'react-toastify';

const AlertsContext = React.createContext({});

export const AlertsProvider = ({ children }) => {
  const [msgs, setMsgs] = useState([]);
  const [hideNotifs, setHideNotifs] = useState([]);

  const removeMsg = useCallback((id) => {
    setMsgs(currMsgs => currMsgs.filter(value => value.id !== id));
  }, []);

  const addMsg = useCallback(
  /**
   * @typedef {Object} Link
   * @property {string} to
   * @property {string} text
   * 
   * @typedef {Object} MsgObject 
   * @typedef {string} id 
   * @property {string} text
   * @property {'success' | 'error' | 'warning' | 'info'} type
   * @property {Link} extLink
   * @property {Link} link
   * @property {Link} hashLink
   * 
   * @param {MsgObject} param0 
   */
  function ({ id, text, type, extLink, link, hashLink, closeAutomatically = true, action }) {
    const msgId = id || getTime(new Date());
    setMsgs(currMsgs => {
      if(currMsgs.find(msg => msg.id === id)) {
        return currMsgs;
      }
      return [
        ...currMsgs,
        {
          text,
          type,
          id: msgId,
          extLink,
          link,
          hashLink,
          closeAutomatically,
          action,
        }
      ];
    });
    return msgId;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onFullfilled = (response) => {
      if (response.data.valid) return response.data;
      let errorMsg = '';
      if(typeof response.data.errorMsg === 'string') {
        errorMsg = response.data.errorMsg;
      } else if(typeof response.data.errorMsg === 'object') {
        if(Array.isArray(response.data.errorMsg)) {
          errorMsg = response.data.errorMsg[0];
        } else if(typeof response.data.errorMsg?.message === 'string') {
          errorMsg = response.data.errorMsg?.message;
        } else {
          errorMsg = 'System Error: Please contact the admin';
        }
      }
      console.log('onfullfil error', response);
      toast.error(errorMsg);
      throw new Error(errorMsg);
      // return Promise.reject({
        //   message: errorMsg
      // })
    };
    
    const onRejected = (response) => {
      console.log('onRejected response', response);
      const configHideErrors = response?.config?.hideErrors;
      if(configHideErrors) {
        const hideErrorCodes = configHideErrors.Code;
        if(Array.isArray(hideErrorCodes) && hideErrorCodes.length > 0) {
          if(hideErrorCodes.includes(response.code)) throw response;
        }
      }
      toast.error(response.message);
      throw new Error(response.message);
      // return Promise.reject({
      //   message: response.message
      // })
    };
    RwardCRUD.interceptors.response.use(onFullfilled, onRejected);
    RwardNotif.interceptors.response.use(onFullfilled, onRejected);
    LikhaCRUD.interceptors.response.use(onFullfilled, onRejected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertsContext.Provider value={{ msgs, addMsg, removeMsg }}>
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsContext;