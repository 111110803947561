import React, { useEffect, useMemo, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

const MAX_WIDTH_ALLOWANCE = 400;

/**
 * 
 * @param {import('react-qrcode-logo').IProps} param0
 * 
 * @returns {React.Component} 
 * 
 */
const RwardQRCode = ({ size, eyeRadius, ...rest}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const preferredSize = useMemo(() => {
    let preferredSize = 250;
    if(size) {
      preferredSize = size
    };

    if(windowWidth <= MAX_WIDTH_ALLOWANCE) {
      preferredSize = windowWidth - 140;
    }
    return preferredSize;
  }, [size, windowWidth]);

  useEffect(() => {
    const updateWindowWidth = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    }
  }, []);

  return (
    <QRCode 
      size={preferredSize}
      eyeRadius={eyeRadius || [10, 10, 10]} 
      // logoImage={'https://rwardstoragedev.blob.core.windows.net/user/Vendor/Branding/5307c8c6-ece0-4c8c-b7e4-0170738bbb01/rwards-banner.png'}
      // logoHeight={50}
      // logoWidth={50}
      removeQrCodeBehindLogo
      {...rest}
    />
  );
};

export default RwardQRCode;