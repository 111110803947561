import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { preventAndStop } from '../../helpers/Generic';
import CustomButton from './CustomButton';

/**
 * 
 * @typedef {Object} WarningProps
 * @property {string} title
 * @property {string} message
 * 
 * @typedef {Object} MenuObject
 * @property {string} icon
 * @property {string} text
 * @property {string} desc
 * 
 * @typedef {Object} CardButtonProps
 * @property {React.Component} icon
 * @property {WarningProps} warning
 * @property {Array<MenuObject>} menu
 * @property {function} onClick
 * @property {string} link
 * @property {string} className
 * 
 * @param {CardButtonProps} param0 
 * 
 */
const CardButton = ({ icon: CardButtonIcon, menu, onClick, link, warning, tips, className = "" }) => {
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const closeModal = useCallback((ev) => {
    preventAndStop(ev);
    setIsOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const confirmClick = useCallback(async (ev) => {
    if(link) {
      navigate(link);
    } else if(typeof onClick === 'function') {
      setConfirming(true);
      await onClick();
      setConfirming(false);
    }
    closeModal(ev);
  }, [onClick, link, navigate, closeModal]);

  const handleOnClick = useCallback((ev) => {
    preventAndStop(ev);
    if(warning) {
      return openModal();
    } else if(ev) {
      confirmClick(ev);
    }
  }, [warning, openModal, confirmClick]);

  return (
    <>
    <button className="card-btn relative" data-danger={!!warning} onClick={handleOnClick}>
      <CardButtonIcon className="w-full h-full"/>
    </button>
    <div className={`hidden ${tips ? 'peer-first:hover:block' : ''} bg-slate-400 px-3 py-1 rounded-full absolute left-1/2 -translate-x-1/2 top-full translate-y-2 text-white text-xs`}>
      {tips}
    </div>
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal} onClick={preventAndStop}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {warning?.title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {warning?.message}
                  </p>
                </div>

                <div className="mt-4 flex flex-row justify-end space-x-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="button"
                    btnClassName='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                    onClick={confirmClick}
                    text='Confirm'
                    isLoading={confirming}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  );
};

export default CardButton;