import React, { useMemo } from 'react';
import { FormWithValidation } from '../../components/forms';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import ReactJoyride from 'react-joyride';

const FORM_STEPS = [
  {
    target: '#field-Name',
    title: 'Collection Name',
    content: 'Name of this collection.',
    disableBeacon: true,
  },
  {
    target: '#field-ShortName',
    title: 'Collection Short Name',
    content: 'Name for creating smart contract.',
  },
  {
    target: '#field-Banner',
    title: 'Collection Banner',
    content: 'Thumbnail for this collection',
  },
  {
    target: '#field-Description',
    title: 'Collection Description',
    content: 'Add some details to your collection',
  },
  {
    target: '#field-ArtName',
    title: 'NFT Name',
    content: 'Formatted Name of NFTs that will be minted',
  },
  {
    target: '#field-ArtImage',
    title: 'NFT Image',
    content: 'Upload your NFT',
  },
  {
    target: '#field-Supply',
    title: 'Quantity',
    content: 'Amount of NFTs users can mint in this collection',
  },
  {
    target: '#field-LimitPerWallet',
    title: 'Limit per Wallet',
    content: 'How many times a wallet can mint this NFT',
  },
  {
    target: '#submit-Collection',
    title: 'Submit Collection',
    content: 'This will create a collection and deploy your own Smart Contract',
  },
];

const Create = () => {
  const location = useLocation();
  const walkthroughForm = useMemo(() => !!location.state?.walkthrough, [location.state]);

  return (
    <div className="form-page">
      <FormWithValidation
        formName='Collection'
        formTitle="Create Collection"
        containerClassName="form-page-container"
        initialValues={{
          Name: '',
          ShortName: '',
          Banner: null,
          Description: '',
          ArtName: '',
          ArtImage: null,
          Supply: 0,
          LimitPerWallet: 1,
        }}
        schema={Yup.object().shape({
          Name: Yup.string().required('Collection name is required').min(2, 'Collection name minimum of 2 characters'),
          ShortName: Yup.string().required('Collection short name is required').min(2, 'Collection short name minimum of 2 characters').max(5, 'Collection short name maximum of 5 characters'),
          Banner: Yup.mixed()
          .required('Please upload a collection banner')
          .test(
            'fileType', 
            'Unsupported image file', 
            /** @param {File} fileValue */
            fileValue => ['image/jpg', 'image/png', 'image/jpeg'].includes(fileValue?.type)
          ),
          Description: Yup.string().required('Collection description is required'),
          ArtName: Yup.string().required('Specify art name'),
          ArtImage: Yup.mixed()
          .required('Please upload the collection art')
          .test(
            'fileType', 
            'Unsupported image file', 
            /** @param {File} fileValue */
            fileValue => ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(fileValue?.type)
          ),
          Supply: Yup.number().min(0, 'Please input a zero or positive integer'),
          LimitPerWallet: Yup.number().min(0, 'Please input a zero or positive integer'),
        })}
        fields={[
          {
            name: 'Name',
            label: 'Name',
            type: 'text',
          },
          {
            name: 'ShortName',
            label: 'Short Name',
            type: 'text',
          },
          {
            name: 'Banner',
            label: 'Collection Banner',
            type: 'file',
            accept: 'image/png, image/jpeg, image/jpg',
            uploadLocation: `/setup/Collection/Banner`,
            fileIsImage: true,
          },
          {
            name: 'Description',
            label: 'Description',
            type: 'textarea',
          },
          {
            name: 'ArtName',
            label: 'NFT Name',
            tips: "We'll append an incremental number to this name (Rwards NFT #001)",
            type: 'text',
          },
          {
            name: 'ArtImage',
            label: 'NFT Art',
            type: 'file',
            accept: 'image/png, image/jpeg, image/jpg, image/gif',
            uploadLocation: `/setup/Collection/ArtImage`,
            fileIsImage: true,
          },
          {
            name: 'Supply',
            label: 'Supply',
            type: 'number',
            tips: 'Input a positive integer, otherwise put 0 to specify unlimited minting',
            className: 'w-1/2',
          },
          {
            name: 'LimitPerWallet',
            label: 'Limit per Wallet',
            type: 'number',
            disabled: true,
            tips: 'Input a positive integer, otherwise put 0 to specify unlimited minting per wallet address',
            className: 'w-1/2',
          },
        ]}
        submitLink='createVendorCollection'
        submitSuccessAccessor='collection'
      />
      <ReactJoyride
        steps={FORM_STEPS}
        run={walkthroughForm}
        disableScrolling
        showSkipButton
        continuous
      />
    </div>
  );
};

export default Create;