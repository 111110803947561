import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const NavigationContext = React.createContext(null);

export const NavigationProvider = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const { currentUser } = useSelector(state => state.auth);

  const toggleExpand = useCallback(() => {
    setExpanded(curr => !curr);
  }, []);

  useEffect(() => {
    if(!currentUser) {
      setExpanded(false);
    }
  }, [currentUser]);

  const value = useMemo(() => ({
    expanded,
    toggleExpand,
  }), [
    expanded,
    toggleExpand,
  ]);

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;