import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/loading/Spinner';
import AlertsContext from '../../contexts/AlertsContext';
import RwardCRUD from '../../helpers/RwardCRUD';
import { Tab } from '@headlessui/react';
import classNames from '../../helpers/classNames';
import FieldInput from '../../components/forms/FieldInput';
import Jumbotron from '../../components/jumbotron';
import { POLYGONSCAN_URL, RWARD_CUSTOMER_URL } from '../../setupConfig';
import { LinkIcon } from '@heroicons/react/outline';
import QRCard from '../../components/cards/QRCard';
import { ItemList } from '../../components/list';
import RedeemCard from '../../components/cards/Redeem/RedeemCard';
import { updateBranch } from '../../redux/auth/authSlice';
import useModal from '../../hooks/useModal';
import { ContentModal } from '../../components/modal';
import { FormWithValidation } from '../../components/forms';
import CollectionMintRequestCard from '../../components/cards/Collection/CollectionMintRequestCard';
require('./typedef');


/**
 * 
 * @typedef {Object} BasicDetailsProps
 * @property {CollectionData} information
 * 
 * @param {BasicDetailsProps} param0 
 * 
 */
const BasicDetails = ({ information }) => {
  
  return (
    <div className='w-96 max-w-full md:w-full md:flex md:flex-row md:flex-wrap md:p-6 h-full overflow-y-auto'>
      <div className='w-full h-80 md:h-96 flex justify-center items-center px-4 py-5 '>
        <img src={information?.Banner} alt='Collection Thumbnail' className='h-full object-contain rounded-lg shadow-lg shadow-slate-600'/>
      </div>
      <FieldInput
        name='Name'
        label='Collection Name'
        value={information?.Name}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Description'
        label='Description'
        value={information?.Description}
        type='textarea'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Supply'
        label='Total Supply'
        value={information?.Supply > 0 ? information?.Supply : "Unlimited"}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='LimitPerWallet'
        label='Limit per Wallet'
        value={information?.LimitPerWallet > 0 ? information?.LimitPerWallet : "Unlimited"}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
    </div>
  )
}

/**
 * 
 * @typedef {Object} BasicDetailsProps
 * @property {string} collectionID
 * @property {CollectionData} information
 * 
 * @param {BasicDetailsProps} param0 
 * 
 */
const CollectionQRCode = ({ collectionID, information }) => {
  const { currentUser } = useSelector(state => state.auth);
  const mintLink = useMemo(() => `${RWARD_CUSTOMER_URL}mint/${currentUser.VendorID}/${collectionID}/${currentUser.BranchID}`, [collectionID, currentUser]);

  return (
    <div className='w-full h-full flex flex-col justify-start items-center'>
      <Jumbotron
        title='Mint QR Code'
        header="This QR Code is for your customers to mint nft for this collection."
      />
      <QRCard
        value={mintLink}
        pdfTitle={information.Name}
        pdfDescription='Scan to Mint NFT'
      />
    </div>
  )
}


/**
 * 
 * @typedef {Object} BasicDetailsProps
 * @property {string} collectionID
 * @property {CollectionData} information
 * 
 * @param {BasicDetailsProps} param0 
 * 
 */
const CollectionOnChain = ({ collectionID, information }) => {
  const { addMsg } = useContext(AlertsContext);
  const polyscanLink = useMemo(() => `${POLYGONSCAN_URL}address/${information.ContractAddress}`, [information.ContractAddress]);
  const [isMintingEnabled, setIsMintingEnabled] = useState(false);
  const [fetchingMintingStatus, setFetchingMintingStatus] = useState(true);
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(polyscanLink);
    addMsg({ type: 'success', text: 'Link copied!'})
  }, [polyscanLink, addMsg]);

  const getMintingStatus = useCallback(async () => {
    if(information.ContractAddress) {
      try {
        setFetchingMintingStatus(true);
        const mintingAvailability = await RwardCRUD.get(`getCollectionMintingAvailability/${collectionID}`);
        setIsMintingEnabled(mintingAvailability.data.isEnabled);
      } finally {
        setFetchingMintingStatus(false);
      }
    }
  }, [collectionID, information.ContractAddress]);


  useEffect(() => {
    getMintingStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information.ContractAddress]);

  const { mintingStatus, textClassName } = useMemo(() => {
    return {
      mintingStatus: fetchingMintingStatus ? 'loading...' : isMintingEnabled ? 'Enabled' : 'Disabled',
      textClassName: fetchingMintingStatus ? 'text-white' : isMintingEnabled ? 'text-green-500' : 'text-rward-red',
    };
  }, [fetchingMintingStatus, isMintingEnabled]);

  const toggleMinting = useCallback(async () => {
    try {
      setFetchingMintingStatus(true);
      const mintingAvailability = await RwardCRUD.post(`toggleCollectionMintingAvailability/${collectionID}`, { Enable: !isMintingEnabled });
      setIsMintingEnabled(mintingAvailability.data.isEnabled);
    } finally {
      setFetchingMintingStatus(false);
    }
  }, [collectionID, isMintingEnabled]);

  return (
    <div className='w-full h-full flex flex-col relative'>
      <Jumbotron
        title='Collection On-Chain'
        header="Manage your collection's Smart Contract"
      />
      <div className='flex flex-col px-4'>
      {
        information.Status === 'D' ?
        <div className='item-list-loader flex-col'>
          <Spinner/>
          <span className='text-lg mt-8 text-center'>Your collection is taking some time to deploy, we'll notify you once your collection is deployed</span>
        </div> :
        <div className='flex flex-col'>
          <div className='rounded-md p-4 bg-gray-300 my-4 flex flex-row justify-between'>
            <span>Contract Address: {' '}
              <a 
                href={polyscanLink}
                target='_blank'
                rel='noreferrer'
                className='text-blue-500'
              >
                {information.ContractAddress}
              </a>
            </span>
            
            <LinkIcon className='w-8 h-8 p-2 rounded-full hover:bg-rward-yellow cursor-pointer' onClick={copyToClipboard}/>
          </div>
          <div className='rounded-md p-4 bg-gray-300 my-4 flex flex-row justify-between'>
            <span>Minting is: {' '}
              <span className={classNames('font-bold', textClassName)}>
                {mintingStatus}
              </span>
            </span>
            <input 
              className={classNames('form-field-switch')}
              onChange={toggleMinting}
              checked={isMintingEnabled}
              value=''
              type='checkbox'
              disabled={fetchingMintingStatus}
            />
          </div>
        </div>
      }
      </div>
    </div>
  )
}

const MintRequest = ({data}) => {
  const dispatch = useDispatch();
    const { 
        isOpen: isOpenGeoloc, 
        openModal: openGeolocModal,
        closeModal: closeGeolocModal,
    } = useModal();
    const { branchInfo } = useSelector(state => state.auth);
    const { CollectionID: collectionID } = useParams();
    const [geolocationEnabled, setGeolocationEnabled] = useState(!branchInfo.DisableGeolocation)
    const { autoApproveState, toggleStateTo, geoloc } = useMemo(() => {
        const approval = branchInfo.AutoApprove ? {
            autoApproveState: 'ON',
            toggleStateTo: 'OFF',
        } : {
            autoApproveState: 'OFF',
            toggleStateTo: 'ON',
        };
        const geolocRequirements = branchInfo?.DisableGeolocation;
        const geoloc = {
            disabled: false,
            time: '',
            transaction: 0,
        };

        if(![null, undefined].includes(geolocRequirements)) {
            if(typeof geolocRequirements === 'boolean') {
                if(!geolocRequirements) {
                    Object.assign(geoloc, geolocRequirements);
                }
            } else {
                Object.assign(geoloc, geolocRequirements);
            }
        }
        return {
            ...approval,
            geoloc: {
                ...geoloc,
                state: !geolocationEnabled ? 'OFF' : 'ON',
            },
        }
    }, [branchInfo, geolocationEnabled]);
    const toggleAutoApprove = useCallback(async () => {
        const toggleResponse = await RwardCRUD.get('toggleBranchAutoApproval');
        dispatch(updateBranch({ branch: toggleResponse.data }));
    }, [dispatch]);

    const handleSubmitSuccess = useCallback((response) => {
        setGeolocationEnabled(!response.data.DisableGeolocation)
        closeGeolocModal()
    }, [closeGeolocModal]);

  const collectionMintRequestDS = useMemo(() => {
    return {
      endpoint: `getBranchMintRequests/${collectionID}`,
      accessor: 'items',
    }
  }, [collectionID]);

  return (
    <div className='w-full h-full flex flex-col justify-start items-center'>
      <div className="collection-list-content w-full">
        <ItemList 
          dataSource={collectionMintRequestDS}
          cardComponent={CollectionMintRequestCard}
          listName='redemptions'
          actions={[
            // {
            //   text: 'Enter a Code',
            //   link: '/mint/code',
            // },
          ]}
          reportModule={'MintRequest'}
        />
      </div>
    </div>
  )
}

const CollectionNFTs = ({ branchID }) => {
  const [data, ] = useState([]);


  return (
    <div className='w-full h-full flex'>
      <Jumbotron
        title='Collection NFTs'
        header="Minted NFTs from your collection"
      />
      {
        data?.length > 0 ?
        data.map((datum) => {
          return null
        }) :
        <Jumbotron
          title='Branch Redemptions'
          header='No redemptions for this branch yet'
        />
      }
    </div>
  )
}
/**
 * 
 * @typedef {Object} CollectionDetailProps 
 * @property {CollectionData} data
 * @property {function} onDataUpdated
 * 
 * @param {CollectionDetailProps} param0
 * 
 */
const CollectionDetails = ({ data, onDataUpdated }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { basicInformation } = useMemo(() => {
    
    const basicInformation = {
      Name: data.Name,
      Description: data.Description,
      Banner: data.Banner,
      ArtImage: data.ArtImage,
      ArtName: data.ArtName,
      Supply: data.Supply,
      ContractAddress: data.ContractAddress,
      LimitPerWallet: data.LimitPerWallet,
      Status: data.Status,
    };

    return {
      basicInformation,
    }
  }, [data]);

  return (
    <div className="info-container tabbed-info">
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="tab-list tabbed-info-tabs">
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          Details
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected before:animate-ping',
              )
            }
          >
          QR Code
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected before:animate-ping',
              )
            }
          >
          Mint Request
          </Tab>
          {/* <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          On Chain
          </Tab> */}
          {/* <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          NFTs
          </Tab> */}
        </Tab.List>
        <Tab.Panels className='tabbed-info-panels'>
          <Tab.Panel className='tabbed-info-panel'>
            <BasicDetails 
              information={basicInformation}
            />
          </Tab.Panel>
          <Tab.Panel className='tabbed-info-panel'>
            <CollectionQRCode 
              collectionID={data.id}
              information={basicInformation}
            />
          </Tab.Panel>
          <Tab.Panel className='tabbed-info-panel'>
            <MintRequest 
              data={""}
            />
          </Tab.Panel>
          {/* <Tab.Panel className='tabbed-info-panel'>
            <CollectionOnChain 
              collectionID={data.id}
              information={basicInformation}
            />
          </Tab.Panel> */}
          {/* <Tab.Panel className='tabbed-info-panel'>
            <BranchRedemptions 
              branchID={data.id}
            />
          </Tab.Panel> */}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const sample_information_data = 
  {
    Name: "Sample",
    Description: "Sample Description",
    Banner: "",
    ArtImage: "",
    ArtName: "Sample Art Name",
    Supply: 10,
    ContractAddress: "12",
    LimitPerWallet: 10,
    Status: "A",
  }


const Information = () => {
  const { addMsg } = useContext(AlertsContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const params = useParams();
  const { CollectionID } = params;

  useEffect(() => {
    console.log(params);
    const getBranchInformation = async () => {
      try {
        const collectionInformation = await RwardCRUD.get(`/getBranchCollection/${CollectionID}`);
        setData(collectionInformation.data);
      } catch (err) {
        addMsg({ type: 'error', text: err?.message });
      } finally {
        setLoading(false);
      }
    }
    if (CollectionID) {
      setLoading(true);
      // setTimeout(() => {
      //   setData(TEMPORARY_DATA);
      //   setLoading(false);
      // }, 3000);
      getBranchInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CollectionID]);

  return (
    <div className='info-page'>
      {
        loading ?
          <div className="item-list-loader">
            <Spinner />
          </div> :
          // data ?
          sample_information_data ?
          <CollectionDetails 
            data={data}
            onDataUpdated={setData}
          />
          :
          <div className="item-list-zero">
            Collection not found
          </div>
      }
    </div>
  )
};

export default Information;