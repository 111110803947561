import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import Header from './Header';
import AlertsContext from '../contexts/AlertsContext';
import Alert from '../components/alerts/Alert';
import SideNav from './SideNav';
import { useDispatch, useSelector } from 'react-redux';
import classNames from '../helpers/classNames';
import { useLocation } from 'react-router-dom';
import ReactJoyride, { EVENTS } from 'react-joyride';
import { stop } from '../redux/walkthrough/walkthroughSlice';

const AppLayout = ({ children }) => {
  const { msgs } = useContext(AlertsContext);
  const location = useLocation();
  const { currentUser } = useSelector(state => state.auth);
  const isOnLanding = useMemo(() => location.pathname === '/', [location]);
  
  const dispatch = useDispatch();
  const { main: runMain } = useSelector(state => state.walkthrough);
  const mainJoyride = useRef(null);
  const mainSteps = useMemo(() => {
    return [
      {
        target: '#sidenav-dashboard',
        title: 'Dashboard',
        content: 'This is your dashboard!',
        disableBeacon: true,
      },
      {
        target: '#sidenav-branches',
        title: 'Branches',
        content: 'Here you will see your branches',
      },
      {
        target: '#sidenav-campaigns',
        title: 'Campaigns',
        content: 'This is where you create your campaigns',
      },
      {
        target: '#topnav-account',
        title: 'Account',
        content: 'To access your account information go here',
      },
      {
        target: '#topnav-notification',
        title: 'Notification',
        content: 'Be updated with everything you need to know',
      },
    ];
  }, [])

  const handleMainWalkthrough = useCallback((callbackProps) => {
    const { type } = callbackProps;
    console.log(callbackProps);
    if(type === EVENTS.TOUR_END) {
      dispatch(stop({ name: 'main' }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col justify-start align-center overflow-y-auto !overflow-x-hidden custom-scrollbar">
      <Header/>
      <main className={`relative flex-1 main-content ${currentUser ? 'md:pl-14' : ''}`} data-landing={isOnLanding}>
        {children}
        <div className={classNames(
          "fixed top-20 right-[10px] md:right-8 md:w-[250px] md:max-w-1/2",
          currentUser ? "w-[calc(100%-5rem)]" : "w-[calc(100%-20px)]",
          !!msgs.length ? 'z-[999]' : 'z-10'
        )}>
          {
            msgs.map((msg) => {
              return (
                <Alert key={msg.id} {...msg}/>
              );
            })
          }
        </div>
        <SideNav/>
      </main>
      <footer>
        <section className={classNames(
          "w-full px-8 p-4 flex flex-row justify-center items-center",
          isOnLanding ? '' : 'bg-slate-200',
        )}>
          <div className="text-center flex flex-col justify-center items-center ">
            <div className='w-64'>
              <div
                className='bg-banner bg-cover bg-no-repeat max-w-[250px] w-full h-auto aspect-[350/76]'
              />
            </div>
          </div>
        </section>
      </footer>
      <ReactJoyride
        callback={handleMainWalkthrough}
        ref={mainJoyride}
        steps={mainSteps}
        run={runMain}
        showSkipButton
        continuous
      />
    </div>
  )
};

export default AppLayout;