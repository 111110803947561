import { DotsVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import CardButton from '../../buttons/CardButton';

/**
 * @typedef {Object} BranchData
 * @property {string} VendorID
 * @property {string} Name
 * @property {string} Code
 * @property {string} Logo
 * @property {string} Description
 * @property {'A' | 'R' | 'D'} Status
 * 
 * @typedef {Object} BranchCardProps
 * @property {BranchData} data
 * 
 * @param {BranchCardProps} param0 
 * 
 */
const Branch = ({ data }) => {

  return (
    <Link to={`/branch/${data.id}`} className="card branch-card group">
      <img 
        className="branch-card-img"
        src={data.Restaurant?.Logo} 
        alt="Branch Thumbnail"
      />
      <div className="card-details">
        <div className="card-data">
          <h1>{data.Name}</h1>
          <LinesEllipsis
            text={data.Description}
            className="card-data-desc text-base"
            maxLine="1"
            ellipsis='...'
            basedOn='letters'
            trimRight
          />
        </div>
        <div className="card-actions">
          <CardButton icon={TrashIcon} danger/>
          <CardButton icon={PencilIcon}/>
          <CardButton icon={DotsVerticalIcon}/>
        </div>
      </div>
      {/* <ItemCardCheckbox/> */}
    </Link>
  );
};

export default Branch;