import { DotsVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import CardButton from '../../buttons/CardButton';
require('../../../pages/collection/typedef');

/**
 * 
 * @typedef {Object} CollectionCardProps
 * @property {CollectionData} data
 * 
 * @param {CollectionCardProps} param0 
 * 
 */
const Collection = ({ data }) => {

  return (
    <Link to={`/collection/${data.id}`} className="card branch-card group pb-12 flex flex-col justify-start items-center">
      {
        data?.Banner ?
        <img 
          className="branch-card-img h-44 w-44 bg-gray-100 rounded-md object-cover object-top"
          src={data?.Banner} 
          alt="Branch Thumbnail"
        />
        :
        <div className='branch-card-img h-44 w-44 bg-gray-100 rounded-md'/>
      }
      <div className="card-details">
        <div className="card-data">
          <h1>{data.Name}</h1>
          <LinesEllipsis
            text={data.Description}
            className="card-data-desc text-base"
            maxLine="1"
            ellipsis='...'
            basedOn='letters'
            trimRight
          />
        </div>
        {/* <div className="card-actions">
          <CardButton icon={TrashIcon} danger/>
          <CardButton icon={PencilIcon}/>
          <CardButton icon={DotsVerticalIcon}/>
        </div> */}
      </div>
      {/* <ItemCardCheckbox/> */}
    </Link>
  );
};

export default Collection;