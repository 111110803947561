import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { FormWithValidation } from '../../components/forms';
import Jumbotron from '../../components/jumbotron';
import * as Yup from 'yup';
import RwardCRUD from '../../helpers/RwardCRUD';
import { successLogin } from '../../redux/auth/authSlice';
import { useCookies } from 'react-cookie';
import COOKIE_NAMES from '../../data/cookies';

const CreatePassword = () => {
  const [, setCookie] = useCookies([COOKIE_NAMES.TOKEN]);
  const dispatch = useDispatch();
  const [waivingPassword, setWaivingPassword] = useState(false);

  return (
    <div className='info-page'>
      <div className='info-container items-center'>
        <Jumbotron
          title='Password Update'
          header="You're using a temporary password, kindly update your password or wave this password update."
        />
        <FormWithValidation
          initialValues={{
            TempPassword: '',
          }}
          containerClassName='shadow-none my-0'
          schema={Yup.object().shape({
            TempPassword: Yup.string().required('Enter your temporary password'),
            NewPassword: Yup.string().required('Enter new password'),
          })}
          fields={[
            {
              name: 'TempPassword',
              type: 'password',
              label: 'Temporary Password',
              className: 'max-w-md'
            },
            {
              name: 'NewPassword',
              type: 'password',
              label: 'New Password',
              className: 'max-w-md'
            },
          ]}
          preButtons={[
            {
              text: 'Waive',
              onClick: async () => {
                setWaivingPassword(true);
                try {
                  const waiveResponse = await RwardCRUD.get('waiveCreatePassword');
                  dispatch(successLogin({ user: waiveResponse.data.user }));
                } finally {
                  setWaivingPassword(false);
                }
              },
              isLoading: waivingPassword,
            }
          ]}
          submitLink={'createNewPassword'}
          onSubmitSuccess={(response) => {
            console.log(response);
            const respData = response.data;
            dispatch(successLogin({ user: respData.user }));
            setCookie(COOKIE_NAMES.TOKEN, respData.token, { secure: true, path: '/' });
          }}
        />
      </div>
    </div>
  );
};

export default CreatePassword;