import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

/**
 * 
 * @param {ActionObj} param0
 * 
 *  
 */
const JumbotronAction = ({ to, text, onClick }) => {
  const isLink = useMemo(() => to, [to]);

  return (
    isLink ? 
    <Link
      type="button"
      className="inline-block px-6 py-2.5 mt-4 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      to={to}
    >
      {text}
    </Link> :
    <button
      type="button"
      className="inline-block px-6 py-2.5 mt-4 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

/**
 * @typedef {Object} ActionObj
 * @property {string} to
 * @property {string} text
 * @property {function} onClick
 * 
 * 
 * @typedef {Object} JumbotronProps
 * @property {string} title
 * @property {string} header
 * @property {string} tips
 * @property {ActionObj} action
 * 
 * @param {JumbotronProps} param0 
 *  
 */
const Jumbotron = ({ title, header, tips, action }) => {

  return (<div className="p-6 text-gray-700 w-full">
    <h2 className="font-semibold text-3xl mb-5">{title}</h2>
    <p>
      {header}
    </p>
    <hr className="my-6 border-gray-300" />
    {
      tips ? 
      <p>
        {tips}
      </p> : null 
    }
    {
      action ? <JumbotronAction {...action}/> : null
    }
  </div>);
}

export default Jumbotron;