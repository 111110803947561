export const getTSNow = () => {
  return Math.floor(Date.now() / 1000);
}

export const preventAndStop = (ev) => {
  if(typeof ev?.preventDefault === 'function') {
    ev.preventDefault();
  }
  if(typeof ev?.stopPropagation === 'function') {
    ev.stopPropagation();
  }
};


export const fileReaderJSON = (file) => {
  return new Promise((onResolve, onReject) => {
    let result = null;
    const onReaderLoad = (ev) => {
      if(ev?.target?.result) {
        result = ev.target.result;
        onResolve(JSON.parse(result));
      } else {
        onReject('Invalid JSON file');
      }
    }

    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(file);
  });
}