import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field } from 'formik';
import FieldInputFile from './FieldInputFile';
import classNames from '../../helpers/classNames';
import FieldInputTextArea from './FieldInputTextArea';
import FieldInputSelect from './FieldInputSelect';
import FieldInputDateRange from './FieldInputDateRange';
import FieldInputOTP from './FieldInputOTP';
import { InformationCircleIcon } from '@heroicons/react/outline';
import FieldInputSelectPill from './FieldInputSelectPill';
import FieldInputSwitch from './FieldInputSwitch';
import FieldInputGeolocation from './FieldInputGeolocation';
import { ReactComponent as HidePasswordSVG } from '../../assets/svg/password-hide.svg';
import { ReactComponent as ShowPasswordSVG } from '../../assets/svg/password-show.svg';
require('./typedef');

export const FIELD_TYPES = {
  TEXT: 'text',
  FILE: 'file',
  CHECKBOX: 'checkbox',
  TEXT_AREA: 'textarea',
  SELECT: 'select',
  SELECT_PILL: 'select-pill',
  DATERANGE: 'daterange',
  SWITCH: 'switch',
  GEOLOC: 'geoloc',
  OTP: 'otp',
}

/** 
 */
export class FieldInputProps {
  /**
   * 
   * 
   * @param {FieldInputObject} props 
   */
  constructor(props) {
    this.label = props.label;
    this.name = props.name;
    this.type = props.type;
    this.intOnly = props.intOnly;
    this.error = props.error;
    this.hasValidation = props.hasValidation;
    this.touched = props.touched;
    this.tips = props.tips;
    this.onChangeFieldValue = props.onChangeFieldValue;
    this.options = props.options;
    this.fileIsImage = props.fileIsImage;
    this.uploadLocation = props.uploadLocation;
    this.canAdd = props.canAdd;
    this.onCreatedNew = props.onCreatedNew;
    this.onShow = props.onShow;
    this.onHide = props.onHide;
    this.inputClassName = props.inputClassName;
    this.length = props.length;
  }
};



/** @param {FieldInputProps} param0 */
const CustomFieldPassword = ({ onChange, name, ...rest }) => {
  const [inputType, setInputType] = useState('password');

  const toggleShow = useCallback(() => setInputType(curr => curr === 'password' ? 'text' : 'password'), []);
  const handleChangeValue = useCallback((ev) => {
    onChange(name, ev.target.value);
  }, [onChange, name]);
  
  return (
    <div className='w-full relative'>
      <input
        name={name}
        {...rest}
        type={inputType} 
        onChange={handleChangeValue}
        defaultValue={undefined}
      />
      <div 
        className='absolute right-2 top-1/2 -translate-y-1/2 h-7 w-7 p-1 hover:bg-gray-300 fill-gray-700 hover:fill-black rounded-full cursor-pointer ' 
        onClick={toggleShow}
      >
      {
        inputType === 'password' ? <HidePasswordSVG/> : <ShowPasswordSVG/>
      }
      </div>
    </div>
  );
}

/** @param {FieldInputProps} param0 */
const CustomField = ({ name, onChange, type, className, intOnly, ...rest }) => {
  const handleChangeValue = useCallback((ev) => {
    if(type === 'number') {
      if(intOnly) {
        onChange(name, parseInt(ev.target.value));
      } else {
        onChange(name, parseFloat(ev.target.value));
      }
    } else {
      onChange(name, ev.target.value);
    }
  }, [type, intOnly, onChange, name]);

  const customFieldClassName = useMemo(() => {
    let fieldClassName = '';
    if(type === 'number') {
      fieldClassName = 'text-right';
    }

    return fieldClassName;
  }, [type]);

  return (
    <input 
      name={name}
      type={type}
      onChange={handleChangeValue}
      {...rest}
      className={classNames(className, customFieldClassName)}
    />
  );
};

const JSON_OBJECT_CONSTRUCTOR = ({}).constructor;

/**
 * 
 * @param {import('react').InputHTMLAttributes | FieldInputProps} param0 
 *  
 */
function FieldInput({ 
  type, 
  touched, 
  error, 
  hasValidation = false,
  name, 
  tips,
  label, 
  value = null, 
  options = [], 
  className,
  inputClassName,
  onChangeFieldValue, 
  onShow,
  onHide,
  ...rest 
}) {
  const [showingTips, setShowingTips] = useState(false);
  const { 
    FieldComponent,
    fieldClassName,
    additionalProps,
  } = useMemo(
  /** 
   * @typedef {Object} DynamicFields
   * @property {import('react').Component} FieldComponent
   * @property {string} fieldClassName
   * 
   * @returns {DynamicFields}
   */
  () => {
    let additionalProps = {};
    if(type === FIELD_TYPES.FILE) {
      return {
        FieldComponent: FieldInputFile,
        fieldClassName: '',
        additionalProps,
      };
    } else if(type === FIELD_TYPES.CHECKBOX) {
      return {
        FieldComponent: Field,
        fieldClassName: 'form-field-checkbox',
        additionalProps,
      };
    } else if(type === FIELD_TYPES.TEXT_AREA) {
      additionalProps.type = type;
      return {
        FieldComponent: FieldInputTextArea,
        fieldClassName: 'form-field-textarea',
        additionalProps,
      }
    } else if(type === FIELD_TYPES.DATERANGE) {
      additionalProps.type = type;
      return {
        FieldComponent: FieldInputDateRange,
        fieldClassName: '',
        additionalProps,
      }
    } else if(type === FIELD_TYPES.SELECT) {
      additionalProps.type = type;
      return {
        FieldComponent: FieldInputSelect,
        // fieldClassName: `form-field-${multiple ? 'multi' : ''}select`,
        fieldClassName: '',
        additionalProps,
      }
    } else if(type === FIELD_TYPES.SELECT_PILL) {
      additionalProps.type = type;
      return {
        FieldComponent: FieldInputSelectPill,
        fieldClassName: '',
        additionalProps,
      }
    } else if(type === FIELD_TYPES.SWITCH) {
      additionalProps.type = type;
      return {
        FieldComponent: FieldInputSwitch,
        fieldClassName: '',
        additionalProps,
      }
    } else if(type === FIELD_TYPES.GEOLOC) {
      return {
        FieldComponent: FieldInputGeolocation,
        fieldClassName: 'form-field-input',
        additionalProps,
      }
    } else if(type === FIELD_TYPES.OTP) {
      return {
        FieldComponent: FieldInputOTP,
        fieldClassName: '',
        additionalProps,
      }
    } else if(type === 'password') {
      return {
        FieldComponent: CustomFieldPassword,
        fieldClassName: 'form-field-input',
        additionalProps,
      }
    } else {
      return {
        FieldComponent: CustomField,
        fieldClassName: 'form-field-input',
        additionalProps,
      };
    }
  }, [type]);

  const fieldError = useMemo(() => {
    return error?.constructor === JSON_OBJECT_CONSTRUCTOR ? error[Object.keys(error)[0]] : error;
  }, [error]);

  const showTips = useCallback(() => setShowingTips(true), []);
  const hideTips = useCallback(() => setShowingTips(false), []);

  useEffect(() => {
    if(typeof onShow === 'function') {
      onShow();
    }
    
    return () => {
      if(typeof onHide === 'function') {
        onHide();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(
    'form-field relative',
      className
    )}>
      <div className='flex flex-row justify-between items-center w-full'>
        <label 
          className="text-sm my-1"
          htmlFor={name}
        >
          {label}
        </label>
        {
          tips ?
          <InformationCircleIcon className='w-4 h-4 stroke-blue-400' onMouseEnter={showTips} onMouseLeave={hideTips}/> : null
        }
      </div>
      <FieldComponent
        id={rest.id || (`field-${name}`)}
        name={name}
        value={value}
        options={options}
        {...rest}
        {...additionalProps}
        onChange={onChangeFieldValue}
        type={type}
        className={classNames(
          'rounded-lg',
          fieldClassName, 
          fieldError ? 'border-red-400' : '',
          inputClassName,
        )}
      />
      {
        tips ?
        <div className={`${showingTips ? 'z-[999]' : 'hidden'} bg-slate-400 px-4 py-1 rounded-full absolute left-1/2 -translate-x-1/2 top-full -translate-y-1/2 text-white text-xs w-full`}>
          {tips}
        </div> : null
      }
      <div data-has-validation={hasValidation} className="form-field-error">{touched ? (fieldError || ' ') : ' '}</div>
    </div>
  );
};

export default FieldInput;