import { Formik, Form } from 'formik';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from '../../helpers/classNames';
import RwardCRUD from '../../helpers/RwardCRUD';
import CustomButton from '../buttons/CustomButton';
import FieldInput from './FieldInput';

/**
 * 
 * @callback PreSubmitValidationCallback
 * @param {Object} values
 * @returns {Promise<boolean>}
 * @throws {string}
 * 
 * 
 * @typedef {Object} CustomActions
 * @property {string} text
 * @property {string} btnClassName
 * @property {string} txtClassName
 * @property {string} arrowClassName
 * @property {function} onClick
 * @property {boolean} isLoading
 * 
 */

/**
 * 
 * @typedef {Object} FormWithValidationProps
 * @property {string} formName
 * @property {string | React.Component} formTitle
 * @property {import("yup").ObjectSchema} schema
 * @property {function} onSubmit
 * @property {function} onCancel
 * @property {function} onSubmitSuccess
 * @property {string} submitSuccessAccessor 
 * @property {PreSubmitValidationCallback} onPreSubmitValidation
 * @property {function} onPreSubmitTransform
 * @property {string} submitLink 
 * @property {Object} initialValues
 * @property {Object<string, function | boolean>} hideFieldWhen 
 * @property {Array<import('react').InputHTMLAttributes | import("./FieldInput").FieldInputProps>} fields
 * @property {Array<CustomActions>} preButtons
 * @property {Array<CustomActions>} postButtons
 * @property {string} submitText
 * @property {string} containerClassName
 * @property {string} cancelClassName
 * @property {string} submitClassName
 * @property {boolean} hideSubmitError
 * 
 * @param {FormWithValidationProps} param0
 * 
 */
const FormWithValidation = ({ 
  formName,
  formTitle, 
  schema = undefined, 
  onCancel, 
  onSubmit, 
  submitLink, 
  submitSuccessAccessor, 
  onPreSubmitTransform, 
  onPreSubmitValidation,
  onSubmitSuccess, 
  preButtons,
  postButtons,
  initialValues, 
  fields = [], 
  submitText = "Submit", 
  containerClassName = '',
  actionsClassName = '', 
  cancelClassName = '',
  submitClassName = '',
  hideSubmitError = true,
  hideFieldWhen
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [createdNew, setCreatedNew] = useState({});

  const handleSubmit = useCallback(async (values, formikHelpers) => {
    if(typeof onPreSubmitValidation === 'function') {
      let validForSubmit = false;
      try {
        validForSubmit = await onPreSubmitValidation(values);
      } catch(err) {
        console.log('Pre Submit Validation Error', err);
        if(!hideSubmitError) {
          toast.error(err.message);
        }
      }
      if(!validForSubmit) return;
    }
    try {
      if(typeof onSubmit === 'function') {
        let transformedValues = values;
        if(typeof onPreSubmitTransform === 'function') {
          transformedValues = onPreSubmitTransform(Object.assign({}, values), formikHelpers, createdNew);
        }
        const submitResponse = await onSubmit(transformedValues, formikHelpers, createdNew);
        if(typeof onSubmitSuccess === 'function') {
          onSubmitSuccess(submitResponse);
        }
      } else if(typeof submitLink === 'string') {
        const allAsyncCalls = fields.reduce((asyncCalls, field) => {
          const hideCallback = hideFieldWhen?.[field.name];
          const isHidden = typeof hideCallback === 'function' ? hideCallback(values, createdNew) : false;
          if(!isHidden && field.type === 'file') {
            var formData = new FormData();
            // const fileType = field.fileIsImage ? 'image' : 'file';
            const additionalLink = typeof field.uploadLocation === 'string' ? field.uploadLocation : '';
            formData.append('file', values[field.name]);
            const uploadLink = (field.fileIsImage ? 'uploadImage' : 'uploadFile') + additionalLink;
            return [
              ...asyncCalls, 
              new Promise(async (resolve, reject) => {
                console.time(field.name);
                console.log(field.name, 'Start: ', Date.now())
                try {
                  const uploaded = await RwardCRUD.post(
                    uploadLink, 
                    formData, { 
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                  )
                  values[field.name] = uploaded.data.fileURL;
                  resolve(uploaded);
                } catch(err) {
                  console.log('Promise Err', err);
                  reject(err);
                } finally {
                  console.log(field.name, 'End: ', Date.now())
                  console.timeEnd(field.name);
                }
              })
            ];
          }
          return asyncCalls;
        }, []);
        console.log(allAsyncCalls);
        const final = await Promise.all(allAsyncCalls);
        console.log(final);
        let transformedValues = values;
        if(typeof onPreSubmitTransform === 'function') {
          transformedValues = onPreSubmitTransform(Object.assign({}, values), formikHelpers, createdNew);
        }
        console.log('transformedValues', transformedValues);
        const rwardResponse = await RwardCRUD.post(submitLink, transformedValues);
        console.log('rwardResponse', rwardResponse);
        if(typeof onSubmitSuccess === 'function') {
          onSubmitSuccess(rwardResponse);
        } else if(typeof submitSuccessAccessor === 'string') {
          const paths = location.pathname.split('/');
          paths[paths.length - 1] = rwardResponse.data[submitSuccessAccessor].id;
          navigate(paths.join('/'));
        }
      } else {
        // THIS IS FOR TESTING PURPOSES, KINDLY PROVIDE ONE OF THE TWO onSubmitFunction or submitLink
        console.log(values);
        console.log(JSON.stringify(values));
      }
    } catch(err) {
      console.log(err);
      if(!hideSubmitError) {
        toast.error(err.message);
      }
    }
  }, [
    onPreSubmitTransform, 
    onPreSubmitValidation, 
    onSubmit, 
    submitLink, 
    onSubmitSuccess, 
    fields, 
    submitSuccessAccessor, 
    hideFieldWhen, 
    hideSubmitError,
    location.pathname, 
    navigate, 
    createdNew
  ]);

  const handleCreatedNew = useCallback((fieldName, isNew) => {
    setCreatedNew(curr => ({ ...curr, [fieldName]: isNew }));
  }, []);

  return (
    <div className={classNames('form-container', containerClassName)}>
      {
        formTitle ? typeof formTitle === 'string' ? <h1 className="form-title">{formTitle}</h1> : formTitle : null
      }
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue, setFieldTouched, handleBlur, isValid, isSubmitting }) => {

          return (
            <Form className="flex flex-row flex-wrap">
            {
              fields.map((field) => {
                let isHidden = false;
                if(hideFieldWhen) {
                  if(typeof hideFieldWhen[field.name] === 'boolean') {
                    isHidden = hideFieldWhen[field.name];
                  } else if(typeof hideFieldWhen[field.name] === 'function') {
                    isHidden = hideFieldWhen[field.name](values, createdNew);
                  }
                  if(isHidden) return null;
                }

                return (
                  <FieldInput 
                    {...field}
                    key={field.name}
                    className={classNames('w-full px-1', field.className)}
                    value={values[field.name]}
                    defaultValue={initialValues[field.name]}
                    onChangeFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    hasValidation={!!schema?.fields?.[field.name]}
                    error={errors[field.name]} 
                    touched={touched[field.name]}
                    disabled={field.disabled || isSubmitting}
                    onCreatedNew={handleCreatedNew}
                  />
                )
              })
            }
            <div className={classNames('flex flex-row w-full justify-end space-x-2', actionsClassName)}>
            {
              preButtons?.map((btn) => {

                return (
                  <CustomButton 
                    type='button'
                    btnClassName={classNames('form-submit !bg-gray-500', btn.btnClassName)}
                    {...btn}
                  />
                )
              })
            }
            {
              onCancel ? 
              <CustomButton 
                type='button'   
                text={'Cancel'}
                btnClassName={classNames(
                  'form-submit !bg-gray-500',
                  cancelClassName
                )}
                onClick={onCancel}
              /> : null
            }
            {
              onSubmit || submitLink ? 
              <CustomButton 
                id={`submit-${formName}`}
                type="submit" 
                disabled={!isValid || isSubmitting} 
                btnClassName={classNames("form-submit", submitClassName)} 
                text={submitText}
                isLoading={isSubmitting}
              /> : null
            }
            {
              postButtons?.map((btn) => {

                return (
                  <CustomButton 
                    type='button'
                    btnClassName={classNames('form-submit !bg-gray-500', btn.btnClassName)}
                    {...btn}
                  />
                )
              })
            }
            </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  )
};

export default FormWithValidation;