import React from 'react';
import { RestaurantCard } from '../../components/cards';
import { ItemList } from '../../components/list';

const DATA_SOURCE = {
  endpoint: 'getVendorRestaurants',
  accessor: 'restaurants',
}

const Restaurants = () => {

  return (
    <div className="collection-list-content">
      <ItemList 
        dataSource={DATA_SOURCE}
        cardComponent={RestaurantCard}
      />
    </div>
  );
};

export default Restaurants;