import React from 'react';
import { Link } from 'react-router-dom';
import classNames from '../../../helpers/classNames';


const VoucherRedeem = ({ data, cardContainerClassName = '', cardClassName = '' }) => {

  return (
    <Link 
      className={classNames('voucher-redeem-card-container', cardContainerClassName)}
      to={`/voucher/redeem/${data.Code}`}
    >
      <div className={classNames('card voucher-redeem-card', cardClassName)}>
        <img src={data.Thumbnail} alt='Campaign Thumbnail' className='w-full object-contain'/>
        <h1 className='text-lg text-center'>{data.Name}</h1>
      </div>
    </Link>
  )
}

export default VoucherRedeem;