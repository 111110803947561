import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RwardCRUD from '../../helpers/RwardCRUD';
import Spinner from '../../components/loading/Spinner';
import errorIcon from '../../assets/img/error.png'
import successIcon from '../../assets/img/success.png'
import { Link } from 'react-router-dom';
import { ErrorScreen } from '../../components/errors';
import { data } from 'autoprefixer';
import { middleTruncate } from '../../helpers/TextFormatter';


const perkInfo = {};
const MintRequestInfo = () => {
    const { CollectionID, RequestID } = useParams();
    const [mintRequest, setMintRequest] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

    const navigate = useNavigate()

    const getRequestInfo = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await RwardCRUD.get(`getBranchMintRequest/${CollectionID}/${RequestID}`)
            setMintRequest(response.data);
        } catch(err) {
            setMintRequest(null);
        } finally {
            setIsFetching(false);
        }
    }, [CollectionID, RequestID])

    const handleAcceptClick = useCallback(async () => {
        try {
            setIsUpdatingStatus(true);
            const response = await RwardCRUD.post(`acceptBranchMintRequest/${CollectionID}/${RequestID}`);
            if(response.valid) {
                setMintRequest(response.data);
                // setStatus(request.data.Status)
            }
        } catch(err) {
            console.log('Accept Request Err', err);
        } finally {
            setIsUpdatingStatus(false);
        }
    }, [CollectionID, RequestID]);

    const handleRejectClick = useCallback(async () => {
        try {
            setIsUpdatingStatus(true);
            const response = await RwardCRUD.post(`rejectBranchMintRequest/${CollectionID}/${RequestID}`);
            if(response.valid){
                setMintRequest(response.data);
            }
        } catch(err) {
            console.log('Reject Request Err', err);
        } finally {
            setIsUpdatingStatus(false);
        }
    }, [CollectionID, RequestID]);

    useEffect(() => {
        getRequestInfo();
    }, [getRequestInfo])

    const {
        headerText,
        userQuestion,
        userActions
    } = useMemo(() => {
        let headerText = '';
        let userQuestion = null;
        let userActions = [];
        switch(mintRequest?.Status) {
            case 'A':
                headerText = <div className='text-2xl text-center w-[calc(100%)]'>A customer has requested to mint an NFT from the collection {<strong>{mintRequest?.Collection?.Name}</strong>}</div>;
                break;
            case 'X':
                headerText = <div className='text-2xl text-center w-[calc(100%)]'>A customer has requested to mint an NFT from the collection {<strong>{mintRequest?.Collection?.Name}</strong>}</div>;
                userQuestion = `You have already rejected this request. ${<br/>}Do you want to accept it instead?`;
                userQuestion = <>
                    <div className='text-2xl text-center w-[calc(100%)]'>You have already rejected this request. </div>
                    <div className='text-2xl text-center w-[calc(100%)]'>Do you want to accept it instead?</div>
                </>
                userActions = [
                    <button
                        className='bg-green-400 hover:bg-green-600 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                        onClick = {handleAcceptClick}
                    >
                        Yes
                    </button>,
                    <button
                        className='bg-rward-yellow hover:bg-yellow-600 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                        onClick={() => navigate(-1)}
                    >
                        Close
                    </button>
                ]
                break;
            default:
                headerText = <div className='text-2xl text-center w-[calc(100%)]'>A customer is requesting to mint an NFT from the collection {<strong>{mintRequest?.Collection?.Name}</strong>}</div>;
                userQuestion = <div className='text-2xl text-center w-[calc(100%)]'>Do you accept?</div>;
                userActions = [
                    <button
                        className='bg-green-400 hover:bg-green-600 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                        onClick = {handleAcceptClick}
                    >
                        Yes
                    </button>,
                    <button
                        className='bg-red-500 hover:bg-red-700 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                        onClick={handleRejectClick}
                    >
                        No
                    </button>
                ]
        }

        return {
            headerText,
            userQuestion,
            userActions
        }
    }, [handleRejectClick, handleAcceptClick, mintRequest, navigate]);

    return(
        <div className='w-full h-full flex flex-col justify-center items-center p-6'>
            { isFetching || isUpdatingStatus ? 
            <Spinner/> : 
            mintRequest ?
            <div className='bg-white shadow-xl rounded-lg w-[calc(100%)] lg:w-[calc(70%)] py-10 px-6 flex flex-col justify-center items-center space-y-4 '>
                <div className='text-4xl text-center w-[calc(100%)]'>{mintRequest?.Voucher?.Name}</div>
                {headerText}
                <div className='rounded-lg bg-rward-blue-dark text-white p-6 w-fit flex flex-col justify-center items-center'>
                    <h1 className='text-3xl text-center mb-4'>Code: <strong className='tracking-[.25rem]'>{mintRequest?.Code}</strong></h1>
                    <h2 className='text-2xl text-center bg-white rounded-md p-4 text-black mb-4'>Wallet Address: {middleTruncate(mintRequest.WalletAddress, 6, 6)}</h2>
                    <p className='text-lg text-center'>Full Name: {mintRequest.FullName}</p>
                </div>
                {
                    userQuestion ?
                    <>
                    {userQuestion}
                    {
                        userActions?.length > 0 ?
                        <div className = "w-full flex flex-row justify-center items-center">
                            {userActions}
                        </div> : null
                    }
                    {/* <>
                        <button
                        className='bg-green-400 hover:bg-green-600 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                        onClick = {handleYesClick}>Yes</button>
                        <button
                        className='bg-red-500 hover:bg-red-700 disabled:bg-gray-300 text-white m-4 font-bold py-3 px-9 rounded-full'
                        onClick = {handleNoClick}>No</button>
                    </> */}
                    </> : null
                }
            </div> :
            <ErrorScreen 
                title="Mint Request Error"
                header="Mint Request not found"
            />
            }
        </div>
    )

}

export default MintRequestInfo