import React from 'react';


const ComingSoon = () => {
  
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <h1 className="text-2xl my-4">Coming Soon</h1>
      <h2 className="text-lg">This page is under development. See you soon!</h2>
    </div>
  )
};

export default ComingSoon;