import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from '../../components/loading/Spinner';
import AlertsContext from '../../contexts/AlertsContext';
import RwardCRUD from '../../helpers/RwardCRUD';
import { Dialog, Tab, Transition } from '@headlessui/react';
import classNames from '../../helpers/classNames';
import FieldInput from '../../components/forms/FieldInput';
import Jumbotron from '../../components/jumbotron';
import { RWARD_CUSTOMER_URL } from '../../setupConfig';
import { preventAndStop } from '../../helpers/Generic';
import FormWithValidation from '../../components/forms/Form';
import * as Yup from 'yup';
import { BranchUserCard } from '../../components/cards';
import QRCard from '../../components/cards/QRCard';

const BasicDetails = ({ information }) => {
  
  return (
    <div className='w-96 max-w-full md:w-full md:flex md:flex-row md:flex-wrap md:p-6 h-full overflow-y-auto'>
      <div className='w-full h-80 md:h-96 flex justify-center items-center px-4 py-5 '>
        <img src={information.Restaurant?.Logo} alt='Campaign Thumbnail' className='h-full object-contain rounded-lg shadow-lg shadow-slate-600'/>
      </div>
      <FieldInput
        name='RestaurantName'
        label='Establishment Name'
        value={information.Restaurant?.Name}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Name'
        label='Branch Name'
        value={information.Name}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Location'
        label='Location'
        value={information.Location?.label || 'No address'}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Description'
        label='Description'
        value={information.Description}
        type='textarea'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
    </div>
  )
}

const BranchQRCode = ({ branchID, restaurantName, branchName }) => {
  const { currentUser } = useSelector(state => state.auth);
  const branchLink = useMemo(() => `${RWARD_CUSTOMER_URL}redeem/${currentUser.id}/${branchID}`, [branchID, currentUser?.id]);
  const qrTitle = useMemo(() => `${restaurantName} (${branchName})`, [restaurantName, branchName]);

  return (
    <div className='w-full h-full flex flex-col justify-start items-center'>
      <Jumbotron
        title='Customer QR Code'
        header="This QR Code is for your customers to check and redeem available promotions."
      />
      <QRCard
        value={branchLink}
        pdfTitle={qrTitle}
        pdfDescription='Scan to claim rewards'
      />
    </div>
  )
}

const BranchUsers = ({ branchID }) => {
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  
  const jumbotronHeader = useMemo(() => loadingUsers ? 'Retrieving branch users' : users.length > 0 ? 'Here are the users for this branch' : 'No users for this branch yet', [loadingUsers, users])
  const getUsers = useCallback(async (currentBranchID) => {
    setLoadingUsers(true);
    try {
      const usersResponse = await RwardCRUD.get(`getMyBranchUsers/${currentBranchID}`);
      setUsers(usersResponse.data);
    } finally {
      setLoadingUsers(false);
    }

  }, []);
  
  useEffect(() => {
    if(branchID) {
      getUsers(branchID);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchID]);

  const openModal = useCallback((value) => {
    if(value) {
      let initValue = Object.assign({}, value);
      delete initValue.Password;
      setInitialValues(initValue);
    } else {
      setInitialValues(null);
    }
    setIsOpen(true);
  }, []);

  const handleAddClick = useCallback(() => {
    openModal();
  }, [openModal]);

  const closeModal = useCallback((ev) => {
    preventAndStop(ev);
    setIsOpen(false);
  }, []);

  const handleSubmitSuccess = useCallback(success => {
    getUsers(branchID);
    setInitialValues(null);
    closeModal();
  }, [getUsers, branchID, closeModal]);

  const handlePreSubmitTransform = useCallback(values => {
    return {
      Email: values.Email,
      Password: values.Password,
    };
  }, []);

  return (
    <div className='w-full h-full flex flex-col relative'>
      <Jumbotron
        title='Branch Users'
        header={jumbotronHeader}
        action={{
          text: 'Add User',
          onClick: handleAddClick,
        }}
      />
      <div className='flex flex-col px-4'>
      {
        loadingUsers ?
        <div className='item-list-loader'>
          <Spinner/>
        </div> :
        users?.length > 0 ?
        users.map((datum) => {
          return <BranchUserCard 
            key={datum.id}
            data={datum} 
            onEditClick={openModal}
            onDeleteSuccess={() => getUsers(branchID)}
          />
        }) :
        null
      }
      </div>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={preventAndStop} onClick={preventAndStop}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={
                  classNames(
                    "w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all",
                  )
                }>

                  <FormWithValidation
                    initialValues={initialValues || {
                      Email: '',
                      Password: '',
                    }}
                    formTitle={`${initialValues ? 'Edit' : 'Add'} Branch User`}
                    containerClassName="shadow-none"
                    schema={Yup.object().shape({
                      Email: Yup.string().required('Email is required').email('Invalid email format'),
                      Password: Yup.string().required('Password is required'),
                    })}
                    fields={[
                      {
                        name: 'Email',
                        label: 'User Email',
                        type: 'email',
                      },
                      {
                        name: 'Password',
                        label: 'User Password',
                        type: 'password',
                      },
                    ]}
                    onPreSubmitTransform={handlePreSubmitTransform}
                    submitText={`${initialValues ? 'Update' : 'Create'} User`}
                    onCancel={closeModal}
                    submitLink={initialValues ? `editBranchUser/${branchID}/${initialValues.id}` : `addBranchUser/${branchID}`}
                    onSubmitSuccess={handleSubmitSuccess}
                    cancelClassName='text-base'
                    submitClassName='text-base'
                  />

                  <hr className="my-6 border-gray-300" />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

const BranchRedemptions = ({ branchID }) => {
  const [data, ] = useState([]);


  return (
    <div className='w-full h-full flex'>
      {
        data?.length > 0 ?
        data.map((datum) => {
          return null
        }) :
        <Jumbotron
          title='Branch Redemptions'
          header='No redemptions for this branch yet'
        />
      }
    </div>
  )
}

const BranchDetails = ({ data, onDataUpdated }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { basicInformation } = useMemo(() => {
    const basicInformation = {
      Name: data.Name,
      Description: data.Description,
      Restaurant: data.Restaurant,
      Location: data.Location,
    };

    return {
      basicInformation,
    }
  }, [data]);

  return (
    <div className="info-container tabbed-info">
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="tab-list tabbed-info-tabs">
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          Details
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected before:animate-ping',
              )
            }
          >
          QR Code
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          Users
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          Redemptions
          </Tab>
        </Tab.List>
        <Tab.Panels className='tabbed-info-panels'>
          <Tab.Panel className='tabbed-info-panel'>
            <BasicDetails 
              information={basicInformation}
            />
          </Tab.Panel>
          <Tab.Panel className='tabbed-info-panel'>
            <BranchQRCode 
              branchID={data.id}
              restaurantName={basicInformation.Restaurant.Name}
              branchName={basicInformation.Name}
            />
          </Tab.Panel>
          <Tab.Panel className='tabbed-info-panel'>
            <BranchUsers 
              branchID={data.id}
            />
          </Tab.Panel>
          <Tab.Panel className='tabbed-info-panel'>
            <BranchRedemptions 
              branchID={data.id}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const Information = () => {
  const { addMsg } = useContext(AlertsContext);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { branchID } = useMemo(() => {
    const paths = location.pathname.split('/');
    return {
      branchID: paths[paths.length - 1]
    };
  }, [location]);

  useEffect(() => {
    const getBranchInformation = async () => {
      try {
        const branchInformation = await RwardCRUD.get(`/getVendorBranch/${branchID}`);
        setData(branchInformation.data);
      } catch (err) {
        addMsg({ type: 'error', text: err?.message });
      } finally {
        setLoading(false);
      }
    }
    if (branchID) {
      setLoading(true);
      // setTimeout(() => {
      //   setData(TEMPORARY_DATA);
      //   setLoading(false);
      // }, 3000);
      getBranchInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchID]);

  return (
    <div className='info-page'>
      {
        loading ?
          <div className="item-list-loader">
            <Spinner />
          </div> :
          data ?
          <BranchDetails 
            data={data}
            onDataUpdated={setData}
          />
          :
          <div className="item-list-zero">
            Branch not found
          </div>
      }
    </div>
  )
};

export default Information;