import { Dialog, Tab, Transition } from '@headlessui/react';
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { parse } from 'date-fns';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Checkbox from 'react-three-state-checkbox';
import * as Yup from 'yup';
import CustomButton from '../../components/buttons/CustomButton';
import { VoucherContractCard, VoucherPerksCard } from '../../components/cards';
import { ErrorScreen } from '../../components/errors';
import FieldInput from '../../components/forms/FieldInput';
import FormWithValidation from '../../components/forms/Form';
import Jumbotron, { NoRestaurantJumbotron } from '../../components/jumbotron';
import Spinner from '../../components/loading/Spinner';
import AlertsContext from '../../contexts/AlertsContext';
import classNames from '../../helpers/classNames';
import { fileReaderJSON, preventAndStop } from '../../helpers/Generic';
import RwardCRUD from '../../helpers/RwardCRUD';
import Web3 from 'web3';
import { BLOCKCHAIN, CHAIN_NETWORK_NAMES, getNetworkSelection, LIKHA_CRUD_API } from '../../setupConfig';
import { contractValidator } from '../../helpers/Web3';
import ReactJoyride from 'react-joyride';
import { joyrideCallback, WALKTHROUGH_NAMES } from '../../plugins/JoyrideWalkthrough';
import RwardQRCode from '../../components/RwardQRCode';
// import { ERC721Validator } from '@nibbstack/erc721-validator';

const ETH_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
const NETWORK_SELECTIONS = getNetworkSelection(BLOCKCHAIN.SUPPORTED_CHAIN_IDS);

const CAMPAIGN_STEPS = [
  {
    target: '.tabname-details',
    title: 'Campaign Details',
    content: 'View campaign basic details',
    disableBeacon: true,
  },
  {
    target: '.tabname-qr',
    title: 'Campaign QR Code',
    content: 'View campaign QR code',
  },
  {
    target: '.tabname-condition',
    title: 'Campaign Condition',
    content: "Setup smart contracts and nft's for your campaign",
  },
  {
    target: '.tabname-scope',
    title: 'Campaign Scope',
    content: 'Specify involved branches for this campaign',
  },
  {
    target: '.tabname-perks',
    title: 'Campaign Perks',
    content: 'Include perks and benefits for your customers',
  },
];

const CONDITION_STEPS = [
  {
    target: '#field-From',
    title: 'NFT Collection',
    content: 'Select where your nft collection ',
    disableBeacon: true,
  },
];

const SCOPE_STEPS = [
  {
    target: '#field-From',
    title: 'NFT Collection',
    content: 'Select where your nft collection ',
    disableBeacon: true,
  },
];

const PERKS_STEPS = [
  {
    target: '#field-Name',
    title: 'Perks Name',
    content: 'The name for this specific perks',
    disableBeacon: true,
  },
  {
    target: '#field-Description',
    title: 'Perks Description',
    content: 'Additional details for this perks',
  },
  {
    target: '#field-Supply',
    title: 'Perks Supply',
    content: 'For perks that has limited amount of redemptions',
  },
  {
    target: '#field-LimitPerNFT',
    title: 'Limit per NFT',
    content: 'Specify how many times each NFT can redeem this perks',
  },
  {
    target: '#field-TimeStart',
    title: 'Daily Time Start',
    content: 'Specify at what time customers can start claiming this perks',
  },
  {
    target: '#field-TimeEnd',
    title: 'Daily Time End',
    content: 'Prevent claiming of this perks at the specified time',
  },
  {
    target: '#submit-NewPerks',
    title: 'Add Perks',
    content: 'Add perks to your campaign',
  }
];

const BasicDetails = ({ information }) => {
  
  return (
    <div className='w-96 max-w-full md:w-full md:flex md:flex-row md:flex-wrap md:p-6 h-full overflow-y-auto'>
      <div className='w-full h-1/2 flex justify-center items-center'>
        <img src={information.Thumbnail} alt='Campaign Thumbnail' className='h-full object-contain rounded-lg shadow-lg shadow-slate-600'/>
      </div>
      <FieldInput
        name='Name'
        label='Name'
        value={information.Name}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Code'
        label='Code'
        value={information.Code}
        type='text'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='DateAvailability'
        label='DateAvailability'
        value={information.DateAvailability}
        type='daterange'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Status'
        label='Status'
        value={information.Status}
        type='select'
        options={[{
          value: 'P',
          label: 'Pending'
        }]}
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
      <FieldInput
        name='Description'
        label='Description'
        value={information.Description}
        type='textarea'
        className='w-full md:w-1/2 md:px-2'
        disabled
      />
    </div>
  )
}

const VoucherQRCode = ({ voucherCode }) => {
  const voucherLink = useMemo(() => `${window.location.origin}/qr?voucher=${voucherCode}`, [voucherCode]);

  return (
    <div className='w-full h-full flex flex-col justify-start items-center'>
      <Jumbotron
        title='Campaign QR Code'
        header="Display this QR Code to your branch and let it do it's magic"
      />
      <RwardQRCode value={voucherLink} />

    </div>
  )
}

const Web3Config = ({ contracts, voucherID, onVoucherUpdated }) => {
  let [isOpen, setIsOpen] = useState(false);
  const [myCollections, setMyCollections] = useState([]);
  const [initialValues, setInitialValues] = useState(null);

  const closeModal = useCallback((ev) => {
    preventAndStop(ev);
    setIsOpen(false);
  }, []);

  const openModal = useCallback((value) => {
    console.log('contract-value', value);
    if(value) {
      let initValue = Object.assign({}, value);
      delete initValue.CustomAddressArtifacts;
      setInitialValues(initValue);
    } else {
      setInitialValues(null);
    }
    setIsOpen(true);
  }, []);

  const handleAddClick = useCallback(() => {
    openModal();
  }, [openModal]);

  const handlePreSubmitValidation = useCallback(async (values) => {
    if(values.From === 'CUSTOM_ADDRESS') {
      let contractArtifact = await fileReaderJSON(values.CustomAddressArtifacts);
      if(!('abi' in contractArtifact)) {
        throw new Error('Please provide the correct contract artifacts');
      }
      try {
        const customContract = contractValidator(values.CustomAddress, contractArtifact?.abi);
        return !!customContract;
      } catch(err) {
        console.log(err);
        throw new Error('Please provide the correct contract address and artifacts');
      }
    }
    return true;
  }, []);

  const handlePreSubmitTransformation = useCallback((values) => {
    if(values.From === 'CUSTOM_ADDRESS') {
      delete values.LikhaCollection;
      delete values.PartnerCollection;
      values.ChainID = parseInt(values.ChainID);
    } else {
      delete values.ChainID;
      delete values.CustomAddress;
      delete values.CustomAddressArtifacts;
      if(values.From === 'LIKHA_COLLECTION') {
        delete values.PartnerCollection;
      } else {
        delete values.LikhaCollection;
      }
    }
    return values;
  }, []);
  
  const handleSubmitSuccess = useCallback(success => {
    onVoucherUpdated(success.data.voucher);
    setInitialValues(null);
    closeModal();
  }, [closeModal, onVoucherUpdated]);

  useEffect(() => {
    const getMyCollections = async () => {
      const response = await RwardCRUD.get('getVendorCollections');
      setMyCollections(response.data.collections?.map((collection) => ({ value: collection.id, label: collection.Name })));
    }

    getMyCollections();
  }, []);

  return (
    <div className='w-full h-full'>
    {contracts?.length > 0 ? 
      <div className='w-full h-full'>
        <div className='w-full flex flex-row justify-end items-center mb-3'>
          <CustomButton 
            text={'Add Contract'}
            btnClassName='px-3 py-1 text-base'
            onClick={handleAddClick}
          />
        </div>
        <div className='flex flex-row flex-wrap w-[calc(100%-10px)] mx-[5px] space-y-2 mb-4 md:mb-2 overflow-y-auto h-[calc(100%-2.25rem)] py-2'>
        {
          contracts.map(item => {
            return (
              <VoucherContractCard 
                key={item.id} 
                contract={item}
                voucherID={voucherID} 
                onClickEdit={openModal}
                onDeleteSuccess={onVoucherUpdated}
              />
            )
          })
        }
        </div>
      </div>  :
      <div className="p-6 text-gray-700">
        <h2 className="font-semibold text-3xl mb-5">NFT Tokens</h2>
        <p>
          By specifying ERC721 Tokens affiliated with this Campaign, redeeming of this campaign will be valid only if a user's wallet holds the specified ERC721 Token.
        </p>
        <hr className="my-6 border-gray-300" />
        {/* <p>
          Setup your scope now
        </p> */}
        <button
          type="button"
          className="inline-block px-6 py-2.5 mt-4 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          onClick={handleAddClick}
        >
          Set up now
        </button>
      </div>
    }
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={preventAndStop} onClick={preventAndStop}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={
                classNames(
                  "w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all",
                )
              }>

                <FormWithValidation
                  initialValues={initialValues || {
                    From: '',
                    MyCollection: '',
                    LikhaCollection: '',
                    PartnerCollection: '',
                    ChainID: '',
                    CustomAddress: '',
                    CustomAddressArtifacts: null,
                    RequiredTokens: 1,
                  }}
                  formTitle={<>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    ERC721 Contract
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Fill up the form to add an ERC721 Contract
                    </p>
                  </div>
                  </>}
                  containerClassName="shadow-none"
                  schema={Yup.object().shape({
                    From: Yup.string().required('NFT collection source is required'),
                    LikhaCollection: Yup.string().when('From',
                    /** 
                     * @typedef {'LIKHA_COLLECTION' | 'LIKHA_PARTNER' | 'CUSTOM_ADDRESS' | 'MY_COLLECTION'} CollectionFromBitwise
                     * 
                     * @param {CollectionFromBitwise} from 
                     * @param {Yup.StringSchema} schema 
                     * 
                     **/
                    (from, schema) => {
                      if(from === 'LIKHA_COLLECTION') {
                        return schema.required('Please select a collection')
                      }
                      return schema;
                    }),
                    PartnerCollection: Yup.string().when('From',
                    /** 
                     * @param {CollectionFromBitwise} from 
                     * @param {Yup.StringSchema} schema 
                     * 
                     **/
                    (from, schema) => {
                      if(from === 'LIKHA_PARTNER') {
                        return schema.required('Please select a partner')
                      }
                      return schema;
                    }),
                    MyCollection: Yup.string().when('From',
                    /** 
                     * @param {CollectionFromBitwise} from 
                     * @param {Yup.StringSchema} schema 
                     * 
                     **/
                    (from, schema) => {
                      if(from === 'MY_COLLECTION') {
                        return schema.required('Please select your collection')
                      }
                      return schema;
                    }),
                    ChainID: Yup.string().when('From', 
                    /** 
                     * @param {CollectionFromBitwise} from 
                     * @param {Yup.StringSchema} schema 
                     * 
                     **/
                    (from, schema) => {
                      if(from === 'CUSTOM_ADDRESS') {
                        return schema.required('Please select a network');
                      }
                      return schema;
                    }),
                    CustomAddress: Yup.string().when(['From', 'ChainID'], 
                    /** 
                     * @param {CollectionFromBitwise} from 
                     * @param {string} chainID 
                     * @param {Yup.StringSchema} schema 
                     * 
                     * */
                    (from, chainID, schema) => {
                      if(from === 'CUSTOM_ADDRESS') {
                        return schema
                        .test({
                          test: async (value) => {
                            if(ETH_ADDRESS_REGEX.test(value)) {
                              try {
                                return Web3.utils.isAddress(value, chainID);
                              } catch(err) {
                                return false;
                              }
                            }
                            return false;
                          },
                          message: `Not a valid ${CHAIN_NETWORK_NAMES[chainID]} contract address`,
                        })
                      }
                      return schema;
                    }),
                    CustomAddressArtifacts: Yup.mixed().when('From', 
                    /** 
                     * @param {'LIKHA_COLLECTION' | 'LIKHA_PARTNER' | 'CUSTOM_ADDRESS'} from 
                     * @param {import("yup/lib/mixed").MixedSchema} schema 
                     * 
                     * */
                    (from, schema) => {
                      if(from === 'CUSTOM_ADDRESS') {
                        return schema
                          .required('Please upload the contract artifacts')
                          .test(
                            'fileType', 
                            'Unsupported JSON format', 
                            /** @param {File} fileValue */
                            fileValue => fileValue?.type === 'application/json'
                          )
                      }
                      return schema;
                    }),
                    RequiredTokens: Yup.number().typeError('Please input a valid quantity').required('Please input a valid quantity').min(1, 'Minimum of 1 Token')
                  })}
                  hideFieldWhen={{
                    LikhaCollection: (formValues) => !(formValues.From === 'LIKHA_COLLECTION'),
                    PartnerCollection: (formValues) => !(formValues.From === 'LIKHA_PARTNER'),
                    MyCollection: (formValues) => !(formValues.From === 'MY_COLLECTION'),
                    ChainID: (formValues) => !(formValues.From === 'CUSTOM_ADDRESS'),
                    CustomAddress: (formValues) => !(formValues.From === 'CUSTOM_ADDRESS'),
                    CustomAddressArtifacts: (formValues) => !ETH_ADDRESS_REGEX.test(formValues.CustomAddress),
                  }}
                  fields={[
                    {
                      name: 'From',
                      label: 'NFT Collection Source',
                      type: 'select',
                      options: [
                        {
                          value: 'LIKHA_COLLECTION',
                          label: 'Likha Collection',
                        },
                        {
                          value: 'LIKHA_PARTNER',
                          label: 'Likha Partners',
                        },
                        {
                          value: 'MY_COLLECTION',
                          label: 'My Collection',
                        },
                        {
                          value: 'CUSTOM_ADDRESS',
                          label: 'Custom Address'
                        },
                      ]
                    },
                    {
                      name: 'MyCollection',
                      label: 'Your Collections',
                      type: 'select',
                      options: myCollections,
                    },
                    {
                      name: 'LikhaCollection',
                      label: 'Likha Collection Name',
                      type: 'select',
                      options: {
                        link: `${LIKHA_CRUD_API}GetLikhaCollections?isPartner=false&search=`,
                        accessor: 'collections',
                        labelKey: 'name'
                      },
                    },
                    {
                      name: 'PartnerCollection',
                      label: 'Partner Collection',
                      type: 'select',
                      options: {
                        link: `${LIKHA_CRUD_API}GetLikhaCollections?isPartner=true&search=`,
                        accessor: 'collections',
                        labelKey: 'name'
                      },
                    },
                    {
                      name: 'ChainID',
                      label: 'Blockchain Network',
                      type: 'select-pill',
                      options: NETWORK_SELECTIONS,
                    },
                    {
                      name: 'CustomAddress',
                      label: 'Custom Address',
                      type: 'text',
                    },
                    {
                      name: 'CustomAddressArtifacts',
                      label: 'Contract Artifacts',
                      tips: 'This is the contracts metadata, ask your smart contract developer for this json file',
                      type: 'file',
                      fileIsImage: false,
                      uploadLocation: `/setup/Voucher/Contract`,
                      accept: 'application/json'
                    },
                    {
                      name: 'RequiredTokens',
                      label: 'Required Tokens',
                      type: 'number',
                    },
                  ]}
                  submitText='Submit'
                  onCancel={closeModal}
                  submitLink={initialValues ? `editCampaignContract/${voucherID}/${initialValues.id}` : `addCampaignContract/${voucherID}`}
                  onPreSubmitTransform={handlePreSubmitTransformation}
                  onPreSubmitValidation={handlePreSubmitValidation}
                  onSubmitSuccess={handleSubmitSuccess}
                  cancelClassName='text-base'
                  submitClassName='text-base'
                />

                <hr className="my-6 border-gray-300" />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
    </div>
  )
}

const RestaurantCheckboxItem = ({ restaurant, onRestaurantCollapse, onChangeSelect, collapsed, selected, disabled, config, onDraftConfig }) => {
  const handleRestaurantCollapse = useCallback(() => {
    onRestaurantCollapse(restaurant.id);
  }, [restaurant, onRestaurantCollapse]);

  const isCollapsed = useMemo(() => collapsed.includes(restaurant.id), [collapsed, restaurant]);
  const restoBranches = useMemo(() => restaurant.Branches, [restaurant]);
  const isFocused = useMemo(() => selected?.restoID === restaurant.id && !selected.branchID, [restaurant, selected]);

  const [restaurantChecked, setRestaurantChecked] = useState(config?.[restaurant.id] === true ? -1 : Array.isArray(config?.[restaurant.id]) ? 0 : 1);
  const [restaurantConfig, setRestaurantConfig] = useState(null);
  const { checked, indeterminate } = useMemo(() => ({ checked: restaurantChecked === 1, indeterminate: restaurantChecked === 0 }), [restaurantChecked]);
  const handleRestaurantCheck = useCallback(() => {
    if(restaurantChecked === 1) {
      setRestaurantChecked(-1);
      setRestaurantConfig(true);
    } else {
      setRestaurantChecked(1);
      setRestaurantConfig(null);
    }
  }, [restaurantChecked]);

  useEffect(() => {
    setRestaurantConfig(config?.[restaurant.id]);
  }, [restaurant, config]);

  useEffect(() => {
    onDraftConfig(restaurant.id, restaurantConfig);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantConfig]);

  const handleRestaurantSelect = useCallback(() => {
    onChangeSelect({ restoID: restaurant.id });
  }, [onChangeSelect, restaurant]);

  const handleToggleBranch = useCallback((branchID) => {
    let branches = restaurantConfig ? typeof restaurantConfig === 'boolean' ? restoBranches.map((branch) => branch.id) : Array.isArray(restaurantConfig) ? [...restaurantConfig] :  [] : [];
    const idx = branches.indexOf(branchID);
    if(idx >= 0) {
      branches.splice(idx, 1);
    } else {
      branches = [...branches, branchID];
    }
    if(branches.length === restoBranches.length) {
      setRestaurantChecked(-1);
    } else if(branches.length === 0) {
      branches = null;
      setRestaurantChecked(1);
    } else {
      setRestaurantChecked(0);
    }
    setRestaurantConfig(Array.isArray(branches) && branches.length === restoBranches.length ? true : branches );
  }, [restaurantConfig, restoBranches]);

  return (
    <li key={restaurant.id}>
      <button 
        data-selected={isFocused} 
        onClick={handleRestaurantSelect}
      >
        <ChevronDownIcon 
          className={classNames('h-4 w-4 transition-transform cursor-pointer', isCollapsed ? '-rotate-90' : 'rotate-0')}
          onClick={handleRestaurantCollapse}
        />
        {/* <input
          type="checkbox"
          value=""
          checked
        /> */}
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleRestaurantCheck}
          disabled={disabled}
        />
        <h2 className='mx-2'>{restaurant.Name}</h2>
      </button>
      
      <ul>
      {
        isCollapsed ? null :
        restoBranches.map((branch) => {
          return (
            <BranchCheckboxItem
              key={branch.id}
              restoID={restaurant.id}
              selected={selected}
              branch={branch}
              onChangeSelect={onChangeSelect}
              onToggleBranch={handleToggleBranch}
              disabled={disabled}
              exemptedBranches={restaurantConfig}
            />
          )
        })
      }
      </ul>
    </li>
  )
}

const BranchCheckboxItem = ({ selected, restoID, branch, onChangeSelect, onToggleBranch, disabled, exemptedBranches }) => {
  const isBranchFocused = useMemo(() => selected?.branchID === branch.id, [branch, selected]);
  const handleBranchSelect = useCallback(() => {
    onChangeSelect({ restoID, branchID: branch.id });
  }, [onChangeSelect, restoID, branch.id]);
  const isChecked = useMemo(() => exemptedBranches ? typeof exemptedBranches === 'boolean' ? false : !exemptedBranches.includes(branch.id) : true, [exemptedBranches, branch]);
  const handleBranchCheck = useCallback(() => {
    onToggleBranch(branch.id);
  }, [branch, onToggleBranch]);

  return (
    <li key={branch.id} onClick={handleBranchSelect} data-selected={isBranchFocused}>
      <Checkbox
        checked={isChecked}
        indeterminate={false}
        onChange={handleBranchCheck}
        disabled={disabled}
      />
      <h2>{branch.Name}</h2>
    </li>
  )
}

const ParticipatingBranches = ({ participants, perks, voucherID, goToPerks, onVoucherUpdated }) => {
  const [myRestaurants, setMyRestaurants] = useState([]);
  const [retrievingRestaurants, setRetrievingRestaurants] = useState(false);
  const [selected, setSelected] = useState(null);
  const [collapsed, setCollapsed] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [disabledPerks, setDisabledPerks] = useState(null);
  const [exemptions, setExemptions] = useState(null);
  const [draftConfig, setDraftConfig] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const cancelEditing = useCallback((initialParticipants) => {
    if(!initialParticipants || initialParticipants?.All) {
      setExemptions(null);
    } else {
      setExemptions(initialParticipants.Exempted);
    }
    setDisabledPerks(initialParticipants?.DisabledPerks);
  }, []);

  useEffect(() => {
    cancelEditing(participants);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  const getRestaurants = useCallback(async() => {
    setRetrievingRestaurants(true);
    const myRestaurantsResponse = await RwardCRUD.get('getVendorRestaurantBranches');
    setMyRestaurants(myRestaurantsResponse.data.restaurants);
    setRetrievingRestaurants(false);
  }, []);

  useEffect(() => {
    getRestaurants();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSelect = useCallback((newSelected) => {
    setSelected(newSelected);
  }, []);

  const handleRestaurantCollapse = useCallback((restoID) => {
    let newCollapsed = [...collapsed];
    const idx = collapsed.indexOf(restoID);
    if(idx >= 0) {
      newCollapsed.splice(idx, 1);
    } else {
      newCollapsed = [...newCollapsed, restoID];
    }
    setCollapsed(newCollapsed);
  }, [collapsed]);

  const toggleEditing = useCallback(() => {
    if(isEditing) {
      cancelEditing(participants);
    }
    setIsEditing(!isEditing);
  }, [cancelEditing, isEditing, participants]);

  const saveEditing = useCallback(async () => {
    setIsSaving(true);
    const finalParticipants = { All: true, Exempted: {} };
    const disabledRestaurants = Object.keys(draftConfig);
    if(disabledRestaurants.length > 0) {
      let hasCustomization = false;
      const finalExemptions = {};
      disabledRestaurants.forEach((restaurantID) => {
        const restaurantConfig = draftConfig[restaurantID];
        if(restaurantConfig) {
          const validCustomization = typeof restaurantConfig === 'boolean' || (Array.isArray(restaurantConfig) && restaurantConfig.length > 0);
          if(validCustomization) {
            if(!hasCustomization) {
              hasCustomization = validCustomization;
            }
            finalExemptions[restaurantID] = restaurantConfig;
          }
        }
        return false;
      });
      if(hasCustomization) {
        finalParticipants.All = false;
        finalParticipants.Exempted = finalExemptions;
      }
    }
    finalParticipants.DisabledPerks = disabledPerks || {};
    try {
      const response = await RwardCRUD.post(`updateVoucherParticipants/${voucherID}`, finalParticipants);
      onVoucherUpdated(response.data.voucher);
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  }, [disabledPerks, draftConfig, onVoucherUpdated, voucherID]);

  const handleDraftConfig = useCallback((restoID, draft) => {
    setDraftConfig({
      ...draftConfig,
      [restoID]: draft,
    })
  }, [draftConfig]);

  const handlePerksToggle = useCallback((perksID, isChecked) => {
    const currentDisabledPerks = Object.assign({}, disabledPerks);
    let currentRestaurant = currentDisabledPerks?.[selected.restoID];
    if(!currentRestaurant) {
      currentDisabledPerks[selected.restoID] = { Disabled: [] };
      currentRestaurant = currentDisabledPerks[selected.restoID];
    }
    if(selected.branchID) {
      let branchDisables = currentRestaurant[selected.branchID];
      if(!branchDisables) {
        currentRestaurant[selected.branchID] = [];
        branchDisables = currentRestaurant[selected.branchID];
      }
      if(isChecked) {
        branchDisables = branchDisables.filter((value) => value !== perksID);
        currentRestaurant[selected.branchID] = branchDisables;
        if(branchDisables.length === 0) {
          delete currentRestaurant[selected.branchID];
        }
      } else {
        branchDisables.push(perksID);
        currentRestaurant[selected.branchID] = branchDisables;
      }
      currentDisabledPerks[selected.restoID] = currentRestaurant;
    } else {
      let restaurantDisabledPerks = [...(currentRestaurant?.Disabled || [])];
      if(isChecked) {
        restaurantDisabledPerks = restaurantDisabledPerks.filter((value) => value !== perksID);
      } else {
        restaurantDisabledPerks.push(perksID);
      }
      currentRestaurant.Disabled = restaurantDisabledPerks;
    }
    setDisabledPerks(currentDisabledPerks);
  }, [selected, disabledPerks]);

  const { restoDisabledPerks, branchDisabledPerks } = useMemo(() => {
    if(selected && disabledPerks) {
      const forThisResto = disabledPerks[selected.restoID];
      if(forThisResto) {
        if(selected.branchID) {
          const forThisBranch = forThisResto[selected.branchID];
          if(Array.isArray(forThisBranch) && forThisBranch.length > 0) {
            return { restoDisabledPerks: forThisResto.Disabled, branchDisabledPerks: forThisBranch };
          } else {
            return { restoDisabledPerks: forThisResto.Disabled, branchDisabledPerks: null };;
          }
        } else {
          if(Array.isArray(forThisResto.Disabled) && forThisResto.Disabled.length > 0) {
            return { restoDisabledPerks: forThisResto.Disabled, branchDisabledPerks: null };
          } else {
            return { restoDisabledPerks: null, branchDisabledPerks: null };
          }
        } 
      }
    }
    return { restoDisabledPerks: null, branchDisabledPerks: null };
  }, [selected, disabledPerks]);

  const selectedDisabledPerks = useMemo(() => selected?.branchID ? branchDisabledPerks : restoDisabledPerks, [selected, branchDisabledPerks, restoDisabledPerks]);

  return (<div className="w-full h-full">
    {
      retrievingRestaurants ?
      <div className="w-full py-12 flex justify-center items-center">
        <Spinner/>
      </div> :
      !!myRestaurants.length ?
      <div className='w-full flex flex-col md:flex-row h-full p-6'>
        <div className='w-full md:w-1/3 h-1/2 md:h-full overflow-y-auto'>
          <div className='flex flex-row justify-between items-center'>
            <h1 className='text-lg'>Establishments and Branches</h1>
            <div className='flex flex-row justify-end items-center space-x-2'>
              <CustomButton
                text={isEditing ? 'Cancel' : 'Edit' }
                btnClassName='px-3 py-1 text-sm'
                onClick={toggleEditing}
              />
              {
                isEditing ?
                <CustomButton
                  text='Save'
                  btnClassName='px-3 py-1 text-sm bg-blue-400 hover:bg-blue-500'
                  onClick={saveEditing}
                /> : null
              }
            </div>
          </div>
          <hr className="my-6 border-gray-300" />
          <ul className='nested-checkbox'>
          {
            myRestaurants.map((restaurant) => {
              return (
                <RestaurantCheckboxItem
                  key={restaurant.id}
                  restaurant={restaurant}
                  onRestaurantCollapse={handleRestaurantCollapse}
                  onChangeSelect={handleChangeSelect}
                  collapsed={collapsed}
                  selected={selected}
                  disabled={!isEditing}
                  config={exemptions}
                  onDraftConfig={handleDraftConfig}
                />
              )
            })
          }
          </ul>
        </div>
        <div className='w-full md:w-2/3 flex p-3 md:p-6 h-1/2 md:h-full'>
          {
            !!perks?.length ? 
            selected ? 
            <div className='flex flex-row flex-wrap space-y-2 mb-4 md:mb-2 overflow-y-auto overflow-x-hidden py-2 w-full'>
            {
              perks?.map(item => {
                const restoDisabled = restoDisabledPerks?.includes(item.id);
                const isPerksDisabled = selectedDisabledPerks?.includes(item.id) || restoDisabled;

                return (
                  <VoucherPerksCard 
                    key={item.id} 
                    perks={item}
                    voucherID={voucherID}
                    cardContainerClassName=''
                    cardClassName='drop-shadow-lg'
                    onClickToggle={handlePerksToggle}
                    isEnabled={!isPerksDisabled}
                    restaurantDisbaled={restoDisabled}
                    disableToggle={!isEditing || (selected?.branchID && restoDisabled)}
                  />
                )
              })
            }
            </div> :
            <Jumbotron 
              title='No establishment or branch selected'
              header='Click a establishment or a branch from the left side'
            /> :
            <Jumbotron
              title='Perks'
              header='This campaign does not have an included perks yet.'
              action={{
                onClick: goToPerks,
                text: 'Go to Perks',
              }}
            />
          }
        </div>
      </div> :
      <NoRestaurantJumbotron/>
    }
    <Transition appear show={isSaving} as={React.Fragment}>
    <Dialog as="div" className="relative z-10" onClose={preventAndStop} onClick={preventAndStop}>
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className={
              classNames(
                "w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex flex-col justify-center items-center",
              )
            }>
              <Spinner svgClassName='my-8'/>
              <h1 className='text-xl'>Saving Participants Setup</h1>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
    </Transition>
  </div>);
};

const Perks = ({ perks, onVoucherUpdated, voucherID }) => {
  let [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const { currentUser } = useSelector(state => state.auth);
  const [walkthrough, setWalkthrough] = useState(false)

  const closeModal = useCallback((ev) => {
    preventAndStop(ev);
    setIsOpen(false);
  }, []);

  const openModal = useCallback((value) => {
    console.log('open modal value', value);
    setInitialValues(value);
    setIsOpen(true);
  }, []);

  const transformPreSubmit = useCallback((value) => {
    console.log('VALUES', value);
    if(value.TimeStart) {
      value.TimeStart = value.TimeStart + ':00';
    } else {
      delete value.TimeStart;
    }
    if(value.TimeEnd) {
      value.TimeEnd = value.TimeEnd + ':00';
    } else {
      delete value.TimeEnd;
    }
    delete value.id;
    delete value.UpdatedTS;
    return value;
  }, []);

  const handleSubmitSuccess = useCallback(success => {
    onVoucherUpdated(success.data.voucher);
    setInitialValues(null);
    closeModal();
  }, [closeModal, onVoucherUpdated]);

  const handleAddClick = useCallback(() => {
    openModal();
  }, [openModal]);

  useEffect(() => {
    if(!isOpen) return;
    const finishedWalkthrough = currentUser?.FinishedWalkthrough || [];
    
    setWalkthrough(!finishedWalkthrough.includes(WALKTHROUGH_NAMES.CAMPAIGN.PERKS));
  }, [isOpen, currentUser]);

  return (<>
  <div className="w-full h-full">
    {
    !!perks?.length ?
    <div className='w-full h-full'>
      <div className='w-full flex flex-row justify-end items-center mb-3'>
        <CustomButton 
          text={'Add Perks'}
          btnClassName='px-3 py-1 text-base'
          onClick={handleAddClick}
        />
      </div>
      <div className='flex flex-row flex-wrap w-[calc(100%-10px)] mx-[5px] space-y-2 mb-4 md:mb-2 overflow-y-auto h-[calc(100%-2.25rem)] py-2'>
      {
        perks.map(item => {
          return (
            <VoucherPerksCard 
              key={item.id} 
              perks={item}
              voucherID={voucherID} 
              onClickEdit={openModal}
              onDeleteSuccess={onVoucherUpdated}
            />
          )
        })
      }
      </div>
    </div> :
    <Jumbotron
      title='Perks'
      header='This campaign does not have an included perks yet.'
      action={{
        onClick: handleAddClick,
        text: 'Create One',
      }}
    />}
  </div>
  <Transition appear show={isOpen} as={React.Fragment}>
    <Dialog as="div" className="relative z-10" onClose={preventAndStop} onClick={preventAndStop}>
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className={
              classNames(
                "w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all",
              )
            }>

              <FormWithValidation
                initialValues={initialValues || {
                  Name: '',
                  Description: '',
                  Supply: 0,
                  LimitPerNFT: 0,
                }}
                formName='NewPerks'
                formTitle={<>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  New Perks
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Fill up the form to add a new Perks
                  </p>
                </div>
                </>}
                containerClassName="shadow-none"
                schema={Yup.object().shape({
                  Name: Yup.string().required('Perks name is required').min(2, 'Perks name minimum of 2 characters'),
                  Description: Yup.string().required('Perks description is required').min(5, 'Perks description minimum of 5 characters'),
                  Supply: Yup.number().min(0, "Supply can't be a negative number"),
                  LimitPerNFT: Yup.number().min(0, "Limit per NFT can't be a negative number")
                    .when('Supply', (supply) => {
                      return supply > 0 ? Yup.number().max(supply, 'Must be lower than the actual supply') : Yup.number();
                    }),
                  TimeEnd: Yup.string()
                    .when('TimeStart', (timeStart, schema) => {
                      return timeStart ? schema.test({
                        test: timeEnd => timeEnd ? parse(timeEnd, 'HH:mm', new Date()) > parse(timeStart, 'HH:mm', new Date()) : true,
                        message: "Time End should be later than Time Start"
                      }) : Yup.string();
                    })
                })}
                fields={[
                  {
                    name: 'Name',
                    label: 'Name',
                    type: 'text',
                  },
                  {
                    name: 'Description',
                    label: 'Description',
                    type: 'textarea',
                  },
                  {
                    name: 'Supply',
                    label: 'Supply',
                    type: 'number',
                    tips: `Leave this as 0 if there's unlimited supply for this perks`,
                    className: 'xs:w-1/2',
                  },
                  {
                    name: 'LimitPerNFT',
                    label: 'Limit per NFT',
                    type: 'number',
                    tips: `Leave this as 0 if there's no limit per NFT for claiming this perks`,
                    className: 'xs:w-1/2',
                  },
                  {
                    name: 'TimeStart',
                    label: 'Time Start',
                    type: 'time',
                    className: 'xs:w-1/2',
                  },
                  {
                    name: 'TimeEnd',
                    label: 'Time End',
                    type: 'time',
                    className: 'xs:w-1/2',
                  },
                ]}
                submitText='Save Perks'
                onCancel={closeModal}
                onPreSubmitTransform={transformPreSubmit}
                submitLink={initialValues ? `editCampaignPerks/${voucherID}/${initialValues.id}` : `addCampaignPerks/${voucherID}`}
                onSubmitSuccess={handleSubmitSuccess}
                cancelClassName='text-base'
                submitClassName='text-base'
              />

              <hr className="my-6 border-gray-300" />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
  <ReactJoyride
    steps={PERKS_STEPS}
    callback={(data) => joyrideCallback(WALKTHROUGH_NAMES.CAMPAIGN.PERKS, data)}
    run={walkthrough}
    disableScrolling
    showSkipButton
    continuous
  />
  </>);
};

const TabAttention = () => {
  return (
    <span className="absolute h-3 w-3 top-0 right-0 translate-y-1/2 -translate-x-full">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
      <span className="absolute rounded-full h-3 w-3 bg-red-400"></span>
    </span>
  );
};

const VoucherDetails = ({ data, onDataUpdated }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { basicInformation, contracts, participants, perks } = useMemo(() => {
    const basicInformation = {
      Name: data.Name,
      Code: data.Code,
      Description: data.Description,
      Thumbnail: data.Thumbnail,
      DateAvailability: data.DateAvailability,
      Status: data.Status,
    };

    const contracts = data.Contracts;

    const participants = data.Participants;

    return {
      basicInformation,
      contracts,
      participants,
      perks: data.Perks
    }
  }, [data]);

  const goToPerks = useCallback(() => setSelectedTab(4), []);

  return (
    <div className="info-container tabbed-info">
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="tab-list tabbed-info-tabs">
          <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                'tabname-details',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          Details
          </Tab>
          {/* <Tab
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                'tabname-qr',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected before:animate-ping',
              )
            }
          >
          QR Code
          </Tab> */}
          <Tab
            id={'tab-condition'}
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                'tabname-condition',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected before:animate-ping',
              )
            }
          >
          Condition
          {
            contracts?.length > 0 ? null :
            <TabAttention/>
          }
          </Tab>
          <Tab
            id={'tab-scope'}
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                'tabname-scope',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected'
              )
            }
          >
          Scope
          </Tab>
          <Tab
            id={'tab-perks'}
            className={({ selected }) =>
              classNames(
                'tab-list-item',
                'tabname-perks',
                selected
                  ? 'tab-list-item-selected'
                  : 'tab-list-item-unselected before:animate-ping'
              )
            }
          >
          Perks
          {
            perks?.length > 0 ? null :
            <TabAttention/>
          }
          </Tab>
        </Tab.List>
        <Tab.Panels className='tabbed-info-panels'>
          <Tab.Panel className='tabbed-info-panel'>
            <BasicDetails 
              information={basicInformation}
            />
          </Tab.Panel>
          {/* <Tab.Panel className='tabbed-info-panel'>
            <VoucherQRCode 
              voucherCode={basicInformation.Code}
            />
          </Tab.Panel> */}
          <Tab.Panel className='tabbed-info-panel'>
            <Web3Config 
              contracts={contracts}
              voucherID={data.id}
              onVoucherUpdated={onDataUpdated}
            />
          </Tab.Panel>
          <Tab.Panel className='tabbed-info-panel'>
            <ParticipatingBranches 
              voucherID={data.id}
              participants={participants}
              perks={perks}
              goToPerks={goToPerks}
              onVoucherUpdated={onDataUpdated}
            />
          </Tab.Panel>
          <Tab.Panel className='tabbed-info-panel'>
            <Perks 
              perks={perks}
              onVoucherUpdated={onDataUpdated}
              voucherID={data.id}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const Information = () => {
  const { addMsg } = useContext(AlertsContext);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { currentUser } = useSelector(state => state.auth);
  const walkthrough = useMemo(() => {
    if(loading) return false;
    /** @type {Array<string>} */
    const finishedWalkthrough = currentUser?.FinishedWalkthrough || [];
    return !finishedWalkthrough.includes(WALKTHROUGH_NAMES.CAMPAIGN.SETUP);
  }, [currentUser, loading]);

  const { voucherID } = useMemo(() => {
    const paths = location.pathname.split('/');
    return {
      voucherID: paths[paths.length - 1]
    };
  }, [location]);

  useEffect(() => {
    const getVoucherInformation = async () => {
      try {
        const voucherInformation = await RwardCRUD.get(`/getVendorVoucher/${voucherID}`);
        setData(voucherInformation.data);
      } catch(err) {
        addMsg({ type: 'error', text: err?.message });
      } finally {
        setLoading(false);
      }
    }
    if(voucherID) {
      setLoading(true);
      getVoucherInformation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherID]);

  return (
    <div className="info-page">
    {
      loading ?
      <div className="item-list-loader">
        <Spinner/>
      </div> :
      data ?
      <VoucherDetails 
        data={data}
        onDataUpdated={setData}
      /> :
      <ErrorScreen
        title={'Campaign 404'}
        header={'Campaign not found'}
        description={`The campaign you're looking for does not exist`}
      >
        <>
          <Link to="/voucher" className="group items-center text-base inline-flex font-medium text-indigo-600 hover:text-indigo-500">
            Go to campaign list <span aria-hidden="true"><ArrowRightIcon className="ml-2 h-5 w-5 text-indigo-600 group-hover:text-indigo-500"/></span>
          </Link>
        </>
      </ErrorScreen>
    }
    <ReactJoyride 
      steps={CAMPAIGN_STEPS}
      callback={(data) => joyrideCallback(WALKTHROUGH_NAMES.CAMPAIGN.SETUP, data)}
      run={walkthrough}
      disableScrolling
      showSkipButton
      continuous
    />
    </div>
  )
};

export default Information;