import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from '../../helpers/classNames';

const SAMPLE_OPTIONS = {
  restaurants: [
    {
      Name: 'Establishment 1',
      id: '1',
    },
    {
      Name: 'Establishment 2',
      id: '2',
    },
    {
      Name: 'Establishment 3',
      id: '3',
    },
    {
      Name: 'Establishment 4',
      id: '5',
    },
  ],
  branches: [
    {
      Name: 'Branch 1',
      id: '1',
    },
    {
      Name: 'Branch 2',
      id: '2',
    },
    {
      Name: 'Branch 3',
      id: '3',
    },
    {
      Name: 'Branch 4',
      id: '5',
    },
  ]
}

/** @param {import('react').InputHTMLAttributes} param0 */
const FieldInputSelectPill = ({ 
  name, 
  onChange, 
  value,
  options = [], 
  multiple, 
  defaultValue, 
  disabled, 
  ...rest 
}) => {
  const handleChangeValue = useCallback((ev) => {
    const pillValue = ev.target.value;
    if(multiple) {
      if(value.includes(pillValue)) {
        onChange(name, value.filter((val) => val !== pillValue));
      } else {
        onChange(name, [...value, pillValue]);
      }
    } else {
      onChange(name, pillValue);
    }
  }, [onChange, value, name, multiple]);
  const [fetchingOptions, setFetchingOptions] = useState(!Array.isArray(options));
  const [optionsData, setOptionsData] = useState([]);

  useEffect(() => {
    if(Array.isArray(options)) return;
    const fetchData = async () => {
      setFetchingOptions(true);
      try {
        /** TODO: Remove temporary data */
        // const optionsResponse = await RwardCRUD.get(options.link);
        const optionsResponse = await new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              data: SAMPLE_OPTIONS,
            })
          }, 1500);
        });
        const responseData = optionsResponse.data;
        setOptionsData(responseData[options.accessor].map((item) => ({
          label: item[options.labelKey || options.labelRenderer(item)],
          value: item[options.valueKey]
        })));
      } catch(err) {
        console.log('error', err);
      }
      setFetchingOptions(false);
    }
    fetchData();
  }, [options]);

  const fieldOptions = useMemo(() => {
    return Array.isArray(options) ? options : optionsData;
  }, [options, optionsData]);

  return (
    <div
      className='flex flex-row flex-wrap'
    >
      {
        fieldOptions.map((option) => {
          // eslint-disable-next-line eqeqeq
          const isSelected = multiple ? value.includes(option.value) : value == option.value;

          return (
            <button 
              key={option.value} 
              value={option.value}
              type='button'
              className={classNames(
                'rounded-full text-base px-3 py-1 cursor-pointer bg-white shadow-lg',
                isSelected ? 'bg-blue-400 text-white' : ''
              )}
              onClick={handleChangeValue}
              disabled={fetchingOptions || disabled}
            >
              {option.label}
            </button>
          )
        })
      }
    </div>
  );
};

export default FieldInputSelectPill;