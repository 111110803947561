import React from 'react';
import classNames from '../../helpers/classNames';


/**
 * 
 * @param {React.ButtonHTMLAttributes} param0 
 * 
 */
const ActionButton = ({ text, type = 'button', className = "", ...rest }) => {

  return (
    <button
      className={classNames(
        `inline-block px-6 py-2.5 mt-4 bg-blue-600 text-white
        font-medium text-xs leading-tight uppercase rounded shadow-md
        hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700
        focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg transition duration-150
        ease-in-out disabled:bg-gray-300 self-end`,
        className
      )}
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      {...rest}
      >
      {text}
    </button>
  );
};

export default ActionButton;