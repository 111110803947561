import { format } from "date-fns";
import { DISCOUNT_DESCRIPTION } from "../data/texts";

export const convertWeiTo = ({ price = 0, priceConversion = 1}) => {
  const maticValue = (price ?? 0) / Math.pow(10, 18);
  return (maticValue * priceConversion).toFixed(4);
}

export const middleTruncate = (str, leftLength = 4, rightLength = 4) => {
  if (str.length > (leftLength + rightLength)) {
    return str.substr(0, leftLength) + '...' + str.substr(str.length-rightLength);
  }
  return str;
}

export const getPromoDescription = ({ repeat, daily, discount }) => {
  let description = '';
  if(repeat === 'ONCE') {
    if(daily) {
      description = 'Everyday'
    } else {
      description = 'One time'
    }
  } else if(repeat === 'BIRTHDAY') {
    description = 'Once a Year'
  } else if(typeof repeat === 'boolean' && repeat) {
    if(typeof daily === 'object') {
      if(daily.time) {
        let timeBound = [];
        if(daily.time.start) {
          timeBound.push(daily.time.start);
        }
        if(daily.time.end) {
          timeBound.push(daily.time.end);
        }
        description = `Daily starting ${timeBound.join('up to')}`;
      }
    }
  }

  return {
    description,
    discount: typeof discount === 'number' ? discount === 1 ? 'FREE' : `${discount*100}% Off` : DISCOUNT_DESCRIPTION[discount],
  }
}

export const getReadableDate = (date, noDate = 'Invalid Date') => {
  let dateStr = noDate;
  if(date) {
    if(Object.prototype.toString.call(date) === '[object Date]') {
      dateStr = format(new Date(date), 'PP');
    } else if(date.startDate && date.endDate) {
      dateStr = `${format(Date.parse(date?.startDate), 'PP')} - ${format(Date.parse(date?.endDate), 'PP')}`;
    } else if(date.startDate) {
      dateStr = `Starting from: ${format(Date.parse(date?.startDate), 'PP')}`
    } else if(date.endDate) {
      dateStr = `Until: ${format(Date.parse(date?.endDate), 'PP')}`
    } else {
      dateStr = noDate;
    }
  }
  return dateStr;
}

export const getReadableTime = (date, noTime = 'Invalid Time') => {
  let dateStr = noTime;
  if(date) {
    if(Object.prototype.toString.call(date) === '[object Date]') {
      dateStr = format(new Date(date), 'HH:mm a');
    } else if(date.startDate && date.endDate) {
      dateStr = `${format(Date.parse(date?.startDate), 'HH:mm a')} - ${format(Date.parse(date?.endDate), 'HH:mm a')}`;
    } else if(date.startDate) {
      dateStr = `Starting from: ${format(Date.parse(date?.startDate), 'HH:mm a')}`
    } else if(date.endDate) {
      dateStr = `Until: ${format(Date.parse(date?.endDate), 'HH:mm a')}`
    } else {
      dateStr = noTime;
    }
  }
  return dateStr;
}