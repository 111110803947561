import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RwardCRUD from '../../helpers/RwardCRUD';
import Select from 'react-select';
import SelectCreatable from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncSelectCreatable from 'react-select/async-creatable';
require('./typedef');

/** @param {import('react').InputHTMLAttributes | FieldInputObject} param0 */
const FieldInputSelect = ({ 
  name, 
  onChange, 
  value,
  options = [], 
  canAdd = false,
  onCreatedNew,
  multiple, 
  defaultValue, 
  disabled, 
  setFieldTouched,
  className,
  ...rest 
}) => {
  const [query, setQuery] = useState('');
  const isAsync = useMemo(() => !Array.isArray(options), [options]);

  const valueKey = useMemo(() => isAsync ? (options.valueKey || 'id') : 'value', [isAsync, options]); 
  const valueAccessor = useCallback(item => item?.[valueKey], [valueKey]);
  const labelAccessor = useCallback(item => item.__isNew__ ? item.label : isAsync ? typeof options.labelRenderer === 'function' ? item?.[options.labelRenderer(item)] : item?.[options.labelKey] : item?.label, [isAsync, options]);

  const [selectedValue, setSelectedValue] = useState(isAsync ? null : options.find(option => option.value === value));
  const handleChangeValue = useCallback((ev) => {
    console.log(ev);
    setSelectedValue(ev);
  }, []);

  const handleAddNew = useCallback((newValue) => {
    console.log(newValue);
    setSelectedValue({ label: newValue, [valueKey]: newValue, __isNew__: true  });
  }, [valueKey]);

  useEffect(() => {
    if(typeof onChange === 'function') onChange(name, multiple ? selectedValue?.map(valueAccessor) : valueAccessor(selectedValue));
  }, [selectedValue, name, multiple, onChange, valueAccessor]);

  useEffect(() => {
    if(typeof onCreatedNew === 'function') {
      onCreatedNew(name, selectedValue?.__isNew__);
    }
  }, [name, onCreatedNew, selectedValue]);

  const loadOptions = useCallback(async (searchQuery) => {
    if(!(searchQuery?.length >= 2)) return [];
    try {
      const response = await RwardCRUD.get(`${options.link}${searchQuery}`);
      const responseData = response.data;
      return responseData[options.accessor];
      // setOptionsData(responseData[options.accessor].map((item) => ({
      //   label: item[options.labelKey || options.labelRenderer(item)],
      //   value: item[options.valueKey]
      // })));
    } catch(err) {
      console.log('error', err);
    }
  }, [options.accessor, options.link]);

  const noOptionsMessage = useCallback(({ inputValue }) => inputValue.length >= 2 ? 'No items to show' : inputValue.length === 1 ? 'Please input atleast 2 characters' : 'No items to show', []);

  const AsyncSelectComp = useMemo(() => {
    return canAdd ? AsyncSelectCreatable : AsyncSelect;
  }, [canAdd]);

  const SelectComp = useMemo(() => {
    return canAdd ? SelectCreatable : Select;
  }, [canAdd]);

  const customStyles = useMemo(() => {
    const isRounded = className?.includes('rounded-full');
    const hasError = className?.includes('border-red-400');
    return {
      control: (provided) => {
        return { 
          ...provided, 
          borderRadius: isRounded ? '1.5rem !important' : provided.borderRadius,
          borderColor:  hasError ? 'rgb(248 113 113) !important' : provided.borderColor,
        }
      }
    }
  }, [className]);

  const handleMenuOpen = useCallback(() => {
    if(typeof setFieldTouched === 'function') {
      setFieldTouched(name, true);
    }
  }, [name, setFieldTouched]);

  // const handleMenuClose = useCallback((ev) => {
  //   console.log('menuClose', ev);
  //   setFieldTouched(name, false);
  // }, [name, setFieldTouched]);

  return (
    isAsync ?
    <AsyncSelectComp
      cacheOptions
      name={name}
      value={selectedValue}
      isMulti={multiple}
      getOptionLabel={labelAccessor}
      getOptionValue={valueAccessor}
      loadOptions={loadOptions}
      isDisabled={disabled}
      noOptionsMessage={noOptionsMessage}
      inputValue={query}
      onInputChange={setQuery}
      onChange={handleChangeValue}
      onCreateOption={handleAddNew}
      // formatCreateLabel={(newString) => `Create "${newString}"`}
      {...rest}
      onMenuOpen={handleMenuOpen}
      // onMenuClose={handleMenuClose}
      classNamePrefix="form-field-react-select"
      className={'form-field-react-select__container'}
      styles={customStyles}
    /> :
    <SelectComp
      name={name}
      onChange={setSelectedValue}
      isMulti={multiple}
      options={options}
      isDisabled={disabled}
      value={selectedValue}
      onCreateOption={handleAddNew}
      {...rest}
      onMenuOpen={handleMenuOpen}
      // onMenuClose={handleMenuClose}
      classNamePrefix="form-field-react-select"
      className={'form-field-react-select__container'}
      styles={customStyles}
    />
    // <Field
    //   as="select"
    //   name={name}
    //   onChange={handleChangeValue}
    //   isMulti={multiple}
    //   data-loading={fetchingOptions}
    //   disabled={fetchingOptions || disabled}
    //   {...rest}
    // >
    // {
    //   fieldOptions.map((optionItem) => {
    //     return (
    //       <option 
    //         key={optionItem.value} 
    //         value={optionItem.value}
    //         label={optionItem.label}
    //       >
    //       </option>
    //     )
    //   })
    // }
    // </Field>
    // <select
    //   name={name}
    //   onChange={handleChangeValue}
    //   multiple={multiple}
    //   data-loading={fetchingOptions}
    //   disabled={fetchingOptions || disabled}
    //   {...rest}
    // >
    // {
    //   fieldOptions.map((optionItem) => {
    //     return (
    //       <option 
    //         key={optionItem.value} 
    //         value={optionItem.value}
    //         label={optionItem.label}
    //       >
    //       </option>
    //     )
    //   })
    // }
    // </select>
  );
};

export default FieldInputSelect;