import React from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { preventAndStop } from '../../helpers/Generic';
import classNames from '../../helpers/classNames';
import Spinner from '../loading/Spinner';

/**
 * @callback closeModal
 * 
 * @typedef {Object} ContentModalProps
 * @property {boolean} isOpen
 * @property {closeModal} onClose
 * @property {boolean} loading
 * @property {React.Component} children
 * 
 * @param {ContentModalProps} param0 
 * 
 */
const ContentModal = ({ isOpen, children, onClose, loading = false }) => {

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose ? onClose : preventAndStop} onClick={preventAndStop}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto top-16">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={
                classNames(
                  "w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative",
                )
              }>
              {children}
              <Transition
                appear
                show={loading}
                as={React.Fragment}
              >
                <div className='absolute left-0 top-0 h-full w-full bg-gray-200/50 flex justify-center items-center'>
                  <Spinner/>
                </div>
              </Transition>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ContentModal;