import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';

const RedeemCode = () => {
    const [code, setCode] = useState();
    const navigate = useNavigate()
    const goTo = useCallback((link) => navigate(link), [navigate]);

    const handleChange = (e) => {
        setCode(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        goTo(`/redeem/code/${code}`)
    }

    return(
        <div class='w-full h-full flex flex-col justify-center items-center p-6'>
            <div className='text-md text-center w-[calc(100%)] h-[calc(5%)]'>Please enter the code that the customer will show you</div>           
            <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-96' onSubmit={handleSubmit}>
            <input type="text" class = 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-rward-yellow' value={code} onChange={handleChange} placeholder = "Enter Code here" />
            <button id="submitButton" className="w-full mt-6 bg-rward-yellow hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Submit
            </button>
            </form>
        </div> 
    )

}

export default RedeemCode;