import React from 'react';

const SocialMediaLanding = ({ svg: SVGComponent, link, className }) => {
  return (
    <a href={link} target='_blank' rel='noreferrer' className={className}>
      <SVGComponent className='w-6 h-6 fill-rward-red hover:fill-rward-yellow'/>
    </a>
  );
};

export default SocialMediaLanding;