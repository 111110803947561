import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useLocationID = () => {
  const location = useLocation();
  
  const locationID = useMemo(() => {
    const paths = location.pathname.split('/');
    return paths[paths.length - 1];
  }, [location]);

  return locationID;
};

export default useLocationID;