import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { VoucherRedeemCard } from '../../components/cards';
import { ErrorScreen } from '../../components/errors';
import Spinner from '../../components/loading/Spinner';
import RwardCRUD from '../../helpers/RwardCRUD';
import useLocationID from '../../hooks/useLocationID';

const BranchVouchers = () => {
  const [queryParams] = useSearchParams();
  const branchID = useLocationID();
  const vendorID = useMemo(() => queryParams.get('vendor'), [queryParams]);

  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);

  const getBranchInformation = useCallback(async (branchID, vendorID) => {
    setFetching(true);
    const branchData = await RwardCRUD.get(`getBranchVouchers/${vendorID}/${branchID}`);
    setData(branchData.data);
    setFetching(false);
  }, []);

  useEffect(() => {
    if(branchID && vendorID) {
      getBranchInformation(branchID, vendorID);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchID, vendorID]);

  return (
    <div className="w-full h-full flex justify-center items-center">
    {
      fetching ?
      <div className='w-full h-full flex justify-center items-center'>
        <Spinner/> 
      </div> :
      data ?
      <div className='w-[calc(100%-4rem)] max-w-[750px] flex flex-col justify-start items-center m-8 p-6 rounded-md shadow-lg bg-white'>
        <h1 className='text-2xl'>{`${data.branch?.Restaurant?.Name} - (${data.branch?.Name})`}</h1>
        <div className='my-8 w-full flex flex-col justify-start items-center'>
        {
          data.vouchers?.length > 0 ?
          <>
            <h2 className='text-xl mb-4'>Select a voucher to redeem</h2>
            <div className='w-full flex flex-row flex-wrap justify-center items-start'>
            {
              data.vouchers.map((voucher) => {
                return (
                  <VoucherRedeemCard
                    key={voucher.id}
                    data={voucher}
                  />
                )
              })
            }
            </div>
          </> :
          <ErrorScreen 
            title={'Oooops, No Vouchers'}
            header={'This branch does not offer any vouchers'}
            // description={`Try again later`}
          />
        }
        </div>
      </div> :
      <ErrorScreen 
        title={'QR Failed'}
        header={'QR information not retrieved'}
        description={`The QR you're trying to use does not exists`}
        />
    }
    </div>
  );
};

export default BranchVouchers;