import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../components/loading/Spinner';
import AlertsContext from '../../contexts/AlertsContext';
import RwardCRUD from '../../helpers/RwardCRUD';
import CardButton from '../../components/buttons/CardButton';
import { TrashIcon } from '@heroicons/react/outline';

const Information = () => {
  const { addMsg } = useContext(AlertsContext);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { restoID } = useMemo(() => {
    const paths = location.pathname.split('/');
    console.log(paths[paths.length - 1]);
    return {
      restoID: paths[paths.length - 1]
    };
  }, [location]);

  useEffect(() => {
    const getRestaurantInformation = async () => {
      try {
        const restoInformation = await RwardCRUD.get(`/getVendorRestaurant/${restoID}`);
        setData(restoInformation.data);
        console.log(restoInformation);
      } catch (err) {
        console.error(err);
        addMsg({ type: 'error', text: err?.message });
      } finally {
        setLoading(false);
      }
    }
    if (restoID) {
      setLoading(true);
      // setTimeout(() => {
      //   setData(TEMPORARY_DATA);
      //   setLoading(false);
      // }, 3000);
      getRestaurantInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoID]);

  return (
    <div className='form-page'>
      {
        loading ?
          <div className="item-list-loader">
            <Spinner />
          </div> :
          data ?
            <div className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl pt-4 pl-4 pr-4">
              <img className="w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={data.Logo} alt="" />
              <div className="flex flex-col justify-between p-4 leading-normal">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-black">{data.Name}</h5>
                <p className="mb-3 font-normal text-black">{data.Description}</p>
                <p className="mb-3 font-normal text-black pb-4">Establishment Code: {data.Code}</p>
                <CardButton
                  icon={TrashIcon}
                  tips={'Delete Establishment'}
                  warning={{ title: 'Delete Establishment', message: `Are you sure you want to delete ${data.Name}?` }}
                  onClick={() => alert('Coming soon')}
                />
              </div>
            </div>
            // JSON.stringify(data)
            :
            <div className="item-list-zero">
              Establishment not found
            </div>
      }
    </div>
  )
};

export default Information;