import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import classNames from '../../helpers/classNames';

const ROW_COUNT = [
  { id: 12, name: '12 Items' },
  { id: 24, name: '24 Items' },
  { id: 36, name: '36 Items' },
  { id: 48, name: '48 Items' },
  { id: 60, name: '60 Items' },
];

export const GET_ROW_COUNT = (pageSize) => {
  return ROW_COUNT.find((value) => value.id === pageSize) || ROW_COUNT[0];
}

/**
 * @typedef {Object} RowObject
 * @property {number} id
 * @property {string} name
 * 
 * @typedef {Object} ItemListPageSizeButtonProps
 * @property {RowObject} selectedRows
 * @property {function} onSelectedRowsChange
 * 
 * 
 * @param {ItemListPageSizeButtonProps} param0 
 *  
 */
const ItemListPageSizeButton = ({ selectedRows, onSelectedRowsChange, className = "" }) => {

  return (
    <Listbox value={selectedRows} onChange={onSelectedRowsChange}>
      <div className={classNames("relative", className)}>
        <Listbox.Button className="relative w-48 h-12 cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate">{selectedRows.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {ROW_COUNT.map((row) => (
              <Listbox.Option
                key={row.id}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                  }`
                }
                value={row}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {row.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default ItemListPageSizeButton;