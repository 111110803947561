import React, { useMemo } from 'react';
import classNames from '../../helpers/classNames';


/**
 * 
 * @typedef {Object} ItemListPageSizeButtonProps
 * @property {number} pageCount
 * @property {number} pageSize
 * @property {number} itemCount
 * @property {number} pageNumber
 * @property {function} onPageNumberChange
 * @property {string} className
 * 
 * @param {ItemListPageSizeButtonProps} param0 
 * 
 */
const ItemListPagesButton = ({ pageCount, pageNumber, className, onPageNumberChange }) => {
  const pageDetails = useMemo(() => {
    const hasPages = pageCount >= 1;
    return `Page ${hasPages ? pageNumber : 0} of ${hasPages ? pageCount : 0}`
  }, [pageCount, pageNumber]);

  const array = useMemo(() => {
    const arr = [];
    for(let i = 1; i <= pageCount; i++) {
      arr.push(i);
    }
    return arr;
  }, [pageCount]);

  return (
    <div className={classNames("item-list-pages", className)}>
      <h1>{pageDetails}</h1>
      <div className="item-list-pages-btns">
      {
        array.map((page) => {
          return (
            <button key={page} id={page} data-selected={page === pageNumber} className="item-list-page-btn" onClick={() => onPageNumberChange(page)}>{page}</button>
          )
        })
      }
      </div>
    </div>
  );
};

export default ItemListPagesButton;