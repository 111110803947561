import { PencilIcon, TrashIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import CardButton from '../buttons/CardButton';
// import ItemCardCheckbox from '../buttons/ItemCardCheckbox';

/**
 * @typedef {Object} RestaurantData
 * @property {string} VendorID
 * @property {string} Name
 * @property {string} Code
 * @property {string} Logo
 * @property {string} Description
 * @property {'A' | 'R' | 'D'} Status
 * 
 * @typedef {Object} RestaurantCardProps
 * @property {RestaurantData} data
 * 
 * 
 * 
 * @param {RestaurantCardProps} param0 
 * 
 */
const Restaurant = ({ data }) => {

  return (
    <Link to={`/restaurant/${data.id}`} className="card restaurant-card group">
      <img 
        className="restaurant-card-img"
        src={data.Logo} 
        alt="Establishment Logo"
      />
      <div className="card-details">
        <div className="card-data">
          <LinesEllipsis
            text={data.Name}
            className="card-data-title"
            maxLine="1"
            ellipsis='...'
            basedOn='letters'
            trimRight
          />
          <LinesEllipsis
            text={data.Description}
            className="card-data-desc"
            maxLine="2"
            ellipsis='...'
            basedOn='letters'
            trimRight
          />
        </div>
        <div className="card-actions">
          <CardButton 
            icon={TrashIcon} 
            tips={'Delete Establishment'}
            warning={{ title: 'Delete Establishment', message: `Are you sure you want to delete ${data.Name}?`}}
            onClick={() => alert('Coming soon')}
          />
          <CardButton 
            icon={PencilIcon} 
            tips={'Edit Establishment'}
            onClick={() => alert('Coming soon')}
          />
          <CardButton 
            icon={DotsVerticalIcon}
          />
        </div>
      </div>
      {/* <ItemCardCheckbox/> */}
    </Link>
  );
};

export default Restaurant;