import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import CustomButton from './CustomButton';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import RwardQRCode from '../RwardQRCode';


const QRCodePrinterButton = ({ text = "Print QR Code", link = '/', title = '', description = ''}) => {
  const printableRef = useRef();

  return (
    <>
      <ReactToPrint
        trigger={_ => 
          <CustomButton 
            text={text}
            btnClassName='mt-8 shadow-md'
          />
        }
        content={_ => printableRef.current}
      />
      <div className='print-doc' ref={printableRef}>
        <div className='print-content'>
          <div className='p-4 w-64'>
            <div 
              className='aspect-[350/76] w-full h-auto bg-banner bg-contain'
            />
          </div>
          <div className='shadow-md rounded-md p-4 bg-white'>
            <RwardQRCode value={link}/>
          </div>
          <h1 className='my-4 text-xl'>{title}</h1>
          {
            description ?
            <>
            <hr className='border-gray-500 border-[1px] w-full'/>
            <span className='my-4 text-base'>{description}</span>
            </> : null
          }
        </div>
      </div>
    </>
  );
};

export default QRCodePrinterButton;