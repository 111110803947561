import React, { useCallback, useState } from 'react';
import CustomButton from '../buttons/CustomButton';
import QRCodePrinterButton from '../buttons/QRCodePrinterButton';
import { useSelector } from 'react-redux';
import RwardQRCode from '../RwardQRCode';
import { ContentModal } from '../../components/modal';
import { FormWithValidation } from '../../components/forms';
import useModal from '../../hooks/useModal';



/**
 * 
 * @typedef {Object} QRCardProps
 * @property {string} pdfTitle 
 * @property {string} pdfDescription 
 * 
 * @param {import('react-qrcode-logo').IProps & QRCardProps} param0
 * 
 * @returns {React.Component} 
 * 
 */
const QRCard = ({ value, pdfTitle = '', pdfDescription = '', ...rest }) => {
  const { branchInfo } = useSelector(state => state.auth);
  const { 
    isOpen: isOpenGeoloc, 
    openModal: openGeolocModal,
    closeModal: closeGeolocModal,
} = useModal();
  const [geolocToggleState, setGeolocToggleState] = useState(!branchInfo?.DisableGeolocation);

  const handleSubmitSuccess = useCallback((response) => {
    setGeolocToggleState(!response.data.DisableGeolocation)
    closeGeolocModal()
  }, [closeGeolocModal])
  
  return (
    <div className='flex flex-col justify-center items-center'>
      <a href={value} target="_blank" rel="noreferrer" className='my-4 p-4 rounded-md shadow-lg shadow-slate-600'>
        <RwardQRCode
          value={value}
          // logoImage={'https://rwardstoragedev.blob.core.windows.net/user/Vendor/Branding/5307c8c6-ece0-4c8c-b7e4-0170738bbb01/rwards-banner.png'}
          // logoHeight={50}
          // logoWidth={50}
          {...rest}
        />
      </a>
      <QRCodePrinterButton
        link={value}
        title={pdfTitle}
        description={pdfDescription}
      />
      <div className='m-6'>
      <CustomButton
        text="Geolocation Toggle"
        onClick={openGeolocModal}
      />
      </div>
      <ContentModal
        isOpen={isOpenGeoloc}
        onClose={closeGeolocModal}
      >
        <FormWithValidation
          formName='DisableGeolocation'
          formTitle='Toggle Geolocation'
          containerClassName='shadow-none m-0'
          initialValues={{
            DisableGeolocation: geolocToggleState,
            // TimeReEnable: typeof geoloc.disabled === 'boolean' ? null : geoloc.disabled,
            // TransactionReEnable: 0,
          }}
          hideFieldWhen={{
            // TimeReEnable: (values) => values.EnableGeolocation, 
            // TransactionReEnable: (values) => values.EnableGeolocation, 
          }}
          fields={[
            {
              name: 'DisableGeolocation',
              type: 'switch',
              label: 'Enable Geolocation',
              disabled: false,
              className: 'flex-row items-center mb-2',
            },
            // {
            //     name: 'TimeReEnable',
            //     type: 'time',
            //     label: 'Re-enable by time',
            //     tips: 'Automatically re-enable the realtime location requirements until the specified time',
            //     className: 'w-1/2'
            // },
            // {
            //     name: 'TransactionReEnable',
            //     type: 'number',
            //     label: 'Re-enable by transaction',
            //     tips: 'Automatically re-enable the realtime location requirements when approved transactions reached this count',
            //     className: 'w-1/2'
            // },
          ]}
          submitLink='toggleBranchGeoloc'
          onSubmitSuccess={handleSubmitSuccess}
          onPreSubmitTransform={(values) => {
            return values;
          }}
        />
      </ContentModal>
    </div>
  );
};

export default QRCard;