import React, { useMemo } from 'react';
import { RWARD_CUSTOMER_URL } from '../../setupConfig';
import { useSelector } from 'react-redux';
import Jumbotron from '../../components/jumbotron/Jumbotron';
// import React, { useCallback, useState } from 'react';
import Spinner from '../../components/loading/Spinner';
import QRCard from '../../components/cards/QRCard';
import ErrorScreen from '../../components/errors/Errors';

const Dashboard = () => {
  const { currentUser, branchInfo, authenticatingToken } = useSelector(state => state.auth);
  const branchLink = useMemo(() => `${RWARD_CUSTOMER_URL}redeem/${currentUser.VendorID}/${currentUser.BranchID}`, [currentUser?.BranchID, currentUser?.VendorID]);
  const branchName = useMemo(() => authenticatingToken ? '...' : `${branchInfo?.Restaurant?.Name} (${branchInfo?.Name})`, [authenticatingToken, branchInfo]);


  return (
    <div className="w-full h-full flex flex-col justify-center items-center p-6">
      {
        authenticatingToken ?
          <Spinner /> :
          <div className='bg-white shadow-xl rounded-lg w-[calc(100%)] lg:w-[calc(70%)] p-6 flex flex-col justify-center h-[calc(100%)]'>
          <div className='w-full h-full flex flex-col justify-start items-center'>
            <Jumbotron
              title='Customer QR Code'
              header="This QR Code is for your customers to check and redeem available promotions."
            />
            {
              branchInfo?.Location ?
              <QRCard
                value={branchLink}
                pdfTitle={branchName}
                pdfDescription="Scan to claim rewards"
              /> :
              <ErrorScreen
                title={'QR Generation Failed'}
                header={'Geolocation not found'}
                description={`Please make sure your branch geolocation is updated`}
              />
            }
          </div>
          </div>

      }
    </div>
  );
};

export default Dashboard;