import { createSlice } from '@reduxjs/toolkit'

export const walkthroughSlice = createSlice({
  name: 'walkthrough',
  initialState: {
    main: false,
  },
  reducers: {
    start: (state, action) => {
      state[action.payload.name] = true;
    },
    stop: (state, action) => {
      state[action.payload.name] = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { start, stop } = walkthroughSlice.actions;

export default walkthroughSlice.reducer