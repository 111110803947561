import axios from "axios";
import { LIKHA_CRUD_API } from "../setupConfig";

const LikhaCRUD = axios.create({
  baseURL: LIKHA_CRUD_API,
});

// LikhaCRUD.interceptors.request.use(function (config) {
//   const token = new Cookies().get(COOKIE_NAMES.TOKEN);

//   if (token) {
//     config.headers = config.headers || {};
//     config.headers['rward-authorization'] = token;
//   };

//   return config;
// });

// const onFullfilled = (response) => {
//   console.log('no context onFullfilled', response);
//   if (response.data.valid) return response.data;
//   if(typeof response.data.errorMsg === 'string') {
//     throw new Error(response.data.errorMsg);
//   } else if(typeof response.data.errorMsg === 'object') {
//     if(Array.isArray(response.data.errorMsg)) {
//       throw new Error(response.data.errorMsg[0]);
//     } else if(typeof response.data.errorMsg?.message === 'string') {
//       throw new Error(response.data.errorMsg?.message);
//     } else {
//       throw new Error('System Error: Please contact the admin');
//     }
//   }
// };

// const onRejected = (response) => {
//   throw new Error(response.message);
// };

// RwardCRUD.interceptors.response.use(onFullfilled);
// RwardCRUD.interceptors.response.use(onFullfilled, onRejected);

export default LikhaCRUD;