import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import classNames from '../../helpers/classNames';
import Profile from './Profile';
import Subscription from './Subscription';
import Branding from './Branding';
import Settings from './Settings';


const Account = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={'account-page'}>
      <div className='account-container tabbed-info'>
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="tab-list tabbed-info-tabs">
            <Tab
              className={({ selected }) =>
                classNames(
                  'tab-list-item',
                  selected
                    ? 'tab-list-item-selected'
                    : 'tab-list-item-unselected'
                )
              }
            >
            Profile
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'tab-list-item',
                  selected
                    ? 'tab-list-item-selected'
                    : 'tab-list-item-unselected before:animate-ping',
                )
              }
            >
            Subscription
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'tab-list-item',
                  selected
                    ? 'tab-list-item-selected'
                    : 'tab-list-item-unselected before:animate-ping',
                )
              }
            >
            Branding
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'tab-list-item',
                  selected
                    ? 'tab-list-item-selected'
                    : 'tab-list-item-unselected'
                )
              }
            >
            Settings
            </Tab>
          </Tab.List>
          <Tab.Panels className='tabbed-info-panels'>
            <Tab.Panel className='tabbed-info-panel'>
              <Profile/>
            </Tab.Panel>
            <Tab.Panel className='tabbed-info-panel'>
              <Subscription />
            </Tab.Panel>
            <Tab.Panel className='tabbed-info-panel'>
              <Branding/>
            </Tab.Panel>
            <Tab.Panel className='tabbed-info-panel'>
              <Settings/>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
};

export default Account;