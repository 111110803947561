import { HomeIcon, QuestionMarkCircleIcon, TicketIcon, OfficeBuildingIcon, PhotographIcon } from '@heroicons/react/outline';
import { AccountPage, CreatePasswordPage } from './pages/account';
import { OTPEmailPage, OTPMobilePage } from './pages/account/otp';
import { BranchesPage, InformationBranchPage, VouchersBranchPage } from './pages/branch';
import { CollectionInformationPage, CollectionsPage } from './pages/collection';
import { RedeemInfo, RedeemCode, RedeemLogs } from './pages/redeem';
import { Error401, Error404, Error500 } from './pages/errors';
import Home from './pages/Home';
import { ComingSoonPage, DashboardPage, QRPage } from './pages/landing';
import { QRVoucherPage } from './pages/qr';
import { InformationRestaurantPage, RestaurantsPage } from './pages/restaurant';
import { InformationVoucherPage, RedeemVoucherPage, VoucherRedeemPage, VouchersPage } from './pages/voucher';
import MintRequestInfo from './pages/collection/MintRequestInfo';

const routes = {
  HOME: {
    link: '/',
    name: 'Home',
    element: Home,
  },
  DASHBOARD: {
    link: '/dashboard',
    name: 'Dashboard',
    icon: HomeIcon,
    element: DashboardPage,
    id: 'sidenav-dashboard',
  },
  RESTAURANTS: {
    link: '/restaurant',
    name: 'Restaurants',
    element: RestaurantsPage,
    icon: QuestionMarkCircleIcon,
    routes: {
      INFORMATION: {
        link: ':RestaurantID',
        name: 'Establishment Information',
        element: InformationRestaurantPage,
        // icon: QuestionMarkCircleIcon,
      }
    }
  },
  BRANCHES: {
    link: '/branch',
    name: 'Branches',
    id: 'sidenav-branches',
    icon: OfficeBuildingIcon,
    element: BranchesPage,
    routes: {
      VOUCHERS: {
        link: 'redeem/:BranchID',
        name: 'Branch Vouchers',
        element: VouchersBranchPage,
      },
      INFORMATION: {
        link: ':BranchID',
        name: 'Branch Information',
        element: InformationBranchPage,
        // icon: QuestionMarkCircleIcon,
      }
    },
  },
  REDEEM: {
    link: '/redeem',
    name: 'Redeem',
    icon: TicketIcon,
    element: RedeemLogs,
    id: 'sidenav-redemption',
    routes: {
      REDEEM_CODE: {
        link: '/redeem/code',
        name: 'Redeem Code',
        element: RedeemCode,
      },
      REDEEM_INFO: {
        link: '/redeem/:redeemID',
        name: 'Redeem Information',
        element: RedeemInfo
      },
      REDEEM_CODE_INFO: {
        link: '/redeem/code/:redeemCode',
        name: 'Redeem Information',
        element: RedeemInfo
      }
    }
  },
  COLLECTIONS: {
    link: '/collection',
    name: 'Collections',
    id: 'sidenav-branches',
    icon: PhotographIcon,
    element: CollectionsPage,
    routes: {
      INFORMATION: {
        link: '/collection/:CollectionID',
        name: 'Collection Information',
        element: CollectionInformationPage,
        // icon: QuestionMarkCircleIcon,
      },
      MINT_REQUEST_INFO: {
        link: '/collection/:CollectionID/request/:RequestID',
        name: 'Mint Request Information',
        element: MintRequestInfo
      },
    },
  },
  // VOUCHERS: {
  //   link: '/voucher',
  //   name: 'Campaigns',
  //   icon: TicketIcon,
  //   element: RedeemsPage,
  //   id: 'sidenav-redemption',
  // },
  ACCOUNT: {
    link: '/account',
    name: 'Vendor Account',
    element: AccountPage,
    routes: {
      CREATE_PASSWORD: {
        link: 'create-password',
        name: 'Create Password',
        element: CreatePasswordPage
      },
      OTP: {
        link: 'otp',
        name: 'Account OTP',
        routes: {
          EMAIL: {
            link: 'email',
            name: 'OTP Email',
            element: OTPEmailPage,
          },
          MOBILE: {
            link: 'mobile',
            name: 'OTP Mobile',
            element: OTPMobilePage,
          }
        }
      }
    }
  },
  SETTINGS: {
    link: '/settings',
    name: 'Settings',
    element: ComingSoonPage,
  },
  ERRORS: {
    link: 'errors',
    name: 'Errors',
    element: Error500,
    routes: {
      ERROR_404: {
        link: '404',
        name: 'Error404',
        element: Error404,
      },
      ERROR_401: {
        link: '401',
        name: 'Error401',
        element: Error401,
      },
    },
  },
};

export const getFullPath = (keys = []) => {
  let currentPath = routes;
  return keys.reduce((arrPath, pathKey) => {
    arrPath.push(currentPath[pathKey].link);
    currentPath = currentPath[pathKey].routes;
    return arrPath;
  }, []).join('/');
}

export default routes;