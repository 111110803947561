import React, { useCallback } from 'react';


/** @param {import('react').InputHTMLAttributes} param0 */
const FieldInputTextArea = ({ name, onChange, ...rest }) => {
  const handleChangeValue = useCallback((ev) => {
    onChange(name, ev.target.value);
  }, [onChange, name]);

  return (
    <textarea 
      name={name}
      onChange={handleChangeValue}
      {...rest}
    />
  );
};

export default FieldInputTextArea;