import React, { useCallback, useState } from 'react';
import classNames from '../helpers/classNames';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { successLogin } from '../redux/auth/authSlice';
import RwardCRUD from '../helpers/RwardCRUD';
import COOKIE_NAMES from '../data/cookies';
import { useNavigate } from 'react-router-dom';

const DOTS = [
  'bg-rward-blue-dark',
  'bg-rward-red',
  'bg-rward-blue-dark',
  'bg-rward-yellow',
  'bg-rward-blue-dark',
  'bg-black',
  'bg-rward-blue-dark',
];

const PARTNERS = [
  {
    bg: 'bg-landing-partner-1',
    ratio: 'aspect-[394/118]',
  },
  {
    bg: 'bg-landing-partner-2',
    ratio: 'aspect-[392/151]',
  },
  {
    bg: 'bg-landing-partner-3',
    ratio: 'aspect-[420/145]',
  },
  {
    bg: 'bg-landing-partner-4',
    ratio: 'aspect-[321/110]',
  },
  // 'bg-landing-partner-5',
]


const DotDivision = () => {
  return (
    <div className='flex flex-row justify-center items-center space-x-8 pb-8'>
    {
      DOTS.map((dot, idx) => {
        return (
          <div key={idx} className={classNames("rounded-full w-2 h-2", dot)}/>
        )
      })
    }
    </div>
  );
};


const Home = () => {
  const [hasWhitelistRequested, setHasWhitelistRequested] = useState(false);
  const hideWhitelistRequested = useCallback(() => setHasWhitelistRequested(false), []);
  const showWhitelistRequested = useCallback(() => setHasWhitelistRequested(true), []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, setCookie] = useCookies([COOKIE_NAMES.TOKEN]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
  });

  const handleChangeValue = useCallback((ev) => {
    setForm(currForm => ({
      ...currForm,
      [ev.target.id]: ev.target.value,
    }))
  }, []);

  const loginUser = useCallback(async (form) => {
    const loginResult = await RwardCRUD.post(`login`, { Email: form.email, Password: form.password, UserType: 'B' });
    dispatch(successLogin({ user: loginResult.data.user, branch: loginResult.data.branch }));
    RwardCRUD.get('branchUserOnline', { headers: { 'rward-authorization': loginResult.data.token } });
    setCookie(COOKIE_NAMES.TOKEN, loginResult.data.token, { secure: true, path: '/' });
  }, [dispatch, setCookie]);

  const handleSubmitClick = useCallback(async (ev) => {
    ev.preventDefault();
    setIsSubmitting(true);
    try {
      await loginUser(form);
      navigate('/dashboard');
    } finally {
      setIsSubmitting(false)
    }
  }, [form, loginUser, navigate]);



  return (
    <div className="w-full h-full p-10 flex flex-col justify-center items-center">
      <form className="bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4 w-96" onSubmit={handleSubmitClick}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input 
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="email" type="text" placeholder="Email"
            disabled={isSubmitting}
            value={form.email} onChange={handleChangeValue}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input 
            className="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
            id="password" type="password" placeholder="******************"
            disabled={isSubmitting}
            value={form.password} onChange={handleChangeValue}
          />
          <p className="text-red-500 text-xs italic">Please choose a password.</p>
        </div>
        <div className="flex items-center justify-between">
          <button id="signin" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" disabled={isSubmitting}>
            Sign In
          </button>
          {
            isSubmitting ? 
            <svg className={`custom-button-spin animate-spin h-5 w-5 text-blue-500`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg> : null
          }
        </div>
      </form>
    </div>
  );
};

export default Home;