import { CheckCircleIcon, CheckIcon, ClockIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from '../../../helpers/classNames';
import { middleTruncate } from '../../../helpers/TextFormatter';

const REDEEM_STATUS = {
    P: {
        text: 'Waiting',
        icon: ClockIcon,
        divColor: 'bg-orange-200 text-orange-400',
        fgColor: 'stroke-orange-400'
    },
    X: {
        text: 'Declined',
        icon: XCircleIcon,
        divColor: 'bg-red-300 text-red-600',
        fgColor: 'stroke-red-600'
    },
    A: {
        text: 'Approved',
        icon: CheckCircleIcon,
        divColor: 'bg-green-300 text-green-600',
        fgColor: 'stroke-green-600'
    }
}

const CollectionMintRequestPending = () => {

    return (
        <div 
            className={classNames(
                "gap-1 rounded-full p-2 xs:py-1 flex flex-row justify-center text-xs items-center bg-orange-300 text-orange-600",
            )}
        >
            Pending
            <ClockIcon className="h-4 w-4" aria-hidden="true" />
        </div>
    )
};

const CollectionMintRequestRejected = () => {

    return (
        <div 
            className={classNames(
                "gap-1 rounded-full p-2 xs:py-1 flex flex-row justify-center text-xs items-center bg-red-300 text-red-600",
            )}
        >
            Rejected
            <XIcon className="h-4 w-4" aria-hidden="true" />
        </div>
    )
};

const CollectionMintRequestAccepted = () => {

    return (
        <div 
            className={classNames(
                "gap-1 rounded-full p-2 xs:py-1 flex flex-row justify-center text-xs items-center bg-green-300 text-green-600",
            )}
        >
            Accepted
            <CheckIcon className="h-4 w-4" aria-hidden="true" />
        </div>
    )
};

const CollectionMintRequestCard = ({ data }) => {

    const statusPill = useMemo(() => {
        switch(data?.Status) {
            case 'A':
                return <CollectionMintRequestAccepted/>
            case 'X':
                return <CollectionMintRequestRejected/>
            default:
                return <CollectionMintRequestPending/>
        }
    }, [data?.Status]);

    return(
        <Link 
            to={`/collection/${data.CollectionID}/request/${data.id}`} 
            className="card redemption-card group hover:bg-rward-yellow w-[10rem]"
        >
            <img className="rounded-md w-full h-2/3 xs:h-1/2 object-cover object-top" src={data?.Collection?.Banner} alt="" />
            <div className="p-3 flex flex-col justify-center items-center w-full">
                <h1 className="text-md font-semibold tracking-tight text-gray-900 text-center mb-2">{data?.Collection?.Name}</h1>
                <h1 className="text-xs font-bold tracking-tight text-gray-900 text-center mb-2">{middleTruncate(data.WalletAddress, 6, 6)}</h1>
                {/* <h1 className="text-md tracking-tight text-gray-900 text-center mb-2">Test Perk 2</h1> */}
                <div className='flex flex-row sm:flex-col justify-between sm:justify-center items-center w-full'>
                    <p className="rounded-full p-2 xs:px-3 xs:py-1 text-xs text-white bg-rward-blue-dark xs:mb-2 tracking-[.25rem]">{data?.Code}</p>
                    {statusPill}
                </div>
            </div>
        </Link>
    )

}

export default CollectionMintRequestCard