import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Slider from 'react-slick';
import classNames from '../../helpers/classNames';
import CustomButton from '../buttons/CustomButton';
import FieldInput from '../forms/FieldInput';

/**
 * 
 * @param {import('formik').FormikProps & import('./typedef').WizardFormProps} param0 
 * 
 */
const WizardFormik = ({ 
  errors, 
  touched,
  values,
  setFieldValue, 
  handleBlur, 
  isValid,
  onSubmit,
  actionsClassName,
  submitClassName,
  submitText = 'Next',
  isSubmitting, 
  hideFieldWhen,
  onValidUpdate, 
  disableSubmit,
  disableSubmitText,
  additionalValidation,
  overrideValidation,
  fields = [], 
  initialValues, 
  schema 
}) => {
  const [createdNew, setCreatedNew] = useState({});
  const handleCreatedNew = useCallback((fieldName, isNew) => {
    setCreatedNew(curr => ({ ...curr, [fieldName]: isNew }));
  }, []);

  useEffect(() => {
    let formIsValid = isValid;
    console.log('additionalValidation', additionalValidation);
    if(typeof overrideValidation === 'function') {
      return overrideValidation();
    } else if(typeof overrideValidation === 'boolean') {
      return overrideValidation;
    }
    if(typeof additionalValidation === 'function') {
      formIsValid = formIsValid && additionalValidation();
    } else if(typeof additionalValidation === 'boolean') {
      formIsValid = formIsValid && additionalValidation;
    }
    onValidUpdate(formIsValid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, overrideValidation, additionalValidation]);

  return (
    <Form className="flex flex-row flex-wrap">
    {
      fields.map((field) => {
        let isHidden = false;
        if(hideFieldWhen) {
          if(typeof hideFieldWhen[field.name] === 'boolean') {
            isHidden = hideFieldWhen[field.name];
          } else if(typeof hideFieldWhen[field.name] === 'function') {
            isHidden = hideFieldWhen[field.name](values, createdNew);
          }
          if(isHidden) return null;
        }

        return (
          <FieldInput 
            {...field}
            key={field.name}
            className={classNames('w-full px-1', field.className)}
            value={values[field.name]}
            defaultValue={initialValues[field.name]}
            onChangeFieldValue={setFieldValue}
            onBlur={handleBlur}
            hasValidation={!!schema?.fields[field.name]}
            error={errors[field.name]} 
            touched={touched[field.name]}
            disabled={field.disabled || isSubmitting}
            onCreatedNew={handleCreatedNew}
          />
        )
      })
    }
    {
      onSubmit ? 
      <div className={classNames('flex flex-row w-full justify-end space-x-2 mb-4', actionsClassName)}>
        <CustomButton 
          type="submit" 
          disabled={!isValid || isSubmitting || disableSubmit} 
          btnClassName={classNames("form-submit", submitClassName)} 
          text={disableSubmit ? (disableSubmitText || submitText) : submitText}
          isLoading={isSubmitting}
        />
      </div> : null
    }
    </Form>
  );
};

/**
 * 
 * @param {import('./typedef').WizardFormProps} param0 
 *  
 */
const WizardForm = ({ fields, className = '', initialValues, schema, title, description, onSubmit, onNext, onPrev, ...restProps }) => {
  const handleSubmit = useCallback(async (values, formikHelpers) => {
    if(typeof onSubmit === 'function') {
      await onSubmit(values, formikHelpers);
    }
    onNext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  return (
    <div
      className={classNames('w-full', className)}
    >
      <h2 className='text-lg font-semibold mb-4'>{title}</h2>
      <span className='text-base text-gray-500'>{description}</span>
      <hr className='my-4'/>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
      {
        formProps => {
          return (
            <WizardFormik
              {...formProps}
              fields={fields}
              initialValues={initialValues}
              onSubmit={onSubmit}
              {...restProps}
            />
          )
        }
      }
      </Formik>
    </div>
  )
}

/**
 * 
 * @param {import('./typedef').WizardProps} param0 
 */
const Wizard = ({ 
  sliderOptions = {
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: false,
    draggable: false,
    swipe: false,
    dotsClass: '!flex flex-row justify-evenly',
    // appendDots: (dots) => {
    //   return (
    //     <ul className='!flex flex-row justify-evenly'>{dots}</ul>
    //   );
    // },
  },
  className = '',
  forms = [],
}) => {
  const [validForms, setValidForms] = useState(new Array(forms.length).fill(false));
  const { allowedSteps, lastAllowedStep } = useMemo(() => {
    let validSteps = new Array(forms.length).fill(false);
    let lastStep = -1;
    for(let i = 0; i < forms.length; i++) {
      if(!validForms[i]) {
        validSteps[i] = true;
        break;
      }
      lastStep = i;
      validSteps[i] = true;
    }

    return {
      allowedSteps: validSteps,
      lastAllowedStep: lastStep,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validForms]);
  const handleFormValid = useCallback((idx, isValid) => {
    console.log('FORM VALID', idx, isValid);
    const newArr = [...validForms];
    newArr[idx] = isValid;
    setValidForms(newArr);
  }, [validForms]);

  const customPaging = useCallback((idx) => {
    return (
      <button className={'custom-paging'} disabled={!allowedSteps[idx]}>{allowedSteps[idx] && lastAllowedStep + 1 !== idx ? '✔' : idx+1}</button>
    )
  }, [allowedSteps, lastAllowedStep]);
  const sliderRef = useRef(null);
  const handleNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  return (
    <div className={classNames('w-full p-4', className)}>
      <Slider   
        {...sliderOptions}
        customPaging={customPaging}
        ref={sliderRef}
      >
      {
        forms.map((form, idx) => {
          return (
            <WizardForm 
              {...form} 
              key={idx} 
              onValidUpdate={(isValid) => handleFormValid(idx, isValid)} 
              onNext={handleNext}
            />
          )
        })
      }
      </Slider>
    </div>
  );
};

export default Wizard;