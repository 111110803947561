import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Popover, Transition, Tab } from '@headlessui/react'
import classNames from '../helpers/classNames';
import { BellIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import COOKIE_NAMES from '../data/cookies';
import LinesEllipsis from 'react-lines-ellipsis';
import Spinner from '../components/loading/Spinner';
import { useNavigate } from 'react-router-dom';
import { formatDistance, fromUnixTime } from 'date-fns';
import CustomButton from '../components/buttons/CustomButton';
import RwardNotif from '../helpers/RwardNotif';

const NotificationLoading = () => {
  return <Spinner svgClassName={'!h-12 !w-12 self-center'}/>
}

const NotificationList = ({ notifs = [], unread = false, fetching = false, onNotifClick }) => {
  const notifList = useMemo(() => {
    return unread ? notifs.filter((notif) => !notif.Read) : notifs; 
  }, [unread, notifs]);

  return (
    notifList.length ? 
    notifList.map((notif) => {
      return (
        <NotificationItem key={notif.id} notif={notif} unread={unread} onNotifClick={onNotifClick}/>
      );
    }) :
    fetching ? null :
    <h1 className="text-center my-4">{unread ? `Hooray, you're all caught up!` : 'No notifications'}</h1> 
  )
}

const NotificationItem = ({ notif, unread, onNotifClick }) => {
  const isUnread = useMemo(() => {
    return unread || !notif?.Read;
  }, [unread, notif?.Read]);

  const timeAgo = useMemo(() => {
    if(notif?.CreatedTS) {
      return formatDistance(
        fromUnixTime(notif.CreatedTS),
        new Date(),
        { addSuffix: true }
      ) //=> 'in about 1 hour'
    }
    return 'Invalid Date Time';
  }, [notif?.CreatedTS]);

  const handleNotifClick = useCallback(() => {
    onNotifClick(notif);
  }, [notif, onNotifClick])

  return (
    <button className={`header-notif-item ${isUnread ? 'bg-slate-300' : ''}`} onClick={handleNotifClick}>
      <div className="header-notif-item-texts">
        <LinesEllipsis
          text={notif.Title}
          className="text-sm text-left w-full"
          maxLine="1"
          ellipsis='...'
          basedOn='letters'
          trimRight
        />
        <LinesEllipsis
          text={notif.Message}
          className="text-xs text-left"
          maxLine="2"
          ellipsis='...'
          basedOn='letters'
          trimRight
        />
        <span>{timeAgo}</span>
      </div>
    </button>
  );
}

const NotificationPopup = ({ open, close }) => {
  const { currentUser } = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [notifs, setNotifs] = useState([]);
  const [cookies] = useCookies([COOKIE_NAMES.TOKEN]);
  const [fetching, setFetching] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [continuationToken, setContinuationToken] = useState("");
  const navigate = useNavigate();

  const getUserNotifications = useCallback(async (pageNumber, unreadOnly = 1, contToken = "") => {
    setFetching(true);
    if(pageNumber === 1) {
      setNotifs([]);
    }
    const respData = await RwardNotif.post(`getUserNotifications`, { Pagination: { ContinuationToken: contToken, PageSize: 5 }, UnreadOnly: !!unreadOnly });
    setFetching(false);
    if(respData.valid) {
      const myNotifs = respData.data.notifications || [];
      setPage(pageNumber + 1);
      setHasMore(respData.data.hasMore);
      setContinuationToken(respData.data.continuationToken || "");
      setNotifs(curr => {
        if(pageNumber === 1) {
          return myNotifs;
        } else {
          return [...curr, ...myNotifs];
        }
      });
    }
  }, []);

  useEffect(() => {
    if(cookies.token) {
      getUserNotifications(1, selectedTab);
    } else {
      setPage(1);
      setSelectedTab(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.token, selectedTab]);
  
  useEffect(() => {
    if(typeof close === 'function' && !currentUser) {
      close();
    }
  }, [close, currentUser]);

  const hasNotif = useMemo(() => {
    return !!notifs.length;
  }, [notifs]);

  
  const handleNotifClick = useCallback(async (notif) => {
    if(notif?.Data) {
      navigate(notif.Data.Link);
      const notifRead = await RwardNotif.post(`markAsRead`, { NotificationID: notif.id });
      if(notifRead.valid) {
        const notifData = notifRead.data;

        setNotifs(curr => {
          const notifIdx = curr.findIndex((currNotif) => !currNotif.Read && currNotif.id === notif.id);
          if(!(notifIdx >= 0)) return curr;

          curr[notifIdx] = notifData;
          return curr;
        })
      }
    }
  }, [navigate]);

  return (
    <>
      <Popover.Button
        className={classNames(
          'custom-nav-btn relative',
        )}
        data-open={open}
        data-show={!!currentUser}
      >
        <BellIcon
          className={classNames(
            currentUser ? 'h-8 w-8' : 'h-0 w-0',
            hasNotif ? 'stroke-sky-600' : '',
            'notification-bell'
          )}
          aria-hidden="true"
          data-has-notif={hasNotif}
          id='topnav-notification'
        />
      </Popover.Button>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"s
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 mt-3 transform px-2 sm:px-0 left-16 -translate-x-full w-[75vw] sm:w-[50vw] md:w-64">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="bg-white px-5 py-6 flex flex-col flex-1">
            <Tab.Group manual selectedIndex={selectedTab} onChange={setSelectedTab}>
              <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-4">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white shadow'
                        : 'text-black hover:bg-white/[0.12] hover:text-white'
                    )
                  }
                >
                All
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white shadow'
                        : 'text-black hover:bg-white/[0.12] hover:text-white'
                    )
                  }
                >
                Unread
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <div className="header-notif-list" data-fetching={fetching}>
                    <NotificationList notifs={notifs} fetching={fetching} onNotifClick={handleNotifClick}/>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="header-notif-list" data-fetching={fetching}>
                    <NotificationList notifs={notifs} fetching={fetching} onNotifClick={handleNotifClick} unread/>
                  </div>
                  </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            {
              notifs.length ?
              <CustomButton 
                text={hasMore ? 'Load more' : 'No more items'}
                onClick={() => getUserNotifications(page, selectedTab, continuationToken)}
                isLoading={fetching}
                disabled={!hasMore || fetching}
                btnClassName="py-2 mt-4"
                txtClassName="text-sm"
              /> :
              fetching ? 
              <NotificationLoading/> :
              null
            }
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </>
  )
};

const NotificationButton = () => {

  return (
    <Popover className="relative">
    {(props) => (<NotificationPopup {...props}/>)}
    </Popover>
  );
};

export default NotificationButton;