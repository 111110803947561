import React from 'react';
import { CollectionCard } from '../../components/cards/Collection';
import { ItemList } from '../../components/list';

const DATA_SOURCE = {
  endpoint: 'getBranchCollections',
  accessor: 'collections',
}

const Collections = () => {

  return (
    <div className="collection-list-content">
      <ItemList 
        dataSource={DATA_SOURCE}
        cardComponent={CollectionCard}
        listName='collection'
      />
    </div>
  );
};

export default Collections;