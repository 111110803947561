import React from 'react';
// import logo from '../../logo.svg';
const logo = require('../../assets/img/rward-banner.png');

const ErrorScreen = ({ title, header, description, children, mainClassName = '' }) => {
  return (
    <div className={`w-100 h-full bg-transparent flex justify-center items-center flex-col px-8 ${mainClassName}`}>
      <img
        className="h-48 w-auto sm:h-60 aspect-[295/50] max-w-[300px] object-contain my-4"
        src={logo}
        alt="App Logo"
      />
      <h2 className="text-xl my-2 text-indigo-600 font-semibold tracking-wide uppercase">{title}</h2>
      <h1 className="text-3xl font-bold text-gray-900">{header}</h1>
      <p className="mt-4 max-w-2xl text-xl text-center text-gray-500 lg:mx-auto">{description}</p>
      <div className="flex flex-row justify-between items-center my-4">
        {children}
      </div>
    </div>
  )
};

export default ErrorScreen;
