import { useCallback, useState } from "react";
import { preventAndStop } from "../helpers/Generic";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const closeModal = useCallback((ev) => {
    preventAndStop(ev);
    setInitialValues(null);
    setIsOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setInitialValues(null);
    setIsOpen(true);
  }, []);

  const openModalWithValues = useCallback((value) => {
    setInitialValues(value);
    setIsOpen(true);
  }, []);

  return {
    isOpen,
    initialValues,
    openModal,
    openModalWithValues,
    closeModal,
  }
};

export default useModal;