import axios from "axios";
// import { useCookies } from "react-cookie";
import { Cookies } from "react-cookie";
import COOKIE_NAMES from "../data/cookies";
import { RWARD_NOTIF_API } from "../setupConfig";

const RwardNotif = axios.create({
  baseURL: RWARD_NOTIF_API,
});

RwardNotif.interceptors.request.use(function (config) {
  // const [cookies] = useCookies([COOKIE_NAMES.TOKEN]);
  // const token = cookies[0];
  const token = new Cookies().get(COOKIE_NAMES.TOKEN);

  if (token) {
    config.headers = config.headers || {};
    config.headers['rward-authorization'] = token;
  };

  return config;
});

// const onFullfilled = (response) => {
//   if (response.data.valid) return response.data;
//   if(typeof response.data.errorMsg === 'string') {
//     throw new Error(response.data.errorMsg);
//   } else if(typeof response.data.errorMsg === 'object') {
//     if(Array.isArray(response.data.errorMsg)) {
//       throw new Error(response.data.errorMsg[0]);
//     } else if(typeof response.data.errorMsg?.message === 'string') {
//       throw new Error(response.data.errorMsg?.message);
//     } else {
//       throw new Error('System Error: Please contact the admin');
//     }
//   }
// };

// const onRejected = (response) => {
//   throw new Error(response.message);
// };

// RwardNotif.interceptors.response.use(onFullfilled, onRejected);

export default RwardNotif;